var wdot = wdot || {};
wdot.Group = function () {
    var id = ko.observable(),
            name = ko.observable(),
            createEditGroupViewModel = function () {
                var users = new Array(),
                    currentUser,
                    usersInGroup = ko.observableArray(),
                    removeUserFromGroup = function (user) {
                        currentUser = user;
                        Wdot.Ajax.prototype.Post("/admin/groups/removeuserfromgroupajax?groupId=" + $("#Id").val() + "&userId=" + user.id, null, removeUserAjaxSuccess, null);
                    },
                    removeUserAjaxSuccess = function (data) {
                        if (data.Success) {
                            usersInGroup.remove(currentUser);
                        }
                    },
                    addUserToGroup = function () {
                        var alreadyAdded = false;
                        var userId = $("#UsersDropDown").val();
                        for (var i = 0; i < usersInGroup().length; i++) {
                            if (usersInGroup()[i].id === parseInt(userId)) {
                                alreadyAdded = true;
                            }
                        }

                        if (!alreadyAdded) {
                            Wdot.Ajax.prototype.Post("/admin/groups/addusertogroupajax?grouid=" + $("#Id").val() + "&userid=" + userId, null, addUserAjaxSuccess, null);
                        }
                    },
                    addUserAjaxSuccess = function (data) {
                        if (data.Success) {
                            $.each(users, function () {
                                if (this.id === parseInt($("#UsersDropDown").val())) {
                                    usersInGroup.push(this);
                                }
                            });
                        }
                    },
                    loadUsersInGroup = function (usersInGroupJsonAsString) {
                        var usersInGroupJson = $.parseJSON(usersInGroupJsonAsString);
                        for (var prop in usersInGroupJson) {
                            if (!usersInGroupJson.hasOwnProperty(prop)) {
                                continue;
                            }

                            $.each(users, function () {
                                if (this.id === usersInGroupJson[prop]["id"]) {
                                    usersInGroup.push(this);
                                }
                            });
                        }
                    },
                    loadAllUsers = function (usersJsonAsString) {
                        users = $.parseJSON(usersJsonAsString);
                    };
                return {
                    users: users,
                    usersInGroup: usersInGroup,
                    removeUserFromGroup: removeUserFromGroup,
                    addUserToGroup: addUserToGroup,
                    loadUsersInGroup: loadUsersInGroup,
                    loadAllUsers: loadAllUsers
                };
            } (),
            loadGroupAutocomplete = function (selector, selectorFind) {
                var cache = {},
                    lastXhr = '',
                    itemSelected = false;
                $(selector).find(selectorFind).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: ''}]);
                        Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        lastXhr = Wdot.Ajax.prototype.Get("/admin/groups/autocompleteforgroups", request, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(selector).find(selectorFind).val(ui.item.Name);
                        return false;
                    },
                    select: function (event, ui) {
                        itemSelected = true;
                        $(selector).find(selectorFind).val(ui.item.Name);
                        $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                        $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + "</a>")
                        .appendTo(ul);
                };
            },
            listingPageViewModel = function () {
                var generateListingFilterJSON = function () {
                    var selectedActivity = $("input[name='SelectedVersionStatuses']:checked").val();
                    var filterKeyword = $("input[name='filterKeyword']").val();
                    var id = $("input[name='Id']").val();
                    var name = $("input[name='Name']").val();
                    var createdBy = $("input[name='CreatedBy']").val();
                    var modifiedBy = $("input[name='ModifiedBy']").val();
                    var queryString = '';

                    if (selectedActivity == 1) {
                        wdot.Paging.data.IncludeInActive = false;
                        wdot.Paging.data.IncludeActive = true;
                        queryString += '&IncludeInActive=false';
                        queryString += '&IncludeActive=true';
                    }
                    else if (selectedActivity == 2) {
                        wdot.Paging.data.IncludeActive = false;
                        wdot.Paging.data.IncludeInActive = true;
                        queryString += '&IncludeInActive=true';
                        queryString += '&IncludeActive=false';
                    } else {
                        wdot.Paging.data.IncludeActive = true;
                        wdot.Paging.data.IncludeInActive = true;
                        queryString += '&IncludeInActive=true';
                        queryString += '&IncludeActive=true';
                    }


                    queryString += '&filterKeyword=' + filterKeyword;
                    queryString += '&Id=' + id;
                    queryString += '&Name=' + name;
                    queryString += '&CreatedBy=' + createdBy;
                    queryString += '&ModifiedBy=' + modifiedBy;

                    wdot.Paging.data.filterKeyword = filterKeyword;
                    wdot.Paging.data.Id = id;
                    wdot.Paging.data.Name = name;
                    wdot.Paging.data.CreatedBy = createdBy;
                    wdot.Paging.data.ModifiedBy = modifiedBy;
                    return queryString;
                },
                resetListingFilter = function () {
                    $("input[name='SelectedVersionStatuses']").attr('checked', false);
                    $("input[name='SelectedVersionStatuses'][value='0']").attr('checked', true);
                    $("input[name='filterKeyword']").val('');
                    $("input[name='Id']").val('');
                    $("input[name='Name']").val('');
                    $("input[name='CreatedBy']").val('');
                    $("input[name='ModifiedBy']").val('');
                };
                return {
                    generateListingFilterJSON: generateListingFilterJSON,
                    resetListingFilter: resetListingFilter
                };
            } ();
    return {
        id: id,
        name: name,
        createEditGroupViewModel: createEditGroupViewModel,
        loadGroupAutocomplete: loadGroupAutocomplete,
        ListingPageViewModel: listingPageViewModel
    };
} ();