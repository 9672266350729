var tw = tw || {};
tw.Analytics = function () {

    var _log = false;

    /**
     * Set pageview, used for tracking virtual page changes like in a SPA.
     * @param {any} pagePath
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
    */
     //Legacy
    var setPageview = function (pagePath) {
        if (_log) console.group('tw.Analytics.legacy.setPageview()');
        if (_log) console.log('pagePath: ', pagePath);
        if (_log) console.groupEnd();
        //ga('set', 'page', '/new-page.html');
        ga('set', 'page', pagePath);
    };

    //Legacy
    var trackPageview = function(page) {
        if (_log) console.group('tw.Analytics.legacy.trackPageview()');
        if (_log) console.log('page: ', page);
        if (_log) console.groupEnd();
        ga('send', 'pageview', page);
    };

    //Legacy Event tracking
    var trackEvent = function(category, action, label) {
        if (_log) console.group('tw.Analytics.legacy.trackEvent()');
        if (_log) console.log('category: ', category);
        if (_log) console.log('action: ', action);
        if (_log) console.log('label: ', label);
        if (_log) console.groupEnd();
        ga('send', 'event', category, action, label);
    };


    //NEW GA4 
    var ga4 = function (category, action, label, eventName = 'onclick') {
        if (_log) console.group('tw.Analytics.ga4()');
        if (_log) console.log('category: ', category);
        if (_log) console.log('action: ', action);
        if (_log) console.log('label: ', label);
        if (_log) console.log('eventName: ', eventName);
        if (_log) console.groupEnd();

        var options = {
            event: eventName,
            category: category,
            action: action,
            label: label,
            page: { 
                protocol: window.location.protocol,
                hostname: window.location.hostname,
                path: window.location.pathname,
                search: window.location.search
            }
        };

        var parentUrl = getParentUrl();
        if(parentUrl){
            options['referrer'] = parentUrl;
            //console.log('tw.Analytics.parentUrl:', parentUrl);
        }

        window['dataLayer'].push(options);
        if (_log) console.log('tw.Analytics.window[dataLayer]:', window['dataLayer']);

        //continue sending legacy Events for a while. 2023-01-09
        trackEvent(category, action, label);
    }


    /**
     * Gets parent url if is in an iframe
     * @returns 
    */
    var getParentUrl = function () {
        var isInIframe = (parent !== window),
            parentUrl = null;
    
        if (isInIframe) {
            parentUrl = document.referrer;
        }
        if (_log) console.log('tw.Analytics.getParentUrl.isInIframe:', isInIframe, '=', parentUrl);

        return parentUrl;
    }

    return {
        ga4: ga4,
        trackEvent: trackEvent,
        trackPageview: trackPageview,
        setPageview: setPageview
    }

}();