var wdot = wdot || {};
wdot.PressRelease = function () {
    var listingPageViewModel = function () {
        var generateListingFilterJSON = function () {
            var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");
            var categories = $("select[name='SelectedCategories'] :selected");
            var filterKeyword = $("input[name='filterKeyword']").val();
            var id = $("input[name='Id']").val();
            var name = $("input[name='Name']").val();
            var author = $("input[name='Author']").val();
            var startDate = $("input[name='StartDate']").val();
            var endDate = $("input[name='EndDate']").val();
            var queryString = '';

            if (checkedStatuses.length > 0) {
                var i = 0;
                var selectedVersionStatuses = {};
                ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;

            }
            else {
                wdot.Paging.data.SelectedVersionStatuses = {};
            }

            if (categories.length > 0) {
                var i = 0;
                var selectedCategories = {};
                ko.utils.arrayForEach(categories, function (t) { selectedCategories[i] = $(t).val(); queryString += '&SelectedCategories[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data.SelectedCategories = selectedCategories;
            }
            else {
                wdot.Paging.data.SelectedCategories = {};
            }

            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&Id=' + id;
            queryString += '&Name=' + name;
            queryString += '&Author=' + author;
            queryString += '&StartDate=' + startDate;
            queryString += '&EndDate=' + endDate;

            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.Id = id;
            wdot.Paging.data.Name = name;
            wdot.Paging.data.Author = author;
            wdot.Paging.data.StartDate = startDate;
            wdot.Paging.data.EndDate = endDate;
            return queryString;
        },
                resetListingFilter = function () {
                    $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
                    $("select[name='SelectedCategories'] :selected").attr("selected", false);
                    $("input[name='filterKeyword']").val('');
                    $("input[name='Id']").val('');
                    $("input[name='Name']").val('');
                    $("input[name='Author']").val('');
                    $("input[name='StartDate']").val('');
                    $("input[name='EndDate']").val('');
                };
        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    } (),
        createEditBlogViewModel = function () {
            var selectedCategories = ko.observableArray(),
                allCategories = new Array(),
                addCategory = function () {
                    var categoryId = $("#Categories").val(),
                        alreadyAdded = false;
                    if (categoryId !== '' && categoryId !== undefined) {
                        for (var i = 0; i < selectedCategories().length; i++) {
                            if (selectedCategories()[i].Id === parseInt(categoryId)) {
                                alreadyAdded = true;
                            }
                        }

                        if (!alreadyAdded) {
                            $.each(allCategories, function () {
                                if (this.Id === parseInt(categoryId)) {
                                    selectedCategories.push(this);
                                }
                            });
                        }
                    }
                },
                removeCategory = function (category) {
                    selectedCategories.remove(category);
                },
                loadAllCategories = function (categoriesJsonAsString) {
                    allCategories = $.parseJSON(categoriesJsonAsString);
                },
                loadSelectedCategories = function (selectedCategoriesJsonAsString) {
                    var selectedCategoriesJson = $.parseJSON(selectedCategoriesJsonAsString);
                    for (var prop in selectedCategoriesJson) {
                        if (!selectedCategoriesJson.hasOwnProperty(prop)) {
                            continue;
                        }

                        $.each(allCategories, function () {
                            if (this.Id === selectedCategoriesJson[prop]["Id"]) {
                                selectedCategories.push(this);
                            }
                        });
                    }
                };
            return {
                SelectedCategories: selectedCategories,
                AddCategory: addCategory,
                AllCategories: allCategories,
                RemoveCategory: removeCategory,
                LoadAllCategories: loadAllCategories,
                LoadSelectedCategories: loadSelectedCategories
            };
        } ();
    return {
        createEditBlogViewModel: createEditBlogViewModel,
        ListingPageViewModel: listingPageViewModel
    };
} ();