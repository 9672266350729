var tw = tw || {};
tw.OpenWeather = function () {

    //global vars
    var _log = false,
        weatherIcons = null,

        //day.js.org/
        dayjsFormat = {
            server: 'YYYY-MM-DD HH:mm:ss',
            serverDate: 'YYYY-MM-DD',
            serverTime: 'HH:mm:ss',
            threeDay: 'ddd'
        },

        //weather - https://openweathermap.org/
        weatherApi = {
            baseUrl: 'https://api.openweathermap.org/data/2.5/',
            appId: '78a48bd42e45f8167f91525b6fff4530',//ascedia free acnt 
            units: 'imperial'
        },

        //geocode - https://openweathermap.org/api/geocoding-api
        weatherApiGeo = {
            baseUrl: 'https://api.openweathermap.org/geo/1.0/',
            appId: '78a48bd42e45f8167f91525b6fff4530',//ascedia free acnt 
            units: 'imperial'
        };



    /**
	 * Get Weather Current from lat/long
     * @param {*} latitude 
     * @param {*} longitude 
	*/
	var getWeatherCurrentNearby = function (latitude, longitude) {
        var urlCurrent = weatherApi.baseUrl + 'weather?&units=' + weatherApi.units  + '&lat=' + latitude + '&lon=' + longitude + '&appid=' + weatherApi.appId;

		if (_log) console.group('tw.openweather.getWeatherCurrentNearby()');
        if (_log) console.log('latitude: ', latitude);
		if (_log) console.log('longitude: ', longitude);
		if (_log) console.log('urlCurrent: ', urlCurrent);
        if (_log) console.groupEnd();

        return fetch(urlCurrent)
            .then(res => res.json())
            .then(data => {
                if (_log) console.log('tw.openweather.data: ', data);
                return processDataCurrent(data);
            })
            .catch(err => {
                console.error(err);
            });
	}



    // Create structured object for weather methods
    var processDataCurrent = function (data) {
        var processedData = 
        {
            ...data,
            DateDisplay: dayjs.unix(data.dt).format(dayjsFormat.threeDay),
            IconClass: getWeatherIcon(data),
            TempMain: Math.round(data.main.temp)
        } 

        return processedData;
    }

    

    /**
     * Get Weather 5day Forecast from lat/long
     * https://openweathermap.org/forecast5
     * @param {*} latitude 
     * @param {*} longitude 
    */
   
    var getWeatherForecastNearby = function (latitude, longitude, maxItems = 3) {
        var urlForecast = weatherApi.baseUrl + 'forecast?&units=' + weatherApi.units  + '&lat=' + latitude + '&lon=' + longitude + '&appid=' + weatherApi.appId;

        if (_log) console.group('tw.openweather.getWeatherForecastNearby()');
        if (_log) console.log('urlForecast: ', urlForecast);
        if (_log) console.groupEnd();

        return fetch(urlForecast)
            .then(res => res.json())
            .then(data => {
                if (_log) console.log('tw.openweather.getWeatherForecastNearby.data: ', data);
                return processDataForecast(data, maxItems);
            })
            .catch(err => {
                console.error(err);
            });
    }



    /**
     * processDataForecast - fetch with URL - return processDataForecast(data)
     * @param {*} data 
     * @param {*} maxItems 
     * @returns 
    */
    var processDataForecast = function (data, maxItems) {
        if (_log) console.group('tw.openweather.processDataForecast()');
        if (_log) console.log('data:', data);
        if (_log) console.log('maxItems:', maxItems);
        if (_log) console.groupEnd();

        var datesAdded = [],
            displayItems = []; 

        datesAdded.push(dayjs().format(dayjsFormat.serverDate));//add todays date to array, so we don't include with forecast below
        
        //add to array if is: after today, not yet in the array, & is afternoon, and less than max
        data.list.forEach(item => {

            var dtServer = dayjs.unix(item.dt).format(dayjsFormat.server),
				dtServerDate = dayjs.unix(item.dt).format(dayjsFormat.serverDate),
				//dtServerTime = dayjs.unix(item.dt).format(dayjsFormat.serverDate),
				dtServerTemp = dayjs(dtServerDate + ' 11:59:59').format(dayjsFormat.server),//12pm of current period date
				isAfternoon = dayjs(dtServer).isAfter(dayjs(dtServerTemp)),
                isAfterToday = dayjs(dtServer).isAfter(dayjs(), "day"),
				pos = datesAdded.indexOf(dtServerDate);

			// only show next 3 days after 12pm
			if (pos == -1 && displayItems.length < maxItems && isAfternoon && isAfterToday) {
                var processedItem = {
                    ...item,
                    DateDisplay: dayjs.unix(item.dt).format(dayjsFormat.threeDay),
                    IconClass: getWeatherIcon(item),
                    TempMain: Math.round(item.main.temp)
                }
                
				datesAdded.push(dtServerDate);
				displayItems.push(processedItem);
			}

        });

        var processedData = {
            ...data,
            displayItems: displayItems
        }
        if (_log) console.group('tw.openweather.processedData()');
        if (_log) console.log('displayItems:', displayItems);
        if (_log) console.groupEnd();

        return processedData;

    } 



    /**
	 * Get Weather Geocode
	 * 	https://openweathermap.org/geo
	*/
    var getAllWeatherByGeocode = function (city, country) {
        var urlCurrent = weatherApiGeo.baseUrl + 'direct?&units=' + weatherApiGeo.units  + '&q=' + city + ',' + country + '&appid=' + weatherApiGeo.appId;
		
        if (_log) console.group('tw.openweather.getAllWeatherByGeocode');
        if (_log) console.log('city: ', city);
		if (_log) console.log('country: ', country);
		if (_log) console.log('urlCurrent: ', urlCurrent);
        if (_log) console.groupEnd();

        return fetch(urlCurrent)     
            .then(res => res.json())
			.then(data => {
                if (_log) console.log('tw.Openweather.getAllWeatherByGeocode.data:', data);
                if (data && data[0] && data[0].lat) {

                    var promiseCurrent = getWeatherCurrentNearby(data[0].lat, data[0].lon)
                        .then(data => {
                            if (_log) console.log('tw.Openweather.Geocode.getWeatherCurrentNearby.data:', data);
                            return data;
                        })
                        .catch(err => {
                            if (_log) console.log('tw.Openweather.Geocode.getWeatherCurrentNearby.err:', err);
                            console.error(err);
                        });

                    var promiseForecast = getWeatherForecastNearby(data[0].lat, data[0].lon)
                        .then(data => {
                            if (_log) console.log('tw.Openweather.Geocode.getWeatherForecastNearby.data:', data);
                            return data;
                        })
                        .catch(err => {
                            if (_log) console.log('tw.Openweather.Geocode.getWeatherForecastNearby.err:', err);
                            console.error(err);
                        });

                    return Promise.all([promiseCurrent, promiseForecast])
                        .then((values) => {
                            if (_log) console.log('tw.OpenWeather.Geocode.Promise.all.values: ', values);
                            var allWeather = {
                                current: values[0],
                                forecast: values[1]
                            }
                            return allWeather;
                        });

                } else {
                    return {error: 'something went wrong'}
                }
                //return data;
			});
	}



    /**
     * Get weather icons
     * 
     * how to wire up OpenWeatherMap icon codes to the prettier 'weather icons'
     * https://gist.github.com/tbranyen/62d974681dea8ee0caa1
     * 
     * https://github.com/erikflowers/weather-icons
    */
    var getWeatherIcons = function (data) {

        if (_log) console.group('tw.openweather.getWeatherIcons()');

        return fetch('/JS/tw/conditions-reports/icons-weather.json')
            .then(res => res.json())
            .then(data => {
                if (_log) console.log('data:', data);
                if (_log) console.groupEnd();
                weatherIcons = data;
            })
            .catch(err => {
                console.error(err);
            });
    }
    getWeatherIcons();




    /**
     * Process weather id to get icon based on current time
     * @param {*} data 
     * @returns 
    */
    var getWeatherIcon = function (data) {
        //weatherApi.iconUrl + data.weather[0].icon + '.png',
        if (_log) console.log('openweather.getweathericon.data:', data);

        var code = data.weather[0].id,
            icon = weatherIcons[code].icon,
            xm = getDayNight(data),// (isAfternoon) ? 'day' : 'night',
            iconClass = 'wi wi-' + xm + '-' + icon;
            
        if (_log) console.group('tw.OpenWeather.getWeatherIcon()');
        if (_log) console.log('icon:', icon);
        if (_log) console.log('iconClass:', iconClass);
        if (_log) console.groupEnd();

        return iconClass;
    }




    /**
	 * Use sunrise/sunset times and figure out if NOW is during daylight hours
	*/
    var getDayNight = function (data) {
        if (_log) console.log('getDayNight().data', data);
        
        if(data.sys.sunrise && data.sys.sunset)
        {
            var date = new Date(),
                sunrise = new Date(data.sys.sunrise * 1000), //Convert a Unix timestamp to time
                sunset = new Date(data.sys.sunset * 1000);

            if (date.getHours() >= sunrise.getHours() && date.getHours() < sunset.getHours()) {
                return 'day';
            } else if (date.getHours() >= sunset.getHours()  || date.getHours() < sunrise.getHours()) {
                return 'night'
            }
        }
        else
        {
            return 'day';
        }
    }


    
    return {
        getWeatherCurrentNearby: getWeatherCurrentNearby,
        getWeatherForecastNearby: getWeatherForecastNearby,
        getAllWeatherByGeocode: getAllWeatherByGeocode
    }

}();