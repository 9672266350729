wdot.CityRegionsHelper = function() {
    return {
        regionMapClick: function (region) {
            console.log("region map click");
            //console.log('wdot.CityRegionsHelper.regionMapClick: ' + region);
            $('.citiesRegionsMap .region-map').attr("src", '/Images/public/regions/menu_' + region.replace(/-/g, '') + '.png');
            $('.map-list__link').removeClass('active');
            var dataName = region.replace(/-/g, '');
            $('.map-list__link.' + dataName).addClass('active');

            $('#Destinations-Region').val($('.js-map-list__link.active').data('value'));

        }, 
        setDropdownImage: function(name) {
            if (name == null || name == undefined || name.length == 0) {
                if (Modernizr.touch) {
                    name = "noselect-tablet";
                } else {
                    name = "noselect";
                }
            }
            var picExtension = '.jpg';
            if (name == 'noselect' || name == 'noselect-tablet') {
                picExtension = '.png';
            }

            $(".citiesRegionsDropdown .map").attr("src", "/Images/citiesregions/menu/menu_" + name.replace("-tablet", "") + ".png");
            $(".regionBlocks li").removeClass("selected");
            $(".regionBlocks li." + name).addClass("selected");
            return false;
        },
        preloadRegionDropdownImages: function() {
            new Image().src = "/Images/citiesregions/menu/bg-tab.jpg";
            new Image().src = "/Images/citiesregions/menu/bg-main.jpg";

            new Image().src = "/Images/citiesregions/menu/menu_central.png";
            new Image().src = "/Images/citiesregions/menu/menu_eastcentral.png";
            new Image().src = "/Images/citiesregions/menu/menu_northeast.png";
            new Image().src = "/Images/citiesregions/menu/menu_northwest.png";
            new Image().src = "/Images/citiesregions/menu/menu_southcentral.png";
            new Image().src = "/Images/citiesregions/menu/menu_southeast.png";
            new Image().src = "/Images/citiesregions/menu/menu_southwest.png";
            
        },

        getUrlVars: function () {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },

        initLoadRegionMap: function () {
            var onloadRegion = wdot.CityRegionsHelper.getUrlVars()['Region'],
                name = 'noselect'
            ;
            //console.log('initLoadRegionMap: ' + onloadRegion);
            if (onloadRegion != undefined) {
                $('.js-map-list__link').each(function (index, ele) {
                    if ($(this).data('value') == onloadRegion) {
                        $(this).addClass('active');
                        name = $(this).data('name').replace("-", "")
                    }
                });
                $('.citiesRegionsMap .region-map').attr("src", '/Images/public/regions/menu_' + name.replace("-", "") + '.png');
                $('#Destinations-Region').val(onloadRegion);
            }
        },

        initRegionMapButtons: function () {
            //console.log('LOAD wdot.CityRegionsHelper.initRegionMapButtons. found: ' + $('.js-map-list__link').length);
            wdot.CityRegionsHelper.initLoadRegionMap();

            $('.js-map-list__link').hover(
                function () {
                    var name = $(this).attr('data-name');
                    //console.log('hover: ' + name);
                    $('.citiesRegionsMap .region-map').attr("src", '/Images/public/regions/menu_' + name.replace("-", "") + '.png');
                }, function () {
                    var activeItem = $(".map-list__item .active");
                    var imgSrc = activeItem.attr('data-name');
                    if (imgSrc == undefined || imgSrc == '') imgSrc = 'noselect';
                    $('.citiesRegionsMap .region-map').attr("src", '/Images/public/regions/menu_' + imgSrc.replace("-", "") + '.png');
                }
            );

            $(".js-map-list__link").on('click', function () {
                var that = $(this),
                    value = String(that.data('value'))
                name = String(that.data('name')),//hyphen
                nameclean = name.replace("-", "")
                ;

                $('.citiesRegionsMap .region-map').attr("src", '/Images/public/regions/menu_' + nameclean + '.png');

                $('#Destinations-Region').val(value);
                $('.js-map-list__link').removeClass('active');
                $('.' + nameclean).addClass('active');
                return false;
            });


            $(".citiesRegionsMap").on('click', function () {
                //console.log('citiesRegionsMap.click');//CLEARS OUT MAP SELECTION
                wdot.CityRegionsHelper.setDropdownImage();
            });

            $(".citiesRegionsMap map").on('click', function (event) {
                //console.log('citiesRegionsMap map click');//DOES NOTHING, JUST BLOCK CLICKS FROOM BELOW
                event.stopPropagation();
            });

            $(".citiesRegionsMap map area").on('click', function (event) {
                //console.log('citiesRegionsMap map area region-name::: ' + $(this).data("region-name"));
                wdot.CityRegionsHelper.setDropdownImage($(this).data("region-name"));
                //$('#Destinations-Region').val($('.js-map-list__link.active').data('value'));
                event.stopPropagation();
            });


        }
    };
}();