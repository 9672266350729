var wdot = wdot || {};
wdot.TreeMenu = function () {
    var id = null,
            ulSelector = null,
            allowSortable = false,
            template = "",
            codeName = "",
            displayName = "",
            treeNodes = [],
            currentMenuCodeName = "",
            currentNodeLevel = 0,
            currentNode = null,
            currentNodeParents = [],
            currentPage = null,
            init = function (selector, autoLoad, selectable, templateName, menuName, sortable) {
                ulSelector = selector;

                if (sortable != undefined) allowSortable = sortable;

                var menuCodeName = 'TravelWisconsin.ContentTree';

                if (menuName != undefined) menuCodeName = menuName;

                if (autoLoad) {
                    if ($(ulSelector + ' #treeNodes').html().length == 0) loadTreeNodes(menuCodeName, 1);
                } else {
                    $(ulSelector + ' a#TreeMenuNav').click(function () {
                        if ($(ulSelector + ' #treeNodes').html().length == 0) loadTreeNodes(menuCodeName, 1);
                    });
                }

                if (selectable) {
                    initSelectable();
                }

                template = '#nodeTemplate';
                if (templateName != undefined) {
                    template = templateName;
                }

                $(ulSelector + ' a.navLink').each(function () {
                    var self = $(this);
                    if (window.location.pathname.indexOf(self.attr('href')) > -1) {
                        self.parent().addClass('activeNav');

                        if (self.hasClass('subNav')) {
                            var topParent = self.parent().parent().parent();
                            topParent.addClass('activeNav expdBtn');
                            topParent.children('ul').slideDown();

                            self.addClass('activeItem');
                        } else {
                            self.addClass('expdBtn');
                            loadTreeNodes(menuCodeName, 1);
                            self.parent('li').children('ul').slideDown();
                        }
                    }
                });
            },
            loadTreeNodes = function (menuCodeName, nodeLevel, parentId) {
                currentMenuCodeName = menuCodeName;
                currentNodeLevel = nodeLevel;

                Wdot.Ajax.prototype.Get('/Admin/TreeMenu/GetMenuTreeNodes', { menuCodeName: menuCodeName, parentId: parentId }, function (data) {
                    //treeNodes = data;
                    //$("#treeNodes").html('');

                    $(template).render(data).appendTo(ulSelector + ' #treeNodes');

                    loadNodePath();
                    bindNodeElements();
                }, null);

            },
            setCurrentNodePath = function (pageId, nodeId, parentIds) {
                currentPage = pageId;
                currentNode = nodeId;
                currentNodeParents = parentIds;
                if (currentNodeParents[0] == nodeId) currentNodeParents.reverse();
            },
            loadNodePath = function () {
                if (currentNodeParents.length > 0) selectNode(0, (currentNodeParents.length > 1)); // start with first node as next function is recursive
            },
            selectNode = function (i, continueLoop) {
                var node = $(ulSelector + ' #treeNodes li[data-id="' + currentNodeParents[i] + '"]');
                var nodeHighlight = $(ulSelector + ' #treeNodes li[data-id="' + currentNode + '"]');

                //use 'node' to hightlight all nodes in path. use 'nodeHighlight' to highlight one the current node
                nodeHighlight.children(ulSelector + ' .node-link').addClass('activeItem');

                if (parseInt(node.attr('data-numberofchildren')) > 0) {
                    Wdot.Ajax.prototype.Get('/Admin/TreeMenu/GetMenuTreeNodes', { menuCodeName: currentMenuCodeName, nodeLevel: parseInt(node.attr('data-nodelevel')) + 1, parentId: node.attr('data-id') },
                        function (data) {
                            //treeNodes = data;
                            $(template).render(data).appendTo(node.children('.treeNodeChildren'));
                            node.children('.node-expander').toggleClass('ui-icon-minus');
                            node.children('.treeNodeChildren').show();

                            var nextContinueLoop = i + 1 < currentNodeParents.length;
                            if (continueLoop) selectNode(i + 1, nextContinueLoop);
                        }, null);
                }
            },
            bindNodeElements = function () {
                $(ulSelector + ' .treeNode .node-expander').unbind();
                $(document).on("click", ulSelector + ' .treeNode .node-expander', (function (e) {
                    var self = $(this).parent();
                    if (self.children('.treeNodeChildren').css('display') == "none") {
                        //lazy load node via ajax
                        if (self.children('.treeNodeChildren').html().length == 0) {

                            Wdot.Ajax.prototype.Get('/Admin/TreeMenu/GetMenuTreeNodes', { menuCodeName: currentMenuCodeName, nodeLevel: parseInt(self.attr('data-nodelevel')) + 1, parentId: self.attr('data-id') }, function (data) {
                                //treeNodes = data;
                                $(template).render(data).appendTo(self.children('.treeNodeChildren'));
                            }, null);
                        }
                        self.children('.node-expander').toggleClass('ui-icon-minus');
                        self.children('.treeNodeChildren').show();
                        //bindNodeElements();
                    } else {
                        self.children('.node-expander').toggleClass('ui-icon-minus', 'ui-icon-plus');
                        self.children('.treeNodeChildren').hide();
                    }
                }));

                //open first level by defaul and hide expand/collapse
                if (currentNode == null) {
                    $(ulSelector + ' .treeNode').find('.node-expander').trigger('click');
                }
                $(ulSelector + ' .treeNode').find('.node-expander').remove();

                $(ulSelector + ' #ContentNew').unbind();
                $(ulSelector + ' #ContentNew').click(function () {
                    if (currentPage != null) {
                        $(this).attr('href', "/admin/contentpage/create/" + currentNode);
                        window.location = "/admin/contentpage/create/" + currentNode;
                    }

                    return false;
                });

                $(ulSelector + ' #ContentDelete').unbind();
                $(ulSelector + ' #ContentDelete').click(function () {
                    if (currentPage != null) {
                        $(this).attr('href', "/admin/contentpage/delete/" + currentPage);
                        window.location = "/admin/contentpage/delete/" + currentPage;
                    }
                    return false;
                });

                $(ulSelector + ' #ContentCopy').unbind();
                $(ulSelector + ' #ContentCopy').click(function () {
                    if (currentPage != null) {
                        Wdot.ModalWindow.LoadAjaxGet(400, 200, "/admin/contentpage/copy/" + currentPage);
                    }
                    return false;
                });

                $(ulSelector + ' #ContentMove').unbind();
                $(ulSelector + ' #ContentMove').click(function () {
                    if (currentPage != null) {
                        Wdot.ModalWindow.LoadAjaxGet(400, 200, "/admin/contentpage/move/" + currentPage);
                    }
                    return false;
                });

                $(ulSelector + ' #ContentUp').unbind();
                $(ulSelector + ' #ContentUp').click(function () {
                    if (currentPage != null) {
                        console.log('move up: ' + currentNode);
                        Wdot.Ajax.prototype.Get('/admin/treemenu/movetreenodeup', { nodeId: currentNode }, function (data) {
                            var current = $('li[data-id="' + currentNode + '"]');
                            current.prev().before(current);
                        }, null);
                    }
                    return false;
                });

                $(ulSelector + ' #ContentDown').unbind();
                $(ulSelector + ' #ContentDown').click(function () {
                    if (currentPage != null) {
                        console.log('move up: ' + currentNode);
                        Wdot.Ajax.prototype.Get('/Admin/TreeMenu/MoveTreeNodeDown', { nodeId: currentNode }, function (data) {
                            var current = $('li[data-id="' + currentNode + '"]');
                            current.next().after(current);
                        }, null);
                    }
                    return false;
                });

                if (allowSortable) {
                    // setup widget zone for sorting of tree nodes
                    $('#treeNodeContainer ul').sortable({
                        connectWith: '#treeNodeContainer ul',
                        placeholder: 'ui-state-highlight',
                        distance: 15,
                        update: function (event, ui) {
                            var node = ui.item.context,
                                    nodeId = $(ui.item.context).attr('data-id'),
                                    newParent = node.parentNode,
                                    newParentId = $(newParent).attr('data-parentid'),
                                    newPosition = 1;

                            $(newParent.childNodes).each(function () {
                                if ($(this).attr('data-id') == $(node).attr('data-id')) return false;
                                newPosition += 1;
                            });

                            moveTreeNode(nodeId, newParentId, newPosition);
                        }
                    });
                }
            },
            initSelectable = function () {
                // do nothing for now since bind events weren't working
            },
            selectTreeNodeCopy = function (newId) {
                copyTreeNode(currentNode, newId, 0, function (data) {
                    if (data.Success) {
                        Wdot.ModalWindow.close();
                        window.location = '/Admin/ContentPage/View/' + data.ContentPage.Id;

                        //wdot.TreeMenu.ClearMenu('#treeMenu');
                        //wdot.TreeMenu.Init('#treeMenu');
                    }
                });
            },
            copyTreeNode = function (nodeId, newParentId, newPosition, callBackFunction) {
                Wdot.Ajax.prototype.Get('/Admin/ContentPage/CopyContentPageByNodeId', { nodeId: nodeId, newParentId: newParentId }, function (data) {
                    if (callBackFunction != undefined) callBackFunction(data);
                }, null);
            },
            selectTreeNodeMove = function (newId) {
                moveTreeNode(currentNode, newId, 0, function (data) {
                    if (data.Success) {
                        Wdot.ModalWindow.close();
                        window.location = '/Admin/ContentPage/View/' + data.ContentId;

                        //wdot.TreeMenu.ClearMenu('#treeMenu');
                        //wdot.TreeMenu.Init('#treeMenu');
                    }
                });
            },
            moveTreeNode = function (nodeId, newParentId, newPosition, callBackFunction) {
                Wdot.Ajax.prototype.Get('/Admin/TreeMenu/MoveTreeNode', { nodeId: nodeId, newParentId: newParentId, newPosition: newPosition }, function (data) {
                    if (callBackFunction != undefined) callBackFunction(data);
                }, null);
            },
            clearMenu = function (menuName) {
                $(menuName + ' #treeNodes').empty();
            };
    return {
        Id: id,
        CodeName: codeName,
        DisplayName: displayName,
        Init: init,
        LoadTreeNodes: function (menuCodeName) {
            loadTreeNodes(menuCodeName, 1);
            return treeNodes;
        },
        SetCurrentNodePath: setCurrentNodePath,
        SelectTreeNodeCopy: selectTreeNodeCopy,
        CopyTreeNode: copyTreeNode,
        SelectTreeNodeMove: selectTreeNodeMove,
        MoveTreeNode: moveTreeNode,
        ClearMenu: clearMenu,
        CurrentNode: currentNode,
        TreeNodes: treeNodes,
        SelectNode: selectNode,
        CurrentNodeLevel: currentNodeLevel
    };
} ();