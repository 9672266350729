var Wdot;
(function (Wdot) {
    var TinyMCE = (function () {
        function TinyMCE() {
        }
        TinyMCE.loadTinyMCE = function (selector, configOverride, startingContent) {
            if (configOverride != undefined && configOverride != null && configOverride != '') {
                for (var propertyName in TinyMCE.defaultConfig) {
                    if (!configOverride.hasOwnProperty(propertyName)) {
                        configOverride[propertyName] = TinyMCE.defaultConfig[propertyName];
                    }
                }
                if (configOverride.hasOwnProperty("paste_preprocess")) {
                    configOverride["paste_preprocess"] = TinyMCE.pastePreprocessOverride;
                }
                $(selector).tinymce(configOverride);
            }
            else {
                $(selector).tinymce(TinyMCE.defaultConfig);
            }
            if (startingContent != undefined && startingContent != null && startingContent != '') {
                $(selector).html(startingContent);
            }
        };
        return TinyMCE;
    }());
    TinyMCE.tinyMCEScriptUrl = "/js/tiny_mce/tiny_mce.js";
    TinyMCE.defaultConfig = {
        script_url: TinyMCE.tinyMCEScriptUrl,
        theme: 'advanced',
        plugins: 'autolink,lists,spellchecker,pagebreak,style,layer,table,save,advhr,advimage,advlink,emotions,iespell,inlinepopups,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,xhtmlxtras,template',
        extended_valid_elements: "*[*]",
        valid_elements: "*[*]",
        schema: 'html5',
        theme_advanced_buttons1: 'code,|,bold,italic,underline,strikethrough,|,justifyleft,justifycenter,justifyright,justifyfull,|,styleselect,formatselect',
        theme_advanced_buttons2: 'cut,copy,paste,pastetext,pasteword,|,search,replace,|,bullist,numlist,|,undo,redo,|,link,unlink,anchor,image,',
        theme_advanced_buttons3: 'tablecontrols,|,hr,removeformat,visualaid,|,sub,sup,|,media,advhr,|,fullscreen',
        theme_advanced_buttons4: 'spellchecker,|,nonbreaking,blockquote,|,internalLinks,|,insertfile,insertimage',
        theme_advanced_toolbar_location: 'top',
        theme_advanced_toolbar_align: 'left',
        theme_advanced_statusbar_location: 'bottom',
        theme_advanced_resizing: true,
        convert_urls: false,
        skin: "o2k7",
        skin_variant: "silver",
        //Setup the new internal link button
        setup: function (ed, url) {
            ed.addButton('internalLinks', {
                title: 'Internal Links',
                image: '/images/admin/auto_link.png',
                onclick: function () {
                    ed.windowManager.open({
                        file: '/admin/helper/TinyMceButtonPopup',
                        width: 480,
                        height: 400,
                        inline: 1,
                    }, {
                        selection: ed.selection,
                        href: Wdot.TinyMCE.href,
                        contentType: Wdot.TinyMCE.contentType
                    });
                }
            });
            //Get the data from where the user places the cursor. If it is link info,
            //then populate the variables so that it can be passed to the popup window
            ed.onNodeChange.add(function (ed, cm, n, co) {
                var hrefNode = n.attributes.getNamedItem("href");
                var contentTypeNode = n.attributes.getNamedItem("data-contenttype");
                var href;
                var contentType;
                if (hrefNode) {
                    href = hrefNode.nodeValue;
                    Wdot.TinyMCE.href = hrefNode.nodeValue;
                }
                if (contentTypeNode) {
                    contentType = contentTypeNode.nodeValue;
                    Wdot.TinyMCE.contentType = contentTypeNode.nodeValue;
                }
            });
        }
        //        template_external_list_url: "/js/template_list.js",
        //        external_link_list_url: "/js/link_list.js",
        //        external_image_list_url: "/js/image_list.js", 
        //        media_external_list_url: "/js/media_list.js"
    };
    TinyMCE.pastePreprocessOverride = function (pl, o) {
        var tempDiv = $('<div/>').html(o.content).text();
        o.content = tempDiv.replace(/<(?:.|\n)*?>/gm, '');
    };
    Wdot.TinyMCE = TinyMCE;
})(Wdot || (Wdot = {}));