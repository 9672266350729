var wdot = wdot || {};
wdot.AudioPlayer = function (data) {
    var id = data.playerId,
			container = $("#" + data.containerId),
			myPlayer = $("#" + data.playerId),
			containerId = data.containerId,
			playerId = data.playerId,
			template = data.template,
			myPlayerData,
			options = {
			    ready: function (event) {
			        var media = {};
			        for (var i = 0; i <= data.suppliedMediaTypes.length; i++) {
			            media[data.suppliedMediaTypes[i]] = data.fileLocations[data.suppliedMediaTypes[i]];
			        }

			        $(this).jPlayer("setMedia", media);
			    },
			    swfPath: "/js/jPlayer/Jplayer.swf",
			    supplied: data.suppliedMediaTypes.join(),
			    cssSelectorAncestor: "#" + containerId,
			    wmode: "window",
			    keyEnabled: false
			},
			loadPlayer = function () {
			    if ($("#" + containerId).length == 0 || $("#" + playerId).length == 0) {
			        throw "jPlayer Template must be rendered before loading player.";
			    }

			    container = $("#" + containerId);
			    myPlayer = $("#" + playerId);
			    myPlayer.jPlayer(options);

			    myPlayerData = myPlayer.data("jPlayer");

			    container.find(".jp-play").click(function () {
			        wdot.AudioPlayerList.currentPlayingId(id);
			    });

			    container.find(".jp-play").click(playClickFunction);
			    
			    container.find(".jp-pause").click(function () {
			        wdot.AudioPlayerList.currentPlayingId("");
			    });


			    container.find('.jp-gui ul li').hover(
					function () { $(this).addClass('ui-state-hover'); },
					function () { $(this).removeClass('ui-state-hover'); }
				);
			},
			stopPlayer = function () {
			    wdot.AudioPlayerList.currentPlayingId("");
			    myPlayer.jPlayer("stop");
			},
			playClickFunction,
			renderTemplate = function (templateContainer) {
			    if (templateContainer) {
			        templateContainer.append(template);
			    } else {
			        $("body").append(template);
			    }
			    this.loadPlayer();
			};
    if (data.enablePlayOnlyOneMedia) {
        playClickFunction = wdot.AudioPlayerList.playAndStopOtherPlayers;
    }
    return {
        loadPlayer: loadPlayer,
        stopPlayer: stopPlayer,
        playClickFunction: function (fn) {
            if ($.isFunction(fn)) {
                this.playClickFunction = fn;
                if (container.length > 0) {
                    container.find(".jp-play").click(fn);
                }
            }
            else {
                this.playClickFunction = null;
            }
        },
        renderTemplate: renderTemplate,
        jPlayer: myPlayer,
        id: function () {
            return id;
        }
    };
};

wdot.AudioPlayerList = function () {
    var audioPlayers = [],
		audioPlayerTemplate = "",
		container = null,
        currentPlayingId = "",
		loadAudio = function (containerId, audioPlayerData) {
		    container = $("#" + containerId);
		    audioPlayerTemplate = $("<div/>").append(container.html());
		    container.empty();
		    $(audioPlayerData).each(function (index, item) {
		        var currentAudioPlayerTemplate = audioPlayerTemplate.clone();
		        currentAudioPlayerTemplate.find("#jquery_jplayer").attr("id", item.playerId);
		        currentAudioPlayerTemplate.find("#jp_container").attr("id", item.containerId);
		        currentAudioPlayerTemplate.find("[data-jp-title=true]").text(item.title);
		        currentAudioPlayerTemplate.find("[data-jp-description=true]").text(item.description);
		        item.template = currentAudioPlayerTemplate;
		        var ap = new wdot.AudioPlayer(item);
		        if ($.isFunction(item.playClickFunction)) {
		            ap.playClickFunction(item.playClickFunction);
		        }
		        ap.renderTemplate(container);
		        audioPlayers.push(ap);
		    });
		},
		stopAll = function () {
		    $(audioPlayers).each(function (index, item) {
		        item.stopPlayer();
		    });
		},
		playAndStopOtherPlayers = function () {
		    $(audioPlayers).each(function (index, item) {
		        if (item.id() != currentPlayingId) {
		            item.stopPlayer();
		        }
		    });
		};
    return {
        loadAudio: loadAudio,
        stopAll: stopAll,
        playAndStopOtherPlayers: playAndStopOtherPlayers,
        audioPlayers: audioPlayers,
        currentPlayingId: function (value) {
            if (value) {
                currentPlayingId = value;
            }
            return currentPlayingId;
        }
    };
} ();
wdot.AudioPlayerAutoLoader = function() {
    if($(".audio_list[data-audiodata]").length > 0) {
        $(".audio_list[data-audiodata]").each(function() {
            var id = $(this).attr("id") || '';
            var data = $(this).data("audiodata");
            if(id != '' && data) {
                wdot.AudioPlayerList.loadAudio(id, data);
            }
        });
    }
};
$(function () {
    wdot.AudioPlayerAutoLoader();
    $(".audio_list").removeClass("hidden");
});