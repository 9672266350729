var Wdot;
(function (Wdot) {
    var KoViewModels;
    (function (KoViewModels) {
        var PlaceEventBlogArticleMediaViewModel = (function () {
            function PlaceEventBlogArticleMediaViewModel(inputSelector, jsonSelector) {
                var _this = this;
                this.inputSelector = inputSelector;
                this.jsonSelector = jsonSelector;
                this.selectedItems = ko.observableArray();
                this.addItem = function () {
                    var valueSelectorName = "#";
                    valueSelectorName += _this.inputSelector;
                    var value = $(valueSelectorName).val();
                    var itemId = +$(valueSelectorName).attr("data-itemid");
                    var itemTypeId = +$(valueSelectorName).attr("data-itemtypeid");
                    if (value != undefined && value != '' && !_this.isItemAlreadyAdded(value)) {
                        var itemFromTextbox = new Wdot.BlogArticleMediaItem();
                        itemFromTextbox.name = value;
                        itemFromTextbox.id = itemId;
                        itemFromTextbox.displayOrder = _this.index++;
                        itemFromTextbox.itemTypeId = itemTypeId;
                        _this.selectedItems.push(itemFromTextbox);
                    }
                    $(valueSelectorName).val('');
                    $(valueSelectorName).attr("data-itemid", 0);
                    $(valueSelectorName).attr("data-itemtypeid", -1);
                    $("#" + _this.jsonSelector).val(ko.toJSON(_this.selectedItems()));
                };
                this.removeItem = function (itemToRemove) {
                    _this.selectedItems.remove(itemToRemove);
                    _this.index--;
                    $("#" + _this.jsonSelector).val(ko.toJSON(_this.selectedItems()));
                };
                this.loadItems = function (items) {
                    var itemsJson = $.parseJSON(items);
                    for (var prop in itemsJson) {
                        if (!itemsJson.hasOwnProperty(prop)) {
                            continue;
                        }
                        var value = itemsJson[prop]["Name"];
                        if (!_this.isItemAlreadyAdded(value)) {
                            var itemFromTextbox = new Wdot.BlogArticleMediaItem();
                            itemFromTextbox.name = value;
                            itemFromTextbox.id = itemsJson[prop]["Id"];
                            itemFromTextbox.displayOrder = itemsJson[prop]["Index"];
                            itemFromTextbox.itemTypeId = itemsJson[prop]["ItemTypeId"];
                            _this.selectedItems.push(itemFromTextbox);
                        }
                    }
                };
                this.index = 0;
            }
            PlaceEventBlogArticleMediaViewModel.prototype.isItemAlreadyAdded = function (item) {
                for (var i = 0; i < this.selectedItems().length; i++) {
                    if (this.selectedItems()[i].name === item) {
                        return true;
                    }
                }
                return false;
            };
            return PlaceEventBlogArticleMediaViewModel;
        }());
        KoViewModels.PlaceEventBlogArticleMediaViewModel = PlaceEventBlogArticleMediaViewModel;
    })(KoViewModels = Wdot.KoViewModels || (Wdot.KoViewModels = {}));
})(Wdot || (Wdot = {}));