
//Global Menu 
+function ($) {
    "use strict";
    // For info on what 'use strict' is: http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/

    // Constructor for the plugin
    var Navigation = function (element) {
        this.$element = $(element);
        this.$form = this.$element.find('.js-global-nav-form');
        this.$searchField = this.$form.find('.js-search-input');
        this.$searchSelect = $('.js-global-nav-form__select');
        this.$toggle = $('.js-navbar-toggle');
        this.$primaryNav = this.$element.find('.js-global-nav__primary');
        this.$utilityNav = this.$element.parent().parent().parent().find('.js-global-nav__icon');//says icon, but it's the full utility nav
        this.$utilityNavDesktop = $('.js-utility-menu');
        this.$listItems = this.$element.find('.js-dropdown');
        this.$utilityDropdowns = $('.js-global-nav__icon').find('.js-dropdown');
        this.expandLink = "<button class='global-nav__expander js-global-nav__expander'><span class='sr-only'>Toggle Submenu</span></button>";
		this.navWidth = this.$element.css('width');
        this.dropdowns = [];
        var isLayoutNew = $('body').hasClass('js-layout-new23');
        this.options = {
            utilityMenuBreakpoint: isLayoutNew ? "1200" : "1175",
            megamenuWidth: '820',
            utilityMenuIsMobile: null,
            utilityMenuBoundEvents: false
        };
        //console.log('this.options:', this.options);
        //console.log('this.$utilityNav:', this.$utilityNav.width());
        this.init();
    };

    Navigation.prototype.init = function () {
        var that = this;
        //console.log('INIT');
        this.findDropdowns();
        this.options.utilityMenuIsMobile = !this.breakpointCheck();
        this.dropdownOffsetTarget = $('.js-header-wrapper').offset().left;
        this.addExpanders();
        //console.log('this.options:', this.options);
        if (this.options.utilityMenuIsMobile) {
            this.toggleUtilityMenu(false);
        } else {
            that.toggleSearch(true);
        }
        $('.js-utility-menu').removeClass('force-mobile-hidden');
        this.$form.removeClass('js-loading');
        this.$toggle.on('click', function (e) {
            var $this = $(this);
            if ($this.hasClass('menu-shown') && that.$element.hasClass('shown')) {
                // IF the menu is open, close it on click
                that.closeMenu(that.$element, $this, false);
                that.$toggle.removeClass('menu-shown');
            } else if (that.$element.hasClass('shown') && !$this.hasClass('menu-shown')) {
                // If the menu is shown and search is clicked on
                //console.log('FOCUS ON SEARCH');
                that.$searchField.focus();
            } else {
                // Open the menu
                that.openMenu(that.$element, $this, false);
                if ($this.hasClass('js-search-focus')) {
                    //console.log('FOCUS ON SEARCH');
                    that.$searchField.focus();
                    $this.siblings('.js-navbar-toggle').addClass('menu-shown');
                }
            }
        });
        this.hoverMenu($('.js-has-dropdown'));
        $(window).smartresize(function () {
            that.calibrate();
        });
        this.findHoverNoMenu();

        $("#eventsMenuForm #MenuFormStartDate, #eventsMenuForm #MenuFormEndDate").datepicker({

            beforeShow: function (input, obj) {
                //use widget line, to force datepicker to load inline.
                $(input).after($(input).datepicker('widget'));
    
                //get dims of clicked input box for datepicker positioning
                var objLeft = $('#' + obj.id).position().left,
                    objTop = $('#' + obj.id).position().top,
                    objHei = $('#' + obj.id).outerHeight(),
                    objY = objTop + objHei + 8;
                ;
                //console.log('obj.id:' + obj.id + ' = ' + objLeft + ' x ' + objTop + ' + ' + objHei + ' + ' + objY);
    
                //USE SETTIMEOUT WITH ZERO WAIT, OTHERWISE TOP/LEFT GET OVERWRITTEN RIGHT AFTER BEFORESHOW.
                setTimeout(function () {
                    obj.dpDiv.css({ top: objY + 'px', left: objLeft + 'px' });
                }, 0);
    
                //debugger;
    
            },
    
            onSelect: function () {
                var sd = $("#eventsMenuForm input[name=StartDate]");
                var ed = $("#eventsMenuForm input[name=EndDate]");
                var prevStartDate = sd.attr("data-prevdate");
                var prevEndDate = ed.attr("data-prevdate");
                var startDate = new Date(sd.val());
                var endDate = new Date(ed.val());
    
                if ($(this).attr("name") == "StartDate") {
                    if (startDate > endDate) {
                        sd.val(prevStartDate);
                        alert('Start Date must be less than End Date');
                    } else {
                        sd.attr("data-prevdate", $(this).val());
                    }
                } else {
                    if (endDate < startDate) {
                        ed.val(prevEndDate);
                        alert('Start Date must be less than End Date');
                    } else {
                        ed.attr("data-prevdate", $(this).val());
                    }
                }
            }
        });
		
    };

    Navigation.prototype.findDropdowns = function () {
        var that = this;
        this.$listItems.each(function () {
            var $this = $(this);
            if ($this.hasClass('topLevel')) {
                var $thisDropdown = $this.children('.global-nav__dropdown');
            } else {
                var $thisDropdown = $this.children('.global-nav__dropdown-list');
            }
            
            if ($thisDropdown.length > 0) {
                that.dropdowns.push($this);
                $this.addClass("-has-dropdown js-has-dropdown");
            }
        });
        this.findSubmenus();
    };

    Navigation.prototype.findSubmenus = function () {
        //console.log('FIND SUB MENUS');
        this.$submenus = $('.js-sub-nav');
        this.$submenuLinks = $('.js-has-sub-nav');
        //this.subMenuHeaders(this.$submenus);
        // this.$submenuLinks.each(function () {
        //     var $this = $(this),
        //         $thisLink = $(this).children('a'),
        //         arrowMarkup = "<div class='global-nav__right-arrow'><i class='fas fa-chevron-right mr-2'></i></div>";
        //     $thisLink.append(arrowMarkup);
        // });
    };

    Navigation.prototype.subMenuHeaders = function ($submenus) {
        $submenus.each(function () {
            var $this = $(this),
                headerText = $this.siblings('a').text();
            $this.prepend('<h3 class="global-nav__menu-subheading">' + headerText + '</h3>');
        });
    };

    Navigation.prototype.addExpanders = function () {
        //console.log('addExpanders');
        var that = this,
            $dropdowns = $('.js-has-dropdown');
        $dropdowns.each(function () {
            var $this = $(this),
                $thisDropdown = $this.children('ul');
            $this.prepend(that.expandLink);
        });
        this.bindExpanders();        
    };

    /**
     * Look for buttons that don't have dropdown menus, add rollover state
     */
    Navigation.prototype.findHoverNoMenu = function () {
        //console.log('findHoverNoMenu()');
        var that = this;
        $('.js-no-menu-hover').each(function () {
            var $this = $(this);
            $this.hover(
                function() {
                    $(this).addClass('no-menu-hover');
                }, function() {
                    $(this).removeClass('no-menu-hover');
                }
            );
        });
    }

    Navigation.prototype.hoverMenu = function ($dropdowns) {
        var that = this;
            
        $dropdowns.each(function () {
            var $this = $(this),
                $thisDropdown = $this.children('.global-nav__dropdown'),
                dropdownConfig = {
                    over: function () {
                        if (!that.options.utilityMenuIsMobile) {
                            //console.log('HOVERING');
                            //console.log($thisFirstChild);
                            that.openMenu($thisDropdown, $this, true);
                            that.menuOnScreen($thisDropdown);
                            //$thisFirstChild.addClass('hovered');
                        }
                    },
                    timeout: 200, // number = milliseconds delay before onMouseOut
                    out: function () {
                        if (!that.options.utilityMenuIsMobile) {
                            //console.log('LEAVING');
                            that.closeMenu($thisDropdown, $this, true);
                        }
                    }
                },
                megaDropdownConfig = {
                    over: function () {
                        if (!that.options.utilityMenuIsMobile) {
                            //console.log('HOVERING');
                            that.openMegaMenu($thisDropdown, $this);
                            var $firstChildren = $thisDropdown.find('.global-nav__dropdown-item:first-child'),
                                $secondChildrenGroup = $firstChildren.find('.-secondary > .global-nav__dropdown-item'),
                                $secondChildren = $($secondChildrenGroup[0]),
                                $thirdChildrenGroup = $secondChildren.find('.-tertiary > .global-nav__dropdown-item'),
                                $thirdChildren = $($thirdChildrenGroup[0]);
                                
                            $firstChildren.addClass('hovered');
                            $secondChildren.addClass('hovered');
                            $thirdChildren.addClass('hovered');
                            //that.menuOnScreen($thisDropdown);
                        }
                    },
                    timeout: 200, // number = milliseconds delay before onMouseOut
                    out: function () {
                        if (!that.options.utilityMenuIsMobile) {
                            //console.log('megaDropdownConfig.out');
                            that.closeMenu($thisDropdown, $this, true);
                        }
                    }
                };
            if ($thisDropdown.hasClass('js-mega-menu')) {
                //console.log("MEGA MENU!");
                $this.hoverIntent(megaDropdownConfig);
            } else {
                $this.hoverIntent(dropdownConfig);
            }         
        });
        this.hoverSubMenus(this.$submenuLinks);
    };

    Navigation.prototype.hoverSubMenus = function ($submenus) {
        var that = this;
        $submenus.each(function () {
            var $this = $(this),
                $thisImages = $this.find('.js-img-window'),
                $thisSiblings = $this.siblings("li"),
                $hoverNav = $this.find('.hovered'),
                $thisSiblingsChildren = $thisSiblings.find('li'),
                $thisChildren = $this.find('.global-nav__dropdown-item'),
                $thisFirstChild = $($thisChildren[0]),
                subMenuConfig = {
                    over: function () {
                        //console.log($thisSiblings);
                        //console.log($thisSiblingsChildren);
                        $thisSiblings.removeClass('hovered');
                        $thisSiblingsChildren.removeClass('hovered');
                        $this.addClass('hovered');
                        $thisFirstChild.addClass('hovered');
                    },
                    timeout: 200,
                    out: function () {
                        console.log('hoverSubMenus.out');
                        //$this.removeClass('hovered');
                        if($this.hasClass('js-dropdown-cities')) {
                            console.log('hoverSubMenus.out.js-dropdown-cities');
                        } else {
                            $this.removeClass('hovered');
                        }
                    }
                };
            //console.log('This First Child: ');
            //console.log($thisFirstChild);
            $this.hoverIntent(subMenuConfig);
        });
    };

    Navigation.prototype.menuOnScreen = function ($dropdown) {
        // This makes sure the dropdown isn't slightly off screen to the right and causing
        // a horizontal scroll bar.
        var windowWidth = $(window).width(),
            $parentItem = $dropdown.parent('li'),
            dropdownWidth = $dropdown.width(),
            dropdownOffsetLeft = $dropdown.offset().left,
            dropdownOffsetRight = dropdownOffsetLeft + dropdownWidth,
            parentWidth = $parentItem.width(),
            parentOffsetLeft = $parentItem.offset().left,
            dropdownPositionLeft = $dropdown.position().left;
        // console.log('Window Width: ', windowWidth);
        // console.log('DropdownWidth: ', dropdownWidth);
        // console.log('Dropdown Offset Left: ', dropdownOffsetLeft);
        // console.log('Dropdown Offseet Right: ', dropdownOffsetRight);
        // console.log('Dropdown Position Left: ', dropdownPositionLeft);
        if (dropdownOffsetRight > windowWidth) {
            var adjustment = (parentWidth / 2) - (dropdownOffsetRight - windowWidth);
            $dropdown.css('left', adjustment + "px");
        }
    };
    

    //Navigation.prototype.moveExpanders = function (isMobile) {
    //    console.log('Toggle Expanders');
    //    var that = this;
    //    if (isMobile) {
    //        this.$listItems.each(function () {
    //            var $this = $(this),
    //                $expandLink = $this.find('.js-global-nav__expander').detach();
    //            $thisDropdown = $this.children('ul');
    //            if ($thisDropdown.length > 0) {
    //                if(that.breakpointCheck()){
    //                    $this.append(that.expandLink);
    //                } else {
    //                    $this.prepend(that.expandLink);
    //                }
    //            }
    //        });
    //    } else {
    //        this.$listItems.each(function () {
    //            var $this = $(this),
    //                $thisDropdown = $this.children('ul');
    //            if ($thisDropdown.length > 0) {
    //                if(that.breakpointCheck()){
    //                    $this.append(that.expandLink);
    //                } else {
    //                    $this.prepend(that.expandLink);
    //                }
                
    //            }
    //        });
    //    }
    //}

    Navigation.prototype.bindExpanders = function () {
        //console.log('BINDING!');
        var that = this,
            $button = $('.js-global-nav__expander');
        //console.log($button);
        $button.each(function() {
            var $this = $(this),
                $parent = $this.parent('li');
            if ($parent.hasClass('topLevel')) {
                var $dropdown = $this.siblings('.global-nav__dropdown');
            } else {
                var $dropdown = $this.siblings('.global-nav__dropdown-list');
            }
            //console.log("dropdown: " + $dropdown);
            $this.on('click', function(e) {
                //console.log('BUTTON CLICK!');
                if ($this.hasClass('menu-shown')) {
                    that.closeMenu($dropdown, $this, true);
                } else {
                    that.openMenu($dropdown, $this, true);
                }
            });
        });
    };

    Navigation.prototype.calibrate = function () {
        //console.log('calibrate');
        this.dropdownOffsetTarget = $('.js-header-wrapper').offset().left;
        if (this.breakpointCheck() && this.options.utilityMenuIsMobile) {
            // If screen is large enough and menu was mobile, switch to desktop
           // console.log('switch to desktop');
            this.toggleUtilityMenu(true);
            this.toggleSearch(true);
            this.$element.removeAttr('style');
        } else if (!this.breakpointCheck() && !this.options.utilityMenuIsMobile) {
            //console.log('switch to mobile');
            this.toggleUtilityMenu(false);
            this.toggleSearch(false);
        }
    };

    Navigation.prototype.toggleSearch = function (isMobile) {
        //console.log('toggleSearch');
        var that = this;
        if (isMobile) {
            this.$form.detach();
            this.$form.appendTo(this.$utilityNavDesktop);
        } else {
            this.$form.detach();
            this.$form.prependTo(this.$element);
        }
    };

    Navigation.prototype.toggleUtilityMenu = function (isMobile) {
        //console.log('toggleUtilityMenu');

        // console.log(this.$items);
        var that = this;
        if (isMobile) {
            this.$utilityNav.detach();
            //console.log('SWITCH TO DESKTOP');
            this.$utilityNav.prependTo(this.$utilityNavDesktop);

            this.options.utilityMenuIsMobile = false;
            if(!this.options.utilityMenuBoundEvents){
                this.hoverMenu($(".js-global-nav__icon .js-dropdown"));
            }
        } else {
            //console.log('this.$utilityNav:', this.$utilityNav.width());
            this.$utilityNav.detach();
            //console.log('SWITCH TO MOBILE:', this.$element);
            this.$utilityNav.appendTo(this.$element);

            this.options.utilityMenuIsMobile = true;
        }
    };

    // Returns true if screen is wide enough to show menu
    Navigation.prototype.breakpointCheck = function () {
        var breakpoint = this.options.utilityMenuBreakpoint,
            currentWindowWidth = $(window).width();
        // console.log('breakpoint: ', breakpoint);
        // console.log('current width: ', currentWindowWidth);
        // console.log('Modernizr: ', Modernizr);
        if (Modernizr.csstransforms) {
            //console.log('MATCH MEDIA!');
            if (!window.matchMedia("(min-width: " + this.options.utilityMenuBreakpoint + "px)").matches || $('body').hasClass('force-mobile')) {
                return false;
            } else {
                return true;
            }
        } else {
            //console.log('NO MATCH MEDIA');
            if (currentWindowWidth < this.options.utilityMenuBreakpoint || $('body').hasClass('force-mobile')) {
                return false;
            } else {
                return true;
            }
        }
        
    };

    Navigation.prototype.openMenu = function ($menu, $btns, secondary) {
		//console.log("opening");
		//console.log("bodyHeight: " + this.bodyHeight);
        var that = this;
        $btns.each(function () {
            $(this).addClass('menu-shown');
        });
        if (!Modernizr.csstransforms) {
            that.alignMenu($menu, $btns);
        }
		if (secondary === true) {
			$menu.slideDown(500, function() {
				$menu.addClass('shown');
			});
		} else {
			//console.log("toggle");
			$menu.show().addClass('shown').animate({
				left: "0"
			});
		}
		
        /*$menu.slideDown(500, function () {
            $menu.addClass('shown');
        });*/
    };
    Navigation.prototype.alignMenu = function ($menu, $btns) {
        //console.log('align menu');
        var $parentItem = $menu.parent('li');
        var parentWidth = $parentItem.width();
        var dropdownWidth = $menu.width();
        var dropdownLeftAdjustment = (dropdownWidth / 2) - (parentWidth / 2) ;
        $menu.css({
            "left": -dropdownLeftAdjustment + "px",
        });
    };

    Navigation.prototype.openMegaMenu = function ($menu, $btns) {
        //console.log('MEGE MENU TIME:', $menu);
        $btns.each(function () {
            $(this).addClass('menu-shown');
        });
        var $parentItem = $menu.parent('li');
        var dropdownWidth = $menu.width();//this.options.megamenuWidth;
        var parentWidth = $parentItem.width();
        var dropdownLeftAdjustment = Math.floor((dropdownWidth / 2) - (parentWidth / 2)) * -1;
        var leftMax = -450;
        if(dropdownLeftAdjustment < leftMax){
            dropdownLeftAdjustment = leftMax;
        }
        // console.log('parentWidth: ', parentWidth);
        // console.log('dropdownWidth: ', dropdownWidth);
        // console.log('dropdownLeftAdjustment: ', dropdownLeftAdjustment);

        $menu.css({
            "left": dropdownLeftAdjustment + "px",
        });
        $menu.slideDown(500, function () {
            $menu.addClass('shown');
        });
    };

    Navigation.prototype.closeMenu = function ($menu, $btns, secondary) {
        var $this = $(this);

        if (!this.$element.hasClass('js-prevent-close')) {
            $btns.removeClass('menu-shown');
            /*
        $menu.slideUp(500, function () {
            $menu.removeClass('shown').removeAttr('style');
        });*/
            var navDistance = '-' + this.navWidth;
            if (secondary === true) {
                $menu.slideUp(500, function() {
                    $menu.removeClass('shown').removeAttr('style');
                });
            } else {
                $menu.animate({
                    left: navDistance
                }, function() {
                    $menu.hide().removeClass('shown');
                });
            }
        }

    };

    // Navigation PLUGIN DEFINITION
    // =====================

    var old = $.fn.navigation

    $.fn.navigation = function (option) {
        return this.each(function () {
            var $this = $(this)
            new Navigation(this)
        })
    };

    $.fn.navigation.Constructor = Navigation;


    // navigation NO CONFLICT
    // ===============

    $.fn.navigation.noConflict = function () {
        $.fn.navigation = old;
        return this;
    };

    // Self init

    $('.js-global-nav').navigation();

}(jQuery);