var Wdot;
(function (Wdot) {
    var HomepageVideoHelper = (function () {
        function HomepageVideoHelper() {
        }
        HomepageVideoHelper.anchorHeadline = function (headlineSelector, containerSelector) {
            //console.group('HomepageVideoHelper.anchorHeadline()');
            // console.log('HomepageVideoHelper.headlineSelector: ', headlineSelector);
            // console.log('HomepageVideoHelper.containerSelector: ', containerSelector);
            if (headlineSelector && containerSelector) {
                //console.log('HomepageVideoHelper.headlineSelector.length: ', headlineSelector.length);
                if (headlineSelector.length > 0) {
                    var marqueeOffset = containerSelector.offset();
                    var marqueeHeight = containerSelector.height();
                    var marqueeBottom = marqueeOffset.top + marqueeHeight;
                    var windowHeight = $(window).height();
                    var headlineSelectorHeight = headlineSelector.height();
                    // console.log('marqueeOffset.top: ', marqueeOffset.top);
                    // console.log('marqueeHeight: ', marqueeHeight);
                    // console.log('marqueeBottom: ', marqueeBottom);
                    // console.log('windowHeight: ', windowHeight);
                    // console.log('headlineSelectorHeight: ', headlineSelectorHeight);
                    if (marqueeBottom > windowHeight) {
                        var headlineBottom = marqueeBottom - windowHeight;
                        var xtraPadding = 10;
                        var headlineBottomMin = marqueeBottom - (marqueeOffset.top + headlineSelectorHeight) - xtraPadding;
                        // console.log('headlineBottom: ', headlineBottom);
                        // console.log('headlineBottomMin: ', headlineBottomMin);
                        if(headlineBottomMin < headlineBottom) {
                            headlineBottom = headlineBottomMin;
                            //console.log('headlineBottom UPDATED: ', headlineBottom);
                        }
                        headlineSelector.css({
                            "position": "absolute",
                            "bottom": headlineBottom
                        });
                    }
                    else {
                        //console.log('HomepageVideoHelper.headlineBottom: ****');
                        headlineSelector.css({
                            "position": "",
                            "bottom": ""
                        });
                    }
                }
            }
            //console.groupEnd();
        };
        return HomepageVideoHelper;
    }());
    Wdot.HomepageVideoHelper = HomepageVideoHelper;
})(Wdot || (Wdot = {}));