var wdot = wdot || {};
wdot.MediaLibrary = function () {
    var listingPageViewModel = function () {
        var generateListingFilterJSON = function () {
            var filterKeyword = $("input[name='filterKeyword']").val(),
                id = $("input[name='Id']").val(),
                name = $("input[name='Name']").val(),
                author = $("input[name='Author']").val(),
                parentId = $("#CurrentNode_ParentNode_Id").val(),
                queryString = '',
                isPopup = $("#IsPopup").val(),
                selectMediaFunction = $("#SelectMediaFunction").val();


            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&Id=' + id;
            queryString += '&Name=' + name;
            queryString += '&Author=' + author;
            queryString += '&isPopup=' + isPopup;
            queryString += '&selectMediaFunction=' + selectMediaFunction;

            if (parentId != '') {
                queryString += '&ParentId=' + parentId;
                wdot.Paging.data.ParentId = parentId;
            }


            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.Id = id;
            wdot.Paging.data.Name = name;
            wdot.Paging.data.Author = author;
            wdot.Paging.data.isPopup = isPopup;
            wdot.Paging.data.SelectMediaFunction = selectMediaFunction;
            return queryString;
        },
        resetListingFilter = function () {
            $("input[name='filterKeyword']").val('');
            $("input[name='Id']").val('');
            $("input[name='Name']").val('');
            $("input[name='Author']").val('');
        };
        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    } ();
    return {
        ListingPageViewModel: listingPageViewModel
    };
} ();