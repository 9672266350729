var wdot = wdot || {};
wdot.TripPlannerAddToGroup = function () {

    var favoriteSelector = undefined;

    var setFavoriteSelector = function (data) {
        favoriteSelector = data;
    },

    tripGroups = function (data) {
        var isSelectable = data.Selectable === undefined ? false : data.Selectable;

        var tripGroupId = ko.observable(data.TripGroupId),
            name = ko.observable(data.Name),
            isSelected = ko.observable(data.IsSelected),
            userId = data.UserId,
            created = ko.observable(data.Created),
            selectable = ko.observable(isSelectable),
            isPublic = ko.observable(data.IsPublic),
            itemCount = data.ItemCount,
            itemImage = data.ImageUrl,
            detailLinkUrl = data.LinkUrl,
            originalName = data.Name;
        return {
            tripGroupId: tripGroupId,
            name: name,
            isSelected: isSelected,
            userId: userId,
            created: created,
            selectable: selectable,
            isPublic: isPublic,
            itemCount: itemCount,
            itemImage: itemImage,
            detailLinkUrl: detailLinkUrl,
            originalName: originalName
        };
    },

    tripGroupViewModel = function () {
        // Data
        var self = this;
        self.baseContentId = ko.observable();
        self.isPlace = ko.observable();
        self.tripGroups = ko.observableArray([]);
        self.newGroupText = ko.observable();
        self.originalState = function () { };
        self.savedTripGroups = ko.observableArray([]);
        self.element = ko.observable();
        self.detailPageElementClicked = ko.observable();
        self.removeClicked = ko.observable(true);
        self.newTripGroupCounter = 0;

        // Operations
        var
        addTripGroup = function (text) {
            var isSelectable;

            if ($('#AddToGroups').data('isselectable') === "true") {
                isSelectable = true;
            } else {
                isSelectable = false;
            }

            
            if (self.newGroupText()) {
                self.tripGroups.unshift(new wdot.TripPlannerAddToGroup.TripGroups({ Name: self.newGroupText(), IsSelected: isSelectable, Selectable: isSelectable, Created: true, ItemCount: 0, ImageUrl: "/Images/trip-planner/tripplanner_placeholder.jpg" }));
                self.newGroupText("");
            }
        },

        deleteTripGroup = function (itemId) {
            var tripGroup = ko.utils.arrayFirst(self.tripGroups(), function (tg) {
                return tg.tripGroupId() === parseInt(itemId);
            });

            if (tripGroup) {
                self.tripGroups.remove(tripGroup);
            }

            return ko.mapping.toJSON(self.tripGroups);
        },

        setViewModelData = function (data, contentId, isItemAPlace, clickedElement, isSelectable) {
            var element = document.getElementById("AddToGroups");
            var observableViewModel = wdot.TripPlannerAddToGroup.TripGroupViewModel();

            if (data === null) {
                observableViewModel.TripGroups.unshift(new wdot.TripPlannerAddToGroup.TripGroups({ Name: "My Favorites", IsSelected: false, Created: true }));
            } else {
                var mappedGroups = "";

                try {
                    mappedGroups = JSON.parse(data);
                }
                catch (e) {
                    mappedGroups = $.map(data, function (item) { return new wdot.TripPlannerAddToGroup.TripGroups(item) });
                }

                observableViewModel.TripGroups(mappedGroups);
                observableViewModel.SavedTripGroups(mappedGroups);
                self.savedTripGroups(mappedGroups);
                
            }

            observableViewModel.OriginalState.cache = data;
            observableViewModel.BaseContentId(contentId);
            observableViewModel.IsPlace(isItemAPlace); 
            observableViewModel.Element(element);
            observableViewModel.DetailPageElementClicked(clickedElement);

            $('#AddToGroups').show();

            return observableViewModel;
        },

        //Used by the content listing and directory pages.
        getTripGroups = function (contentId, isItemAPlace, clickedElement, isFavorited) {
            $.ajax("/tripplanner/GetItemTripGroups", {
                data: ko.toJSON({ baseContentId: contentId, isPlace: isItemAPlace }),
                cache: false,
                type: "post",
                contentType: "application/json",
                success: function (result) {
                    var observableViewModel = setViewModelData(result.TripGroups, contentId, isItemAPlace, clickedElement, true);
                    var existingContext = ko.contextFor(observableViewModel.Element());

                    if (existingContext && ko.isObservable(existingContext.$rawData)) {
                        existingContext.$rawData(observableViewModel); // update observable with new view model
                    } else {
                        ko.applyBindings(ko.observable(observableViewModel), observableViewModel.Element()); // initialize with observable view model
                    }

                    if (result.IsSessionUser) {
                        //console.log("TRUE!!");
                        //$('.login').openMenu();
                        //$('.js-global-nav').openMenu($('.login'), $('.js-global-nav'), true);
                    }
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Status: " + textStatus);
                    console.log("Error: " + errorThrown);
                    console.log(XMLHttpRequest.responseText);
                }
            });
        },

        //Used by the trip planner landing page
        getTripGroupsForLandingPage = function (tripGroupsJson) {
            return setViewModelData(tripGroupsJson, 0, false, null, false, false);
        },

        getQueryStringParams = function(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        updateFavoritesUi = function (linkSelector, favoriteCountString) {
            //Increment the number and build the html string
            var favoriteCount = parseInt(favoriteCountString) || 0;
            if (favoriteCount !== 0) {
                var htmlString = "(" + favoriteCount + ")";
                $(linkSelector).find('.js-favorites-count').html(htmlString);
            }
            
            if ($(linkSelector).hasClass("isFavorite")) {
                if (favoriteCount === 0) {
                    $(linkSelector).removeClass("isFavorite");
                }
            } else {
                if (favoriteCount > 0) {
                    $(linkSelector).addClass("isFavorite");
                }
            }

            var url = '/favorites/createfavoritesdropdown?isMobileDevice=' + window.matchMedia('(max-width:760px)').matches;
            $.get(url, function (data) {
                if (data != '') {
                    $("li.favorites.dropdown").html(data);
                }
            });
        },

        updateMap = function (updatePinNumbers, showAll) {
            

            var itemLocations = [];
            var counter = 1;

            var selector = "";

            if (showAll) {
                selector = $('.js-detail-item');
            } else {
                selector = $('.js-detail-item:visible');
            }

            selector.each(function () {
                itemLocations.push($(this).data());
                if (updatePinNumbers) {
                    $(this).find('js-detail-item-pin').text(counter++);

                }
            });

            
            wdot.TripPlanner.IsDetails = true;
            wdot.TripPlanner.LoadMap();
            wdot.TripPlanner.Planner.LoadPlanner(itemLocations);
            
        },

        updateTripGroupItemsGrid = function (result) {
            $('#AddToGroups').hide();

            //Updated the favorites text
            if (favoriteSelector) {
                setTimeout(function() { updateFavoritesUi(favoriteSelector, result.FavoriteCount) }, 50);
            }

            var tripGroupId = parseInt($('#js-tripgroup-id').val());

            //Set the favorite icon on the trip group detail page
            if (result.IsFavorited) {
                $(self.detailPageElementClicked()).find('.card__icon--favorite').addClass("favorited");
            } else {
                $(self.detailPageElementClicked()).find('.card__icon--favorite').removeClass("favorited");
            }

            //Determine if the trip group item should be hidden if it is removed from the group
            //If the user is viewing a shared or recommended group, then the tile should not be hidden
            if ($('#js-tripgroup-id') !== undefined) {
                
                if (getQueryStringParams('shared') === "" && getQueryStringParams('recommended') === "" && $.inArray(tripGroupId, result.TripGroupIds) === -1) {
                    $(self.detailPageElementClicked()).hide();
                    setTimeout(function() { updateMap(true) }, 50);
                }
            }
        },

        save = function () {
            var isFavorited = false;
            if (self.detailPageElementClicked()) {
                if (self.detailPageElementClicked().find('.card__icon--favorite')) {
                    isFavorited = self.detailPageElementClicked().find('.card__icon--favorite').hasClass('favorited');
                }
            }

            $.ajax("/tripplanner/SaveItemToTripGroups", {
                cache: false,
                data: ko.toJSON({ tripGroups: self.tripGroups, originalState: self.originalState.cache, contentId: self.baseContentId, isPlace: self.isPlace, isAlreadyFavorited: isFavorited }),
                type: "post",
                contentType: "application/json",
                success: function (result) {
                    var data = setViewModelData(result.TripGroups, 0, false, self.detailPageElementClicked());
                    
                    setTimeout(function () { updateTripGroupItemsGrid(result) }, 50);
                    //Send GA event
                    setTimeout(function() {
                        if (isFavorited && result.IsFavorited) {
                            ga('send', 'event', 'Trip Planner-Favorites', 'Add Favorite', self.detailPageElementClicked().find('.card-detail__title').attr("href"));
                        }
                    }, 50);

                    //if (callback) {
                    //    callback(data);
                    //}
                    //return data;
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Status: " + textStatus);
                    console.log("Error: " + errorThrown);
                    console.log(XMLHttpRequest.responseText);
                }
            });
        },

        uncheckAll = function () {
            ko.utils.arrayForEach(self.tripGroups(), function (trip) {
                trip.isSelected(false);
            });
        };

        return {
            TripGroups: self.tripGroups,
            NewGroupText: self.newGroupText,
            OriginalState: self.originalState,
            SavedTripGroups: self.savedTripGroups,
            GetTripGroups: getTripGroups,
            GetTripGroupsForLandingPage: getTripGroupsForLandingPage,
            Save: save,
            UncheckAll: uncheckAll,
            RemoveClicked: self.removeClicked,
            AddTripGroup: addTripGroup,
            BaseContentId: self.baseContentId,
            IsPlace: self.isPlace,
            Element: self.element,
            DetailPageElementClicked: self.detailPageElementClicked,
            UpdateMap: updateMap,
            DeleteTripGroup: deleteTripGroup,
        }
    }

    return {
        TripGroupViewModel: tripGroupViewModel,
        TripGroups: tripGroups,
        FavoriteSelector: setFavoriteSelector
    }
}();


var wdot = wdot || {};
wdot.TripPlannerLandingPage = function () {

    var tripPlannerLandingPageViewModel = function () {
        // Data
        var self = this;
        self.landingPageTripGroups = ko.observableArray([]);
        self.TripGroupViewModel = new wdot.TripPlannerAddToGroup.TripGroupViewModel();

        //Initialize the Trip Groups array
        var mappedGroups = $.map($("[data-tripgroups]").data("tripgroups"), function (item) { return new wdot.TripPlannerAddToGroup.TripGroups(item) });
        self.TripGroupViewModel.TripGroups(mappedGroups);
        self.landingPageTripGroups(mappedGroups);

        self.TripGroupViewModel.SavedTripGroups.subscribe(function (newValue) {
            var mappedGroups = $.map(newValue, function (item) { return new wdot.TripPlannerAddToGroup.TripGroups(item) });
            self.landingPageTripGroups(mappedGroups);
        });
        
        // Operations
        var getTripGroups = function (tripGroupsJson) {
            self.TripGroupViewModel.GetTripGroupsForLandingPage(tripGroupsJson, 0, false);
        },

        deleteTripGroup = function (tripGroupId) {
            return self.TripGroupViewModel.DeleteTripGroup(tripGroupId);
        };

        return {
            LandingPageTripGroups: self.landingPageTripGroups,
            TripGroups: self.TripGroupViewModel.TripGroups,
            TripGroupViewModel: self.TripGroupViewModel,
            GetTripGroups: getTripGroups,
            DeleteTripGroup: deleteTripGroup,
            NewGroupText: self.TripGroupViewModel.NewGroupText,
            AddTripGroup: self.TripGroupViewModel.AddTripGroup,
            Save: self.TripGroupViewModel.Save
        }
    }

    return {
        TripPlannerLandingPageViewModel: tripPlannerLandingPageViewModel
    }
}();


var wdot = wdot || {};
wdot.TripPlannerGroupDetailItem = function () {

    var tripGroupItemViewModel = function () {
        // Data
        var self = this;
        self.TripGroupViewModel = new wdot.TripPlannerAddToGroup.TripGroupViewModel();
        self.itemId = ko.observable();
        self.note = ko.observable();
        self.element = ko.observable();


        //Initialize the Trip Groups array
        var mappedGroups = $.map($("[data-tripgroups]").data("tripgroups"), function (item) { return new wdot.TripPlannerAddToGroup.TripGroups(item) });
        self.TripGroupViewModel.TripGroups(mappedGroups);

        // Operations
        var getTripGroups = function (contentId, isItemAPlace, clickedElement, isFavorited) {
            self.element(clickedElement);
            self.TripGroupViewModel.GetTripGroups(contentId, isItemAPlace, clickedElement, isFavorited);
        },
            
        getTripGroupsForLandingPage = function (tripGroupsJson) {
             var observableViewModel = self.TripGroupViewModel.GetTripGroupsForLandingPage(tripGroupsJson);
             var existingContext = ko.contextFor(observableViewModel.Element());

             if (existingContext && ko.isObservable(existingContext.$rawData)) {
                 existingContext.$rawData(observableViewModel);   // update observable with new view model
             } else {
                 ko.applyBindings(ko.observable(observableViewModel), observableViewModel.Element()); // initialize with observable view model
             }
        }

        //save = function () {
        //    debugger;
        //    self.TripGroupViewModel.Save(self.element());
        //}

        return {
            ItemId: self.itemId,
            Note: self.note,
            Element: self.element,
            TripGroupViewModel: self.TripGroupViewModel,
            TripGroups: self.TripGroupViewModel.TripGroups,
            AddTripGroup: self.TripGroupViewModel.addTripGroup,
            NewGroupText: self.TripGroupViewModel.NewGroupText,
            Remove: self.TripGroupViewModel.remove,
            GetTripGroups: getTripGroups,
            GetTripGroupsForLandingPage: getTripGroupsForLandingPage,
            UpdateMap: self.TripGroupViewModel.UpdateMap,
            Save: self.TripGroupViewModel.Save,
        }
    }

    return {
        TripGroupItemViewModel: tripGroupItemViewModel,
        UpddateMap: tripGroupItemViewModel.UpdateMap
    }

    
}();