//shared/layout.cshtml

$(function () {
    //respImgs comes from images inserted into HTML Editor widget
    if (typeof respImgs !== "undefined" && respImgs && respImgs.length) {
        for (var i = 0; i < respImgs.length; i++) {
            responsiveEnhance(document.getElementById(respImgs[i]), 400);
        }
    }

    //Get the ticker message(s)
    if ($('#js-ticker').length > 0) {
        Wdot.Ajax.prototype.Get('/ticker/getallactivetickermessages?https=true', "", function (html) {
            //console.log('gotTickerMsg');
            $('#js-ticker').html(html);
            tw.StickyHeader.updateStickyHeader();
        } );
    }

    //Get the region ticker message(s)
    if ($('#js-region-ticker').length > 0) {
        var endpoint = '/ticker/GetRegionTickerMessage?regionId=' + $('#js-region-ticker').data('region-id') + '&regionName=' + $('#js-region-ticker').data('region-name') + '&https=true';
        Wdot.Ajax.prototype.Get(endpoint, "", function (html) {
            $('#js-region-ticker').html(html);
        });
    }

    if ($('#js-stickyfoot').width() != null) {
        //console.group('JS-STICKYFOOTER:');
        //console.log('js-stickyfoot found');
        $('body').addClass('sticky-padding');

        $('.js-stickyfoot__close').on('click', function () {
            $('#js-stickyfoot').remove();
            $('body').removeClass('sticky-padding');
            $.cookie('HideStickyFooter', 'true');
        });
        //console.groupEnd();
    }


    /**
     * Shade Color - tint a hex color light/dark
     * https://stackoverflow.com/a/13532993
     * usage:
     *   lighten: shadeColor("#63C6FF",40);
     *   darken: shadeColor("#63C6FF",-40);
     * @param {*} color 
     * @param {*} percent 
     * @returns 
    */
    var shadeColor = function (color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
    }

    if ($('.js-svg-fill-hover').length > 0) {
        var $curSVG = null,
            holdSVGFillColor = null;

        $('.js-svg-fill-hover').hover(
            function() {
                $curSVG = $(this).find('path, polygon, circle');
                var curFill = $curSVG.attr('fill'),
                    darkenFill = shadeColor(curFill, -50);
                // console.log('curFill:', curFill);
                // console.log('darkenFill:', darkenFill);
                holdSVGFillColor = curFill;
                $curSVG.attr('fill', darkenFill);
            }, function() {
                //console.log('holdSVGFillColor:', holdSVGFillColor);
                $curSVG.attr('fill', holdSVGFillColor);
            }
        );
    }

   
    wdot.CityRegionsHelper.preloadRegionDropdownImages();
    //var $slides = $('#slides');
    //var slideHeight = 0;

    //if ($slides && $slides[0]) {
    //    //lazyLoadImage();
    //    $slides.superslides({ 
    //        hashchange: false
    //        , play: 8000
    //    });
    //    slideHeight = $slides.height() - 30;
    //    $slides.css('height', slideHeight);
    //}

    
        $(".citiesRegionsDropdown").mousemove(function () {
            wdot.CityRegionsHelper.setDropdownImage();
        });

        $(".citiesRegionsDropdown map").mousemove(function(event) {
            event.stopPropagation();
        });

        $(".citiesRegionsDropdown map area, .citiesRegions .regionBlocks li").mousemove(function (event) { 
            wdot.CityRegionsHelper.setDropdownImage($(this).data("region-name"));
            event.stopPropagation();
        });
    

    wdot.County.loadCountyAutocompleteForPublicSite($(".citiesRegionsSearch"), ".countyAutoComplete", ".cityAutoComplete");
    wdot.City.loadCityAutocompleteForPublicSite($(".citiesRegionsSearch"), ".cityAutoComplete");
    wdot.City.loadCityAutocompleteForPublicSite($(".eventsDropdown"), ".cityFilterAutoComplete");

    $(".menu-destination-search").submit(function () {
		            
        var url = "";
        var searchString = "";
        var appendCityOrCounty = "";

        if (($("input[name='CityCitiesAndRegions']").data("cityurl")) !== "") {
            searchString = $("input[name='CityCitiesAndRegions']").val();
            appendCityOrCounty = "City: ";
            url = $("input[name='CityCitiesAndRegions']").data("cityurl");
        } else if (($("input[name='County']").data("countyurl")) !== "") {
            searchString = $("input[name='County']").val();
            appendCityOrCounty = "County: ";
            url = $("input[name='County']").data("countyurl");
        }

        ga('send', 'event', 'Top Navigation', 'Cities and Regions', appendCityOrCounty + searchString);

        if (url != undefined && url != "") {
            window.location = url;
        }

        return false;
    });

    $('#eventsMenuForm').submit(function() {
        var searchString = "";
        var appendCityOrKeyword = "";

        if (($("#EventsMenuKeyword").val()) !== "Search") {
            searchString = $("#EventsMenuKeyword").val();
            appendCityOrKeyword = " Keyword: ";
        }
        else if (($("#EventsMenuCity").val()) !== "") {
            searchString = $("#EventsMenuCity").val(); 
            appendCityOrKeyword = " City: ";
        }

        var searchStringValue = "Category: " + $("#CategoryId option:selected").text();
        if (searchString !== "" && appendCityOrKeyword !== "") {
            searchStringValue += ", " + appendCityOrKeyword + searchString;
        }
        
        ga('send', 'event', 'Top Navigation', 'Events', "Search " + searchStringValue);
    });

    $(".globalSearch").submit(function () {

        var searchString = "";
        var categoryString = "";

        if (($("input[name='search']").val()) !== "Search") {
            searchString = $("input[name='search']").val();
        }

        categoryString = $('.js-global-nav-form__select option:selected').text();
        categoryString.replace(/[\n\r]/g, '');

        if (categoryString === "") {
            categoryString = "All Content";
        }
        ga('send', 'event', 'Search', categoryString, searchString);

    });

    //for footer images
    $('.js-lazy').lazyload();
});



var tw = tw || {};
tw.StickyHeader = (function () {
    
    var initializeHeader = function () {
       // console.log('tw.StickyHeader.initializeHeader()');
        //console.log('HideStickyHeader: ' + $.cookie('HideStickyHeader'));

        if ($('#js-stickyhead').width() != null) {
            //console.log('js-stickyhead found');
    
            updateStickyHeader();
            // $('.js-stickyhead__close').on('click', function () {
            //     closeStickyHeader();
            // });
            $('.js-stickyhead__accept').on('click', function () {
                closeStickyHeader();
            });
        }
    }



    var updateStickyHeader = function(){
        //console.log('tw.StickyHeader.updateStickyHeader()');
        var tickerHei = $('#js-ticker').height();
        //console.log('tickerHei:' + tickerHei);

        if ($('#js-stickyhead').width() != null) {

            if (tickerHei > 0) {
                $('#js-stickyhead').css({'top': tickerHei + 'px'});
            } else {
                $('#js-stickyhead').css({'top': '0px'});
            }
    
            $('.js-stickyhead-spacer').css({
                'height': $('#js-stickyhead').height() + 'px'
            });
        }
    }

    var closeStickyHeader = function () {
        //console.log('tw.StickyHeader.closeStickyHeader()');
        $('#js-stickyhead').remove();
        $('.js-stickyhead-spacer').remove();
        $.cookie('HideStickyHeader', 'true', { expires: 3650 });//no expiration means session
        //console.log('HideStickyHeader: ' + $.cookie('HideStickyHeader'));
    }


	$(initializeHeader); //doc ready

	return {
		updateStickyHeader: updateStickyHeader
	}
}());