var wdot = wdot || {};
wdot.Site = function () {
    var loadSiteAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/sites/autocomplete", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    };
    return {
        loadSiteAutocomplete: loadSiteAutocomplete
    };
} ();