var wdot = wdot || {};
wdot.Directory = function () {
    var _log = false;
    var directoryEventSetUp = function () {
        if(_log)console.log('wdot.Directory.directoryEventSetUp()');

        //==Update==
        $(".js-directory").on("click", ".event-directory .update", function (e) {
            if(_log)console.log('.event-directory .filters .update click()');
           e.preventDefault();
           var currentPageNum = 1;
           var resetPaging = true;
           var isUserClicked = e.which;
           var currentPage = parseInt(wdot.Utils.GetUrlParameter("CurrentPage"), 10);
           wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), resetPaging, currentPageNum, null, null, true, "directory", isUserClicked, true);
        });

        //==Paging==
        $(".js-directory").on("click", ".event-directory .pagingContainer li a", function (e) {
           //var listingTypeInfo = processUrl($(this).parents(".directoryListSection").attr("id"));
           var isUserClicked = e.which;
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(this).parents(".event-directory"), false, $(this).attr("data-pagenumber"), null, null, true, "directory", isUserClicked, true);
            return false;
        });

        //==Clear==
        $(".js-directory").on("click", ".event-directory .filters .clear", function () {
            if(_log)console.log('.event-directory .filters .clear click()');
            wdot.ContentPages.DirectoryListing.ClearEventFilters(); 
            // wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), true, 1, null, null, true, "directory");
            setTimeout(() => {
                if(_log)console.log('timeout before reloading events');
                //debugger;
                wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), true, 1, null, null, true, "directory");
            }, 300);
        });

        wdot.Directory.DirectoryViewFilters();

        //$(".js-directory").on("click", ".event-directory .js-directory-attribute-container, .js-attributes-modal .js-directory-attribute-container", function () {
        $(".js-directory").on("click", ".js-attributes-modal .js-directory-attribute-container, .js-accessibility-modal .js-directory-attribute-container", function (evt) {
            evt.stopImmediatePropagation();
            evt.preventDefault();
            if(_log)console.log('.event-directory .js-directory-attribute-container.click::', $(this).find('input').attr('id'));
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).find("input:checkbox").attr('checked', false);
            } else {
                $(this).addClass("active");
                $(this).find("input:checkbox").attr('checked', true);
            }
            if(!$('.js-accessibility-modal').hasClass('hidden')) {
                //console.log('js-accessibility-modal is active');
                setTimeout(() => {
                    tw.AccessibilityFilter.updateModalDisplay();
                }, 100);
            }
        });

        //==Cookies for directories==
        if ($.cookie('Directories__NumberOfResults') != 'undefined') {
            $(".event-directory select[name=Results]").val($.cookie('Directories__NumberOfResults'));
        }

        if ($.cookie('Directories__UseLocation') != 'undefined') {
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', true);
            }
        }

        $(".js-directory").on("change", ".event-directory select[name=Results]", function () {
            $.cookie('Directories__NumberOfResults', $("select[name=Results]").val(), { expires: 7, path: '/' });
            directoryEventLoad();
        });

        if ($('.setLocation').hasClass('hasLocation')) {
            $("input[name=onoffswitch]").attr('checked', true);
            $.cookie('Directories__UseLocation', true, { expires: 7, path: '/' });
        }

       //Workaround for a bug with the datepicker plugin
        $("body").on("focus", ".filterdatepicker", function () {  
            var self = $(this);

            setTimeout(function () {
                self.datepicker({
                    onSelect: function () {
                        // console.log('body .filterdatepicker onSelect()');
                        // console.log('js-directory-2023.length:', $('.js-directory-2023').length);

                        var sd = $(".filters input[name=StartDate]");
                        var ed = $(".filters input[name=EndDate]");
                        var prevStartDate = sd.attr("data-prevdate");
                        var prevEndDate = ed.attr("data-prevdate");
                        var startDate = new Date(sd.val());
                        var endDate = new Date(ed.val());

                        if ($(this).attr("name") == "StartDate") {
                            if (startDate > endDate) {
                                sd.val(prevStartDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                sd.attr("data-prevdate", $(this).val());
                                if ($('.js-directory-2023').length > 0) {
                                    sd.val($(this).val());
                                    //console.log('trigger update now:', $(this).val());
                                    $('.js-filter-form-submit').trigger( "click" );
                                }
                            }
                        } else {
                            if (endDate < startDate) {
                                ed.val(prevEndDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                ed.attr("data-prevdate", $(this).val());
                                if ($('.js-directory-2023').length > 0) {
                                    ed.val($(this).val());
                                    $('.js-filter-form-submit').trigger( "click" );
                                }
                            }
                        }
                    }
                });
                self.datepicker("show");
            }, 10);
       });

        wdot.ContentPages.DirectoryListing.PlaceHolderEvents();

        $.address.externalChange(wdot.Directory.DirectoryExternalChange);
        if (firstRun) {
            firstRun = false;
            wdot.Directory.DirectoryExternalChange();
        }
    },
    directoryEventLoad = function () {
        if(_log)console.log('Directory.directoryEventLoad');
        var currentPage = 1;
        if ($.address.parameter("CurrentPage") != null) {
            currentPage = $.address.parameter("CurrentPage");
        }
        wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), false, currentPage, null, $.address.queryString(), true, "directory");
        directoryEventSetUp();
    },
    firstRun = true,
    directoryPlaceSetUp = function () {
        //console.log('wdot.Directory.directoryPlaceSetUp****************');

        //==Cookies for directories==
        if ($.cookie('Directories__NumberOfResults') != 'undefined') {
            $(".place-directory select[name=Results]").val($.cookie('Directories__NumberOfResults'));
        }

        $(".js-directory").on("change", ".place-directory select[name=Results]", function () {
            $.cookie('Directories__NumberOfResults', $("select[name=Results]").val(), { expires: 7, path: '/' });
            //directoryPlaceLoad();
        });

        if ($.cookie('Directories__UseLocation') != 'undefined') {
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', true);
            }
        }
        wdot.Directory.DirectoryViewFilters();

        //$(".js-directory").on("click", ".place-directory .js-directory-attribute-container, .js-attributes-modal .js-directory-attribute-container", function () {
        $("body").on("click", ".js-attributes-modal .js-directory-attribute-container, .js-accessibility-modal .js-directory-attribute-container", function (evt) {
            evt.stopImmediatePropagation();
            evt.preventDefault();
            if(_log)console.log('.place-directory .js-directory-attribute-container.click::', $(this).find('input').attr('id'));
            if ($(this).hasClass("active")) {
                //console.log('remove active!!');
                $(this).removeClass("active");
                $(this).find("input:checkbox").attr('checked', false);
            } else {
                //console.log('add active!!');
                $(this).addClass("active");
                $(this).find("input:checkbox").attr('checked', true);
            }

            if(!$('.js-accessibility-modal').hasClass('hidden')) {
                //console.log('js-accessibility-modal is active');
                setTimeout(() => {
                    tw.AccessibilityFilter.updateModalDisplay();
                }, 100);
            }
            //debugger;
        });

        //Note: mobile version of this code also shows up under \JS\tw\contentpages\hub-page.js
        $(".js-directory").on("click", ".place-directory .update", function (e) {
            if(_log)console.log('.js-directory .place-directory .update');
            e.preventDefault();
            var currentPageNum = 1;
            var resetPaging = true;
            var isUserClicked = e.which;
            //var currentPage = parseInt(wdot.Utils.GetUrlParameter("CurrentPage"), 10);
            var currentPage = 1;
            
            try {
                currentPage = parseInt(wdot.Utils.GetUrlParameter("CurrentPage"), 10);
            } catch (ex){ }

            wdot.ContentPages.DirectoryListing.LoadPage($(this).parents(".place-directory"), resetPaging, currentPageNum, null, null, true, 'directory', isUserClicked, true);
        });

        
        //Note: mobile version of this code also shows up under \JS\tw\contentpages\hub-page.js 
        $(".js-directory").on("click", ".place-directory .filters .clear", function () {
            wdot.ContentPages.DirectoryListing.ClearFilters();
            wdot.ContentPages.DirectoryListing.LoadPage($(this).parents(".place-directory"), true, 1, null, null, true, 'directory');
        });

        //Note: mobile version of this code also shows up under \JS\tw\contentpages\hub-page.js
        $(".js-directory").on("click", ".place-directory .pagingContainer li a", function (e) {
            var isUserClicked = e.which;
            wdot.ContentPages.DirectoryListing.LoadPage($(this).parents(".place-directory"), false, $(this).attr("data-pagenumber"), null, null, true, 'directory', isUserClicked, true);
            return false;
        });

        wdot.ContentPages.DirectoryListing.PlaceHolderEvents();

        $.address.externalChange(wdot.Directory.DirectoryExternalChange);

    },
    directoryPlaceLoad = function () {
        var currentPage = 1;
        if ($.address.parameter("CurrentPage") != null) {
            currentPage = $.address.parameter("CurrentPage");
        }
        //wdot.ContentPages.DirectoryListing.LoadPage($(".place-directory"), false, currentPage, null, $.address.queryString(), true, 'directory');
        directoryPlaceSetUp();
    },
    directoryArticleSetUp = function () {
        $(".js-directory").on("click", ".article-directory .pagingContainer li a", function () {
            wdot.ContentPages.DirectoryListing.LoadPage($(this).parents(".article-directory"), false, $(this).attr("data-pagenumber"), null, null, true, "articleList");
            return false;
        });
    },
    directoryMediaSetUp = function () {
        $(".media-directory").on("click", ".pagingContainer li a", function () {
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(this).parents(".media-directory"), false, $(this).attr("data-pagenumber"), null, null, true, "mediaList");
            return false;
        });
    },
    directoryCheckQueryString = function () {
        if(_log)console.log('Directory.directoryCheckQueryString');
        if ($.address.parameter('PageType') == 'place') {
            wdot.ContentPages.DirectoryListing.LoadPage($(".place-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, 'directory');
        }
        if ($.address.parameter('PageType') == 'event') {
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, "directory");
        }
    },
    directoryExternalChange = function () {
        if(_log)console.log('Directory.directoryExternalChange $.address.queryString(): ', $.address.queryString());

        if ($.address.parameter('PageType') == 'deal') {
            wdot.ContentPages.DirectoryListing.LoadDealsPage($(".deal-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, 'directory');
        }
        if ($.address.parameter('PageType') == 'place') {
            wdot.ContentPages.DirectoryListing.LoadPage($(".place-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, 'directory');
        }
        if ($.address.parameter('PageType') == 'event') {
            //console.log('loadevents from directoryExternalChange');
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, "directory");
        }
        if ($.address.parameter('PageType') == 'article') {
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(".article-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, "articleList");
        }
        if ($.address.parameter('PageType') == 'media') {
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(".media-directory"), false, $.address.parameter('CurrentPage'), null, $.address.queryString(), false, "mediaList");
        }

    },
    locationSetUpForCategoryPage = function () {
        if ($.cookie('Directories__UseLocation') != 'undefined') {
            ;
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', true);
            }
        }

        $(".directorySearchHeader").on("click", "input[name=onoffswitch]", function () {
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', false);
            }
            if ($("input[name=onoffswitch]").is(":checked")) {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
						function (position) {
						    var lat = position.coords.latitude;
						    var lon = position.coords.longitude;
						    $.ajax({
						        url: '/Account/SetLocation',
						        type: "POST",
						        data: {
						            latitude: lat,
						            longitude: lon
						        },
						        beforeSend: function () {
						        },
						        complete: function () {
						        },
						        success: function (data) {
						            if (data.Success) {
						                $.cookie("SetLocationCityId", data.CityId, { expires: 7, path: '/' });
						                $.cookie("SetLocationCountyId", data.CountyId, { expires: 7, path: '/' });
						                $(".setLocation").replaceWith(data.Html);
						                var cityName = $(data.Html).find(".locationName").text();
						                ;
						                $(".directoryFilters input[name=City]").val(cityName);
						                $(".directoryFilters input[name=City]").text(cityName);
						                $(".directoryFilters input[name=City]").attr("data-cityname", cityName);
						                $(".directoryFilters input[name=City]").attr("data-cityid", data.CityId);
						                $(".directoryFilters input[name=City]").attr("data-countyid", data.CountyId);
						                $(".directoryFilters input[name=County]").attr("data-countyid", data.CountyId);
						                $(".directoryFilters input[name=City]").attr("data-itemid", data.CityId);
						            }
						        },
						        error: function (xhr, status, error) {
						            log(xhr, status, error);
						        }
						    });
						},
						function (error) {
						    console.error(error);
						},
                        { timeout: 10000 });
                }
                $.cookie('Directories__UseLocation', true, { expires: 7, path: '/' });

            } else {
                resetLocation();

            }
        });
    },
    populateEventsFilter = function () {
        var urlParameters = {}; window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) { params[key] = value; });
        $(".filterkeyword").val(urlParameters.Keyword);
        $(".city").val(urlParameters.City);
        $("select[name='MileRange']").val(urlParameters.MileRange);
        $("input[name='StartDate']").val(urlParameters.StartDate);
        $("input[name='EndDate']").val(urlParameters.EndDate);

        //$(".update").trigger("click");
    },
    populatePlacesFilter = function () {
        var urlParameters = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) { urlParameters[key] = value; });

        $(".filterkeyword").val(urlParameters.Keyword);
        $(".city").val(urlParameters.City);
        $("select[name='MileRange']").val(urlParameters.MileRange);
        $("#RegionId").val(urlParameters.Region);

        //$(".update").trigger("click");
    },
    resetLocation = function() {
        //$.cookie('Directories__UseLocation', false, { expires: 7 });
        if (!$.removeCookie('Directories__UseLocation')) {
            $.cookie('Directories__UseLocation', false, { expires: 7 });
        }

        $.removeCookie("SetLocationCityId", { expires: 7, path: '/' });
        $.removeCookie("SetLocationCountyId", { expires: 7, path: '/' });

        $(".setLocation").html("<span class='hideForMobile'>Set Your Location</span>");
        $(".setLocation").removeClass("hasLocation");
        wdot.Utils.DeleteCookie("SetLocationCityId");
        wdot.Utils.DeleteCookie("SetLocationCountyId");
        $("input[name=City]").attr("data-cityname", "");
        $("input[name=City]").attr("data-cityid", "");
        $("input[name=City]").attr("data-countyid", "");
        $("input[name=City]").attr("data-countyname", "");
        $("input[name=County]").attr("data-countyname", "");
        $("input[name=County]").attr("data-countyid", "");
        $("input[name=City]").attr("data-itemid", "");
        if ($('.event-directory').length > 0) {
            if(_log)console.log('Directory.resetLocation');
            wdot.ContentPages.DirectoryListing.LoadEventsPage($(".event-directory"), true, 1, null, null, true, "directory");

        }
        if ($('.place-directory').length > 0) {
            wdot.ContentPages.DirectoryListing.LoadPage($(".place-directory"), true, 1, null, null, true, 'directory');
        }

        

    },

    
    directoryDealSetUp = function () {

        if(_log)console.log('directory.js directoryDealSetUp()');
        //==Update==
        $("#deal-directory-widget").on("click", ".update", function (e) {
            e.preventDefault();
            wdot.ContentPages.DirectoryListing.LoadDealsPage($(".deal-directory"), true, 1, null, null, true, "directory");
        });

        //==Paging==
        $("#deal-directory-widget").on("click", ".pagingContainer li a", function () {
            //var listingTypeInfo = processUrl($(this).parents(".directoryListSection").attr("id"));
            wdot.ContentPages.DirectoryListing.LoadDealsPage($(this).parents(".deal-directory"), false, $(this).attr("data-pagenumber"), null, null, true, "directory");
            return false;
        });

        //==Clear==
        $("#deal-directory-widget, .mobileDirectoryList ").on("click", ".filters .clear", function () {
            wdot.ContentPages.DirectoryListing.ClearEventFilters(); 
            wdot.ContentPages.DirectoryListing.LoadDealsPage($(".deal-directory"), true, 1, null, null, true, "directory");
        });

        wdot.Directory.DirectoryViewFilters();

        $("#deal-directory-widget").on("click", ".js-directory-attribute-container", function () {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).find("input:checkbox").attr('checked', false);
            } else {
                $(this).addClass("active");
                $(this).find("input:checkbox").attr('checked', true);
            }
        });

        //==Cookies for directories==
        if ($.cookie('Directories__NumberOfResults') != 'undefined') {
            $(".deal-directory select[name=Results]").val($.cookie('Directories__NumberOfResults'));
        }

        if ($.cookie('Directories__UseLocation') != 'undefined') {
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', true);
            }
        }

        $("#deal-directory-widget").on("change", "select[name=Results]", function () {
            $.cookie('Directories__NumberOfResults', $("select[name=Results]").val(), { expires: 7, path: '/' });
            directoryDealLoad();
        });

        if ($('.setLocation').hasClass('hasLocation')) {
            $("input[name=onoffswitch]").attr('checked', true);
            $.cookie('Directories__UseLocation', true, { expires: 7, path: '/' });
        }

        $("#deal-directory-widget").on("click", "input[name=onoffswitch]", function () {
            if ($.cookie('Directories__UseLocation') == 'true') {
                $("input[name=onoffswitch]").attr('checked', false);
            }
            if ($("input[name=onoffswitch]").is(":checked")) {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var lat = position.coords.latitude;
                            var lon = position.coords.longitude;
                            $.ajax({
                                url: '/Account/SetLocation',
                                type: "POST",
                                data: {
                                    latitude: lat,
                                    longitude: lon
                                },
                                beforeSend: function () {
                                },
                                complete: function () {
                                },
                                success: function (data) {
                                    if (data.Success) {
                                        $.cookie("SetLocationCityId", data.CityId, { expires: 7, path: '/' });
                                        $.cookie("SetLocationCountyId", { expires: 7, path: '/' });
                                        $(".setLocation").replaceWith(data.Html);
                                        if ($('.deal-directory').length > 0) {
                                            wdot.ContentPages.DirectoryListing.LoadDealsPage($(".deal-directory"), false, 1, null, null, true, "directory");

                                        }
                                        if ($('.place-directory').length > 0) {
                                            wdot.ContentPages.DirectoryListing.LoadPage($(".place-directory"), true, 1, null, null, true, 'directory');
                                        }
                                    }
                                },
                                error: function (xhr, status, error) {
                                    log(xhr, status, error);
                                }
                            });
                        },
                        function (error) {
                            console.error(error);
                        },
                        { timeout: 10000 });
                }
                $.cookie('Directories__UseLocation', true, { expires: 7 });

            } else {
                resetLocation();
            }
        });

        $("body").on("click", ".favorites-bar", function () {
            wdot.Favorites.IsPlace = false;
            wdot.Favorites.ContentId = $(this).attr("data-id");
            wdot.Favorites.AddToFavorites(this);
        });

        wdot.City.loadCityAutocompleteForPublicSite($("#deal-directory-widget"), ".cityFilterAutoComplete");

        //Workaround for a bug with the datepicker plugin
        $("body").on("focus", ".filterdatepicker", function () {  
            var self = $(this);

            setTimeout(function () {
                self.datepicker({
                    onSelect: function () {
                        // if(_log)console.log('body .filterdatepicker onSelect()');
                        // if(_log)console.log('js-directory-2023.length:', $('.js-directory-2023').length);

                        var sd = $(".filters input[name=StartDate]");
                        var ed = $(".filters input[name=EndDate]");
                        var prevStartDate = sd.attr("data-prevdate");
                        var prevEndDate = ed.attr("data-prevdate");
                        var startDate = new Date(sd.val());
                        var endDate = new Date(ed.val());

                        if ($(this).attr("name") == "StartDate") {
                            if (startDate > endDate) {
                                sd.val(prevStartDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                sd.attr("data-prevdate", $(this).val());
                                if ($('.js-directory-2023').length > 0) {
                                    sd.val($(this).val());
                                    //if(_log)console.log('trigger update now:', $(this).val());
                                    $('.js-filter-form-submit').trigger( "click" );
                                }
                            }
                        } else {
                            if (endDate < startDate) {
                                ed.val(prevEndDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                ed.attr("data-prevdate", $(this).val());
                                if ($('.js-directory-2023').length > 0) {
                                    ed.val($(this).val());
                                    $('.js-filter-form-submit').trigger( "click" );
                                }
                            }
                        }
                    }
                });
                self.datepicker("show");
            }, 10);
        });



        wdot.ContentPages.DirectoryListing.PlaceHolderEvents();

        $.address.externalChange(wdot.Directory.DirectoryExternalChange);
        if (firstRun) {
            firstRun = false;
            wdot.Directory.DirectoryExternalChange();
        }
    },
    directoryDealLoad = function () {
        if(_log)console.log('directory.js directoryDealLoad()');
        var currentPage = 1;
        if ($.address.parameter("CurrentPage") != null) {
            currentPage = $.address.parameter("CurrentPage");
        }
        wdot.ContentPages.DirectoryListing.LoadDealsPage($(".deal-directory"), false, currentPage, null, $.address.queryString(), true, "directory");
        directoryDealSetUp();
    },
    directoryViewFilters = function () {
        //there's no reason for this to be directory specific

        $(".js-directory").on("click", ".viewFilters", function () {
            //('directoryViewFilters.viewFilters.click()');

            if ($(".directoryGrid__filters-attributes").hasClass("hidden")) {
                $(this).addClass('active');
                $(this).find('.js-view-filters-more').addClass('hidden');
                $(this).find('.js-view-filters-less').removeClass('hidden');
                $(".directoryGrid__filters-attributes").removeClass("hidden");

            } else {
                $(this).removeClass('active');
                $(this).find('.js-view-filters-more').removeClass('hidden');
                $(this).find('.js-view-filters-less').addClass('hidden');
                $(".directoryGrid__filters-attributes").addClass("hidden");

            }
        });
    };

    return {
        DirectoryEventSetup: directoryEventSetUp,
        DirectoryEventLoad: directoryEventLoad,
        DirectoryPlaceSetUp: directoryPlaceSetUp,
        DirectoryPlaceLoad: directoryPlaceLoad,
        DirectoryArticleSetUp: directoryArticleSetUp,
        DirectoryMediaSetUp: directoryMediaSetUp,
        DirectoryCheckQueryString: directoryCheckQueryString,
        DirectoryExternalChange: directoryExternalChange,
        LocationSetUpForCategoryPage: locationSetUpForCategoryPage,
        PopulateEventsFilter: populateEventsFilter,
        PopulatePlacesFilter: populatePlacesFilter,
        ResetLocation: resetLocation,
        DirectoryViewFilters: directoryViewFilters,
        DirectoryDealSetup: directoryDealSetUp
    };
} ();