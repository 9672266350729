var Wdot;
(function (Wdot) {
    var KoViewModels;
    (function (KoViewModels) {
        var PlaceEventNearbyLocationsViewModel = (function () {
            function PlaceEventNearbyLocationsViewModel() {
                var _this = this;
                this.index = 0;
                this.selectedItems = ko.observableArray();
                this.selectedItemsJsonString = ko.observable();
                this.addItem = function (inputSelector, jsonSelector) {
                    var valueSelectorName = "#" + inputSelector;
                    var value = $(valueSelectorName).val();
                    var itemId = +$(valueSelectorName).attr("data-itemid");
                    var placeTypeId = +$(valueSelectorName).attr("data-placetypeid");
                    if (value != undefined && value != '' && !_this.isItemAlreadyAdded(value)) {
                        var locationFromTextbox = new Wdot.NearByLocationItem();
                        locationFromTextbox.name = value;
                        locationFromTextbox.id = itemId;
                        locationFromTextbox.placeTypeId = placeTypeId;
                        //locationFromTextbox.Index = locationsJson[i].Index;
                        //locationFromTextbox.Distance = locationsJson[i].Distance;
                        locationFromTextbox.isManuallySelected = true;
                        locationFromTextbox.isNewlyAdded = true;
                        locationFromTextbox.isRemoved = ko.observable(false);
                        var count = _this.getActiveLocationsCount();
                        var indexToRemove = -1;
                        if (count === 8) {
                            indexToRemove = -1;
                            for (var i = _this.selectedItems().length - 1; i >= 0; i--) {
                                if (!_this.selectedItems()[i].isManuallySelected) {
                                    indexToRemove = i;
                                    break;
                                }
                            }
                        }
                        if (indexToRemove > -1) {
                            _this.selectedItems().splice(indexToRemove, 1);
                        }
                        _this.selectedItems.push(locationFromTextbox);
                    }
                    $(valueSelectorName).val('');
                    $(valueSelectorName).attr("data-itemid", 0);
                    $(valueSelectorName).attr("data-placetypeid", -1);
                    $("#" + jsonSelector).val(ko.toJSON(_this.selectedItems()));
                };
                this.removeItem = function (itemToRemove, jsonSelector) {
                    _this.index++;
                    itemToRemove.isRemoved(true);
                    $("#" + jsonSelector).val(ko.toJSON(_this.selectedItems()));
                };
                this.loadItems = function (items, manuallySelectedItems) {
                    var locationsJson = $.parseJSON(items);
                    var manuallySelectedJson = $.parseJSON(manuallySelectedItems);
                    _this.populateLocationsArray(locationsJson);
                    _this.populateLocationsArray(manuallySelectedJson);
                };
            }
            //Private functions
            PlaceEventNearbyLocationsViewModel.prototype.isItemAlreadyAdded = function (location) {
                for (var i = 0; i < this.selectedItems().length; i++) {
                    if (this.selectedItems()[i].name === location) {
                        return true;
                    }
                }
                return false;
            };
            PlaceEventNearbyLocationsViewModel.prototype.getActiveLocationsCount = function () {
                var count = 0;
                ko.utils.arrayForEach(this.selectedItems(), function (c) {
                    if (!c.isRemoved()) {
                        count++;
                    }
                });
                return count;
            };
            PlaceEventNearbyLocationsViewModel.prototype.populateLocationsArray = function (locationsString) {
                if (locationsString) {
                    for (var i = 0; i < locationsString.length; i++) {
                        var locationFromTextbox = new Wdot.NearByLocationItem();
                        locationFromTextbox.name = locationsString[i].Name;
                        locationFromTextbox.id = locationsString[i].Id;
                        locationFromTextbox.displayOrder = locationsString[i].Index;
                        locationFromTextbox.distance = locationsString[i].Distance;
                        locationFromTextbox.isManuallySelected = locationsString[i].IsManuallySelected;
                        locationFromTextbox.placeType = locationsString[i].PlaceType;
                        locationFromTextbox.placeTypeId = locationsString[i].PlaceTypeId;
                        locationFromTextbox.isRemoved = ko.observable(false);
                        this.selectedItems.push(locationFromTextbox);
                    }
                }
            };
            return PlaceEventNearbyLocationsViewModel;
        }());
        KoViewModels.PlaceEventNearbyLocationsViewModel = PlaceEventNearbyLocationsViewModel;
    })(KoViewModels = Wdot.KoViewModels || (Wdot.KoViewModels = {}));
})(Wdot || (Wdot = {}));