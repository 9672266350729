wdot = wdot || {};
wdot.YouTube = function () {
    var player360;
    var youTubeApiKey = "AIzaSyCSqNy-MFBj8lcom8a62kMN_Zg2I3o_MAw";
        var retrieveYouTubeVideo = function (youTubeId) {
            if (!youTubeId || youTubeId == '') {
                alert('Please Enter a YouTube Video Id.');
                return false;
            }

            
            var youTubeApiUrl = "https://www.googleapis.com/youtube/v3/videos?id=" + youTubeId + "&key=" + youTubeApiKey + "&part=snippet,statistics,contentDetails";

            $.getJSON(youTubeApiUrl).done(function (apiJSON) {
                var videoData = apiJSON.items[0].snippet;
                wdot.Widgets.youTubeViewModel.addYouTubeVideo(youTubeId, videoData.thumbnails["default"].url, videoData.title, videoData.description, true);
            }).fail(function () {
                alert("Error occured: " + arguments[2]);
            });
        },
        mediaSliderYouTubePlayers = [],
        onReadyMediaSlider = function () {
            $(".main .carousel-tabs a[role=tab]").click(function () {
                $(mediaSliderYouTubePlayers).each(function () {
                    this.stopVideo();
                });
            });

            try {
                //Initilze the YTPlayer plugin if it exists on this page
                initYTPlayerPlugin(youTubeApiKey);
            } catch (ex) {
                
            }

            if (player360) {
                player360.setPlaybackQuality("hd1080");
            }
            
        },
        on360VideoReady = function () {
            player360.setPlaybackQuality("hd1080");
        },
        populateEditForm = function (widget) {
            wdot.Widgets.youTubeViewModel.removeAllVideos();
            if (widget != undefined) {
                $(widget.properties).each(function () {
                    wdot.Widgets.youTubeViewModel.loadYouTubeVideos(this.videos);
                    if (this.htmlBefore != null) {
                        $("[data-propertyName='htmlBefore']").val(String(this.htmlBefore).replace(/&amp;/g, '&')
                                .replace(/&quot;/g, '"')
                                .replace(/&#39;/g, '\'')
                                .replace(/&lt;/g, '<')
                                .replace(/&gt;/g, '>'));
                    }

                    if (this.htmlAfter != null) {
                        $("[data-propertyName='htmlAfter']").val(String(this.htmlAfter).replace(/&amp;/g, '&')
                                .replace(/&quot;/g, '"')
                                .replace(/&#39;/g, '\'')
                                .replace(/&lt;/g, '<')
                                .replace(/&gt;/g, '>'));
                    }
                    $("[data-propertyName='widget-title']").val(wdot.Utils.HtmlDecode(this.title));
                    if (this.isActive) {
                        $("[data-propertyName='isActive']").attr("checked", "checked");
                    }
                });
            }
        },
        saveWidgetFunction = function (key) {
            var item = $("#widget-container [data-type='widget-zone'][data-zoneId='" + wdot.Widgets.widgetPropertiesViewModel.zone() + "'] [data-key='" + (key || wdot.Widgets.widgetPropertiesViewModel.key()) + "']");

            var widgetTitle = $("[data-propertyName='widget-title']").val();
            var htmlBefore = $("[data-propertyName='htmlBefore']").val();
            var htmlAfter = $("[data-propertyName='htmlAfter']").val();
            var isActive = $("[data-propertyName='isActive']:checked").length > 0;
            var propertiesList = [];
            var youTubeVideos = $(ko.toJS(wdot.Widgets.youTubeViewModel.videoList));
            if (youTubeVideos.length > 0) {
                youTubeVideos.each(function (index) {
                    delete this.isInEditMode;
                    this.displayOrder = (index + 1);
                    propertiesList.push(this);
                });

                var properties = {
                    videos: { youTubeVideo: propertiesList },
                    title: widgetTitle,
                    isActive: isActive,
                    htmlBefore: String(htmlBefore).replace(/&/g, '&amp;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#39;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;'),
                    htmlAfter: String(htmlAfter).replace(/&/g, '&amp;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#39;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                };
                wdot.Widgets.widgetPropertiesViewModel.properties(properties);
                item.html(htmlBefore + "<div class='widget-content currentTemplate' data-bind=\"template: '" + wdot.Widgets.widgetPropertiesViewModel.codename() + "_template'\"></div>" + htmlAfter);
                return { item: item, success: true };
            } else {
                return { item: item, success: false, message: "Please add a media item." };
            }

        },
        loadWidget = function (id, zone, codename) {
            $('.ui-widget  #widget-editor .toggle > header').prepend('<span class="expdBtn sprite sprite-expand"></span>');
            //basic toggle function with expand btn
            $(".toggle span.expdBtn").toggle(
            function () { // START FIRST CLICK FUNCTION
                $(this).parent('*').next('*').slideDown();
                if ($(this).hasClass('sprite-expand')) {
                    $(this).removeClass('sprite-expand').addClass('sprite-collapse');
                }
            }, // END FIRST CLICK FUNCTION
            function () { // START FIRST CLICK FUNCTION
                $(this).parent('*').next('*').slideUp();
                if ($(this).hasClass('sprite-collapse')) {
                    $(this).removeClass('sprite-collapse').addClass('sprite-expand');
                }
            }); // END TOGGLE FUNCTION


            $("#dialog").dialog("option", "title", "YouTube Video Listing");

            ko.applyBindings(wdot.Widgets.widgetPropertiesViewModel, document.getElementById("widget-buttons"));
            ko.applyBindings(wdot.Widgets.youTubeViewModel, document.getElementById("widget-editor"));
            wdot.Widgets.widgetPropertiesViewModel.id(id);
            wdot.Widgets.widgetPropertiesViewModel.zone(zone);
            wdot.Widgets.widgetPropertiesViewModel.codename(codename);
        },
        youTubePlayer = null,
        youTubePlayerArray = new Array(),
        initYouTubePlayer = function () {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },
        onReady = function (i) {
            $("#youtube-widget-" + i).find(".media-thumbnail__item").click(function () {
                var title = $(this).data("title"),
                        summary = $(this).data("summary"),
                        youtubevideoid = $(this).data("youtubevideoid");
                playVideo(youtubevideoid, i);
                $("#youtube-widget-" + i).find(".media-summary__header").text(title);
                $("#youtube-widget-" + i).find(".media-summary__text").html(summary.replace(/\r?\n/g, '<br />'));
                $("#youtube-widget-" + i).find(".media-thumbnail__item").removeClass("selected");
                $(this).addClass("selected");
            });
        },
        playVideo = function (videoId, i) {
            youTubePlayerArray[i].loadVideoById(videoId);
        },
        onPlayerStateChange = function (event) {

            if (player360) {
                // track when user clicks to Play
                if (event.data === YT.PlayerState.PLAYING) {
                    ga('send', 'event', 'ThompsonLP', 'Play Video', '360 Video');
                }

                if (event.data === YT.PlayerState.ENDED) {
                    player360.playVideo();
                }
            }
            
        },
        mediaSliderContainer = $(".youtube-media-slider-widget"),
        container = $(".youtube-widget"),
        isMobile = container.hasClass("youtube_mobile"),
        container360Video = $(".youtube-widget-360");

    if (!isMobile) {

        $(function () {
            if (container.length > 0) {
                console.log('youtube container found');
                window.onYouTubeIframeAPIReady = function () {
                    //debugger;
                    $(".youtube-widget").each(function (i) {
                        $(this).attr('id', 'youtube-widget-' + i);
                    });
                    $(".youTubePlayerClass").each(function (i) {
                        $(this).attr('id', 'youTubePlayer-' + i);
                        youTubePlayerArray[i] = new YT.Player('youTubePlayer-' + i, {
                            videoId: $("#youTubePlayer-" + i).data("first_youtube_video_id"),
                            playerVars: {
                                wmode: "opaque"
                            },
                            events: {
                                'onReady': onReady(i),
                                'onError': function (event) {
                                    console.log(event.data);
                                }
                            }

                        });
                    });
                };
                initYouTubePlayer();
            }

            if (mediaSliderContainer.length > 0) {
                console.log('youtube mediaSliderContainer found');
                window.onYouTubeIframeAPIReady = function () {
                    mediaSliderContainer.each(function () {
                        var youTubeId = $(this).data("youtube_video_id"),
                            id = "youTubePlayer_" + youTubeId;
                        mediaSliderYouTubePlayers.push(new YT.Player(id, {
                            videoId: youTubeId,
                            playerVars: {
                                wmode: "opaque"

                                // //jk test vars vv delete vv
                                // ,showinfo: 0,
                                // controls: 2,
                                // autohide: 0,
                                // autoplay: 1,
                                // loop: 1,
                                // mute: 1
                            },
                            events: {
                                "onReady": onReadyMediaSlider,
                                'onError': function (event) {
                                    console.log(event.data);
                                }

                            }
                        }));
                    });
                };
                initYouTubePlayer();
            }

            if (container360Video.length > 0) {
                console.log('youtube container360Video found');
                //debugger;
                //var player;
                window.onYouTubeIframeAPIReady = function () {
                    container360Video.each(function () {
                        var youTubeId = $(this).data("youtube_video_id"),
                            id = "youTubePlayer360_" + youTubeId;
                        player360 = new YT.Player(id, {
                            videoId: youTubeId,
                            playerVars: {
                                // wmode: "opaque"
                                showinfo: 0,
                                controls: 2,
                                autohide: 1,
                                loop: 1
                            },
                            events: {
                                "onReady": onReadyMediaSlider,
                                //"onReady": on360VideoReady,
                                'onError': function (event) {
                                    console.log(event.data);
                                },
                                'onStateChange': onPlayerStateChange

                            }
                        });
                    });
                };
                initYouTubePlayer();
            }
        });
    }

    

    return {
        populateEditForm: populateEditForm,
        saveWidgetFunction: saveWidgetFunction,
        loadWidget: loadWidget,
        retrieveYouTubeVideo: retrieveYouTubeVideo
    };
} ();