var wdot = wdot || {};
wdot.Tag = function () {
    var id = ko.observable(),
        tagValue = ko.observable(),
        inputName = '',
        index = 0,
        loadTagAutocomplete = function (selector) {
            var cache = {},
                lastXhr = '',
                itemSelected = false;
            if (selector == undefined) {
                selector = '#tag-value';
            }
            $(selector).autocomplete({
                source: function (request, response) {
                    itemSelected = false;
                    response([{ Id: "AJAX", Name: ''}]);
                    Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    var term = request.term;
                    if (term in cache) {
                        Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(cache[term]);
                        return;
                    }
                    lastXhr = Wdot.Ajax.prototype.Get("/admin/tags/autocomplete", request, function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(data);
                        }
                    });
                },
                focus: function (event, ui) {
                    $(selector).val(ui.item.TagValue);
                    return false;
                },
                select: function (event, ui) {
                    itemSelected = true;
                    $(selector).val(ui.item.TagValue);
                    return false;
                },
                close: function (event) {
                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                },
                minLength: 1
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                return $("<li></li>")
                    .data("ui-autocomplete-item", item)
                    .append("<a>" + item.TagValue + "</a>")
                    .appendTo(ul);
            };
        },
        loadViewAll = function () {
            Wdot.Ajax.prototype.AddAjaxLoader($('body'), true);
            Wdot.Ajax.prototype.Get("/admin/tags/viewall/", null, function (data, status, xhr) {
                $("#tags-view-all").append(data);
                $("#AllTagList input[type='checkbox']").each(function () {
                    if (wdot.Seo.seoContentTagsViewModel.isTagAlreadyAdded($(this).val())) {
                        $(this).attr('checked', 'checked');
                    }
                });
                Wdot.Ajax.prototype.RemoveAjaxLoader($('body'), true);
                $("#tags-view-all #tags-view-all").dialog({ resizable: false, modal: true, height: 500, close: function () {
                    $(this).dialog('destroy').remove();
                } 
                });
            });
        },
        closeViewAll = function () {
            $("#tags-view-all #LoadViewAll").removeClass("hidden");
            $("#tags-view-all #CloseViewAll").addClass("hidden");
            $("#tags-view-all #AllTagList").addClass("hidden");
        };
    return {
        Id: id,
        TagValue: tagValue,
        InputName: inputName,
        Index: index,
        LoadTagAutocomplete: loadTagAutocomplete,
        LoadViewAll: loadViewAll,
        CloseViewAll: closeViewAll
    };
} ();