var wdot = wdot || {};
wdot.PhotoCredit = function () {
    var photoCreditClick = function (element) {
        var photoCredit = $(element).closest(".js-photo-credit");
        if (photoCredit.hasClass("active")) {
            photoCredit.removeClass("active");
        } else {
            photoCredit.addClass("active");
        }
    },
    loadPermissionMembersAutocomplete = function (textboxSelector, selectorFind) {
         //is this needed???
    };
    return {
        photoCreditClick: photoCreditClick,
        loadPermissionMembersAutocomplete: loadPermissionMembersAutocomplete
    };
}();