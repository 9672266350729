var wdot = wdot || {};
wdot.TreeNode = function () {
    var id = ko.observable(),
            parentNodeId = ko.observable(),
            displayName = ko.observable(),
            alias = ko.observable(),
            aliasPath = ko.observable(),
            url = ko.observable(),
            altText = ko.observable(),
            showInMenu = ko.observable(),
            showInSitemap = ko.observable(),
            showInSearch = ko.observable(),
            nodeLevel = ko.observable(),
            displayOrder = ko.observable(),
            contentTypeId = ko.observable(),
            numberOfChildren = ko.observable(),
            children = ko.observableArray([]),
            contentPageId = ko.observable(),
            setChildNodes = function (nodes) {
                children(nodes);
            };
    return {
        Id: id,
        ParentNodeId: parentNodeId,
        DisplayName: displayName,
        Alias: alias,
        AliasPath: aliasPath,
        Url: url,
        AltText: altText,
        ShowInMenu: showInMenu,
        ShowInSitemap: showInSitemap,
        ShowInSearch: showInSearch,
        NodeLevel: nodeLevel,
        DisplayOrder: displayOrder,
        ContentTypeId: contentTypeId,
        NumberOfChildren: numberOfChildren,
        Children: children,
        ContentPageId: contentPageId
    };
} ();