var wdot = wdot || {};
wdot.Playlist = function () {
    
    var updateLinkUrl = function (linkInput, linkType) {
        if (linkInput.length > 0) {
            linkId = linkInput[linkInput.length - 1].id;
            $.ajax({
                type: 'GET',
                url: '/admin/ads/GetLinkUrl',
                dataType: 'json',
                data: { linkType: linkType, linkId: linkId },
                success: function (data) {
                    $('#Url').val(data.Url);
                    $('#placesListing #listItem, #eventsListing #listItem, #blogsListing #listItem, #articlesListing #listItem, #contentPagesListing #listItem, #mediaGalleryItemsListing #listItem').attr("disabled", true);
                    $('.viewAll').hide();
                    $("#TypeName").val(linkType);
                    $("#TypeId").val(linkId);

                    if (data.ImageId != 0) {
                        var mediaFile =
                        {
                            "Success": data.Success,
                            "DimensionsCorrect": data.DimensionsCorrect,
                            "CheckRequired": data.CheckRequired,
                            "FileName": data.FileName,
                            "FileGuid": data.FileGuid,
                            "FileSize": data.FileSize,
                            "FileMimeType": data.FileMimeType,
                            "FileExtension": data.FileExtension,
                            "FileLocation": data.FileLocation,
                            "FileWidth": data.FileWidth,
                            "FileHeight": data.FileHeight,
                            "ThumbnailImage": data.ThumbnailImage
                        };
                        wdot.MediaUpload.AddMediaFile(data.ImageId, data.ImageName, mediaFile);
                    }
                }
            });
        } else {
            $('#Url').val("");
            $("#TypeName").val("");
            $("#TypeId").val("");
            $('#placesListing #listItem, #eventsListing #listItem, #blogsListing #listItem, #articlesListing #listItem, #contentPagesListing #listItem, #mediaGalleryItemsListing #listItem').attr("disabled", false);
            $('.viewAll').show();
        }
    },
    getNextAds = function (currentIndex, numberOfResults) {
        currentIndex = parseInt(currentIndex);
        numberOfResults = parseInt(numberOfResults);
        if (currentIndex == undefined || currentIndex == "") {
            currentIndex = 0;
        }
        adGroupInclude = $('#playlistIncludeItems').text();
        adGroupExclude = $('#playlistExcludeItems').text();
        $.ajax({
            type: 'GET',
            url: '/playlist/getnextads',
            dataType: 'html',
            data: { adGroupInclude: adGroupInclude, adGroupExclude: adGroupExclude, currentIndex: currentIndex, numberOfResults: numberOfResults },
            success: function (data) {
                $('.events-nav-list').append(data);
                $('.playlist-slider__list').append(data);
                if (data != "") {
                    wdot.Playlist.GetNextAds(currentIndex + numberOfResults, numberOfResults);
                }
            }
        });
    },
    getNextAdsHomepage = function (currentIndex, numberOfResults) {
        adGroupInclude = $('#playlistIncludeItems').text();
        adGroupExclude = $('#playlistExcludeItems').text();
        $.ajax({
            type: 'GET',
            url: '/playlist/getnextadshomepage',
            dataType: 'html',
            data: { adGroupInclude: adGroupInclude, adGroupExclude: adGroupExclude, currentIndex: currentIndex, numberOfResults: numberOfResults },
            success: function (data) {
               
                $('.events-nav-list').append(data);
                $('.playlist-slider__list').append(data);
                if (data != "") {
                    wdot.Playlist.GetNextAdsHomepage(currentIndex + numberOfResults, numberOfResults);
                }
            }
        });
    },
    removeItem = function () {
        $("#MediaFileList").empty();
        $("#MediaUpload_UploadedItemsJson").val("[]");

    },
    resetPlaylistDisplay = function (itemCount, pageSize) {
        $(".events-nav-item").addClass("hidden");
        $(".next").addClass("hidden");
        $(".previous").addClass("hidden");

        if (itemCount > pageSize) {
            $.each($(".events-nav-item"), function (index, value) {
                if ($(this).data("number") > pageSize) {
                    $(this).addClass("hidden");
                } else {
                    $(this).removeClass("hidden");
                }
            });
            $('.next').removeClass("hidden");
        } else {
            $(".events-nav-item").removeClass("hidden");
        }
    };
    return {
        UpdateLinkUrl: updateLinkUrl,
        GetNextAds: getNextAds,
        GetNextAdsHomepage: getNextAdsHomepage,
        RemoveItem: removeItem,
        ResetPlaylistDisplay: resetPlaylistDisplay
    };
} ();