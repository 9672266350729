var wdot = wdot || {};
wdot.City = function () {
	//console.log('wdot.City loaded')
    var loadCityAutocomplete = function (selector, selectorFind, tripRandomizerVM) {
		console.log('loadCityAutocomplete:', selector, selectorFind);
        var cache = {},
			lastXhr = '',
			itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;

                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }

                lastXhr = Wdot.Ajax.prototype.Get("/admin/cities/autocompleteforcities", request, function (data, status, xhr) {
                    cache[term] = data;

                    if (xhr === lastXhr) {
                        Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(data);
                    }
                });
            },
            focus: function (event, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);

                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);

                if (tripRandomizerVM) {
                    tripRandomizerVM.CityId(ui.item.Id);
                }

                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
				.data("ui-autocomplete-item", item)
				.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + (item.Counties ? (", in " + item.Counties) : "") + "</a>")
				.appendTo(ul);
        };
    },
	loadCityAutocompleteForPublicSiteMenu = function (selector, selectorFind) {
		//console.log('loadCityAutocompleteForPublicSiteMenu:', selector, selectorFind);
	    var cache = {},
			lastXhr = '',
			itemSelected = false;
	    $(selector).find(selectorFind).autocomplete({
	        source: function (request, response) {
	            itemSelected = false;
	            response([{ Id: "AJAX", Name: ''}]);
	            var term = request.term;

	            if (term in cache) {
	                response(cache[term]);
	                return;
	            }

	            $('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]').append("<div class='ajaxLoaderAutocomplete' style='width: 100%; height: 16px; padding-top: 5px; padding-bottom:5px;'><div class='ajaxImage' style='background-image:url(/Images/ajax-loader.gif); margin:auto; width:16px; height:16px; padding:0px'></div></div>");

	            lastXhr = Wdot.Ajax.prototype.Get("/cities/autocompleteforcities", request, function (data, status, xhr) {
	                cache[term] = data;

	                if (xhr === lastXhr) {
	                    $(".ajaxLoaderAutocomplete").remove();
	                    response(data);
	                }
	            }, 'json', false);
	        },
	        focus: function (event, ui) {
	            $(selector).find(selectorFind).val(ui.item.Name);

	            return false;
	        },
	        select: function (event, ui) {
	            itemSelected = true;
	            $("[name=CityId]").val(ui.item.Id);
	            $("[name=CountyId]").val(ui.item.CountyId);
	            $(selector).find(selectorFind).val(ui.item.Name);
	            disableMileRangeForRoadTrips(selector, ui.item.IsRoadTrip);

	            return false;
	        },
	        close: function (event) {
	            Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	        },
	        minLength: 1
	    }).data("ui-autocomplete")._renderItem = function (ul, item) {
	        return $("<li></li>")
					.data("ui-autocomplete-item", item)
					.append("<a data-itemid='" + item.Id + "' data-countyid='" + item.CountyId + "'>" + item.Name + "</a>")
					.appendTo(ul);
	    };
	    $(selector).find(selectorFind).autocomplete("widget").appendTo(".primaryNav .events");
	},
    disableMileRangeForRoadTrips = function(selector, isRoadTrip) {
        if (isRoadTrip) {
            $(selector).find("select[name=MileRange]").attr("disabled", "true");
        } else {
            $(selector).find("select[name=MileRange]").removeAttr("disabled");
        }
    },
	loadCityAutocompleteForPublicSite = function (selector, selectorFind) {
		//console.log('loadCityAutocompleteForPublicSite:', selector, selectorFind);
	    var cache = {},
			lastXhr = '',
			itemSelected = false;
	    if ($(selector).find(selectorFind).length > 0) {
	        $(selector).find(selectorFind).autocomplete({
	            source: function (request, response) {
	                itemSelected = false;
	                response([{ Id: "AJAX", Name: ''}]);
	                var term = request.term;

	                if (term in cache) {
	                    response(cache[term]);

	                    return;
	                }

	                var regionFilter = parseInt($(selector).find(selectorFind).data("regionfilter"), 10),
						countyFilter = parseInt($(selector).find(selectorFind).data("countyfilter"), 10);

	                if (isNaN(regionFilter)) {
	                    regionFilter = 0;
	                }

	                if (isNaN(countyFilter)) {
	                    countyFilter = 0;
	                }

	                request.regionFilter = regionFilter;
	                request.countyFilter = countyFilter;

	                $('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]').append("<div class='ajaxLoaderAutocomplete' style='width: 100%; height: 16px; padding-top: 5px; padding-bottom:5px;'><div class='ajaxImage' style='background-image:url(/Images/ajax-loader.gif); margin:auto; width:16px; height:16px; padding:0px'></div></div>");

	                lastXhr = Wdot.Ajax.prototype.Get("/cities/autocompleteforcities", request, function (data, status, xhr) {
	                    cache[term] = data;

	                    if (xhr === lastXhr) {
	                        $(".ajaxLoaderAutocomplete").remove();
	                        response(data);
	                    }
	                }, 'json', false);
	            },
	            focus: function (event, ui) {
	                $(selector).find(selectorFind).val(ui.item.Name);

	                return false;
	            },
                select: function (event, ui) {
                    //debugger;
                    itemSelected = true;
                    var inputSelector = $(selector).find(selectorFind);

                    if (inputSelector) {
                        inputSelector.attr("data-cityname", ui.item.CityName);
                        inputSelector.attr("data-countyname", ui.item.CountyName);
                        inputSelector.attr("data-itemid", ui.item.Id);
                        inputSelector.attr("data-cityid", ui.item.Id);
                        $('input[name=CityId]').val(ui.item.Id);
                        inputSelector.attr("data-countyid", ui.item.CountyId);
                        $('input[name=CountyId]').val(ui.item.CountyId);
                        inputSelector.attr("data-regionid", ui.item.RegionId);
                        $('input[name=RegionId]').val(ui.item.RegionId);
                        inputSelector.attr("data-cityurl", ui.item.CityUrl);
                        inputSelector.val(ui.item.Name);
                        disableMileRangeForRoadTrips(selector, ui.item.IsRoadTrip);
                    }
	                return false;
	            },
	            close: function (event) {
	                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	            },
	            minLength: 1
	        }).data("ui-autocomplete")._renderItem = function (ul, item) {
				//debugger; //onmouseover=\"alert('onmouseover')\" onmouseout=\"alert('onmouseout')\"
	            return $("<li></li>")
					.data("ui-autocomplete-item", item)
					.append("<a data-itemid='" + item.Id + "' data-cityname='" + item.CityName + "' data-countyname='" + item.CountyName + "' data-cityid='" + item.CityId + "' data-countyid='" + item.CountyId + "' data-regionid='" + item.RegionId + "' class=\"js-autocomplete-item\">" + item.Name + "</a>")
					.appendTo(ul);
	        };
	    }
	},
	loadCityAutocompleteForCitiesAndRegions = function (selector, selectorFind) {
	    var cache = {},
			lastXhr = '',
			itemSelected = false;
	    if ($(selector).find(selectorFind).length > 0) {
	        $(selector).find(selectorFind).autocomplete({
	            source: function (request, response) {
	                itemSelected = false;
	                response([{ Id: "AJAX", Name: ''}]);
	                var term = request.term;

	                if (term in cache) {
	                    response(cache[term]);

	                    return;
	                }

	                var regionFilter = parseInt($(selector).find(selectorFind).data("regionfilter"), 10),
						countyFilter = parseInt($(selector).find(selectorFind).data("countyfilter"), 10);

	                if (isNaN(regionFilter)) {
	                    regionFilter = 0;
	                }

	                if (isNaN(countyFilter)) {
	                    countyFilter = 0;
	                }

	                request.regionFilter = regionFilter;
	                request.countyFilter = countyFilter;

	                $('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]').append("<div class='ajaxLoaderAutocomplete' style='width: 100%; height: 16px; padding-top: 5px; padding-bottom:5px;'><div class='ajaxImage' style='background-image:url(/Images/ajax-loader.gif); margin:auto; width:16px; height:16px; padding:0px'></div></div>");

	                lastXhr = Wdot.Ajax.prototype.Get("/Cities/AutocompleteForCitiesAndCounties", request, function (data, status, xhr) {
	                    cache[term] = data;

	                    if (xhr === lastXhr) {
	                        $(".ajaxLoaderAutocomplete").remove();
	                        response(data);
	                    }
	                }, 'json', false);
	            },
	            focus: function (event, ui) {
	                $(selector).find(selectorFind).val(ui.item.Name);

	                return false;
	            },
	            select: function (event, ui) {
	                itemSelected = true;
	                $(selector).find(selectorFind).attr("data-cityname", ui.item.CityName);
	                $(selector).find(selectorFind).attr("data-countyname", ui.item.CountyName);
	                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
	                $(selector).find(selectorFind).attr("data-countyid", ui.item.CountyId);
	                $(selector).find(selectorFind).attr("data-destinationurl", ui.item.DestinationUrl);
	                $(selector).find(selectorFind).val(ui.item.Name);

	                return false;
	            },
	            close: function (event) {
	                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	            },
	            minLength: 1
	        }).data("ui-autocomplete")._renderItem = function (ul, item) {
	            return $("<li></li>")
					.data("ui-autocomplete-item", item)
					.append("<a data-itemid='" + item.Id + "' data-countyid='" + item.CountyId + "'>" + item.Name + "</a>")
					.appendTo(ul);
	        };
	    }
	},
	lastSelectedCity,
	loadLocationCityAutocomplete = function (city) {
	    var lastXhr,
			cache = {},
			itemSelected = false;
	    $("#City").autocomplete({
	        source: function (request, response) {
	            itemSelected = false;
	            response([{ Id: "AJAX", Name: ''}]);
	            Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	            var term = request.term;

	            if (term in cache) {
	                Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	                response(cache[term]);

	                return;
	            }
	            lastXhr = Wdot.Ajax.prototype.Get("/admin/cities/autocompleteforcities", request, function (data, status, xhr) {
	                cache[term] = data;

	                if (xhr === lastXhr) {
	                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	                    response(data);
	                }
	            });
	        },
	        focus: function (event, ui) {
	            $("#City").val(ui.item.Name);
	            $("#City").attr("data-itemid", ui.item.Id);
	            $("#CityId").val(ui.item.Id);

	            return false;
	        },
	        select: function (event, ui) {
	            //                if (wdot.Listing.listingViewModel.Items.cities != undefined) {
	            //                    var itemFromListing = ko.utils.arrayFilter(wdot.Listing.listingViewModel.Items.cities(), function (item) { return item.id == $("#City").attr("data-itemid"); });
	            //                    if ($("#City").attr("data-itemid") != ui.item.Id && itemFromListing.length > 0) {
	            //                        wdot.Listing.listingViewModel.Remove(itemFromListing[0], "cities", $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Name_']").not("[data-isinherited='True']"));
	            //                    }

	            //                    if ($("#City").attr("data-itemid") != ui.item.Id && ko.utils.arrayFilter(wdot.Listing.listingViewModel.Items.cities(), function (item) { return item.id == ui.item.Id; }).length == 0) {
	            //                        wdot.Listing.listingViewModel.Add("cities", ui.item.Name, ui.item.Id, null, $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Id_']"), ui.item.Data);
	            //                    }
	            //                }
	            itemSelected = true;
	            city(ui.item.Name);
	            wdot.City.lastSelectedCity = ui.item.Name;
	            $("#City").attr("data-itemid", ui.item.Id);
	            $("#City").attr("data-listItemData", ui.item.Data);
	            $("#CityId").val(ui.item.Id);

	            return false;
	        },
	        close: function (event) {
	            var autocomplete = $(this).data("ui-autocomplete");
	            var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val() + '-' + $("#City").attr("data-itemid")), "i");
	            autocomplete.widget().children(".ui-menu-item").each(function () {
	                //Check if each autocomplete item is a case-insensitive match on the input
	                var item = $(this).data("ui-autocomplete-item");

	                if (matcher.test(item.Name + '-' + item.Id)) {
	                    //There was a match, lets stop checking
	                    autocomplete.selectedItem = item;

	                    return false;
	                }
	            });

	            //if there was a match trigger the select event on that match
	            //I would recommend matching the label to the input in the select event
	            if (autocomplete.selectedItem) {
	                autocomplete._trigger("select", event, {
	                    item: autocomplete.selectedItem
	                });
	            } else if (autocomplete.widget().find("#ui-active-menuitem").length == 0) {
	                var item = $(autocomplete.widget().children(".ui-menu-item")[0]).data("ui-autocomplete-item");

	                if (item.Id && typeof (item.Id) === "number") {
	                    autocomplete._trigger("select", event, {
	                        item: item
	                    });
	                } else {
	                    city(wdot.City.lastSelectedCity);
	                }
	            }
	        },
	        minLength: 1,
	        delay: 100
	    }).data("ui-autocomplete")._renderItem = function (ul, item) {
	        return $("<li></li>")
				.data("ui-autocomplete-item", item)
				.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + (item.Counties ? (", in " + item.Counties) : "") + "</a>")
				.appendTo(ul);
	    };
	},
	loadChildCitiesAutocomplete = function (parentId, selector, selectorFind) {
	    var cache = {},
		lastXhr = '',
		itemSelected = false,
		isShowAsCityChecked;
	    $(selector).find(selectorFind).autocomplete({
	        source: function (request, response) {
	            itemSelected = false;

	            if (isShowAsCityChecked != $("[Name='ShowCityGroupAsCity']").is(":checked")) {
	                cache = {};
	            }

	            response([{ Id: "AJAX", Name: ''}]);
	            Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	            var term = request.term;

	            if (term in cache) {
	                Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	                response(cache[term]);

	                return;
	            }

	            isShowAsCityChecked = $("[Name='ShowCityGroupAsCity']").is(":checked");
	            request.ShowCityGroupAsCity = isShowAsCityChecked;
	            lastXhr = Wdot.Ajax.prototype.Get("/admin/cities/autocompleteforchildcities/" + parentId, request, function (data, status, xhr) {
	                cache[term] = data;

	                if (xhr === lastXhr) {
	                    response(data);
	                }
	            });
	        },
	        focus: function (group, ui) {
	            $(selector).find(selectorFind).val(ui.item.Name);

	            return false;
	        },
	        select: function (event, ui) {
	            itemSelected = true;
	            $(selector).find(selectorFind).val(ui.item.Name);
	            $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
	            $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);

	            return false;
	        },
	        close: function (event) {
	            Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	        },
	        minLength: 1,
	        delay: 100
	    }).data("ui-autocomplete")._renderItem = function (ul, item) {
	        return $("<li></li>")
				.data("ui-autocomplete-item", item)
				.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
				.appendTo(ul);
	    };
	},
	listingPageViewModel = function () {
	    var generateListingFilterJSON = function () {
	        var filterKeyword = $("input[name='filterKeyword']").val();
	        var id = $("input[name='Id']").val();
	        var name = $("input[name='Name']").val();
	        var counties = $("input[name*='Counties.SelectedItems']");
	        var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");
	        var queryString = '';
	        queryString += '&filterKeyword=' + filterKeyword;
	        queryString += '&Id=' + id;
	        queryString += '&Name=' + name;

	        if (checkedStatuses.length > 0) {
	            var i = 0;
	            var selectedVersionStatuses = {};
	            ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
	            wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;
	        } else {
	            wdot.Paging.data.SelectedVersionStatuses = {};
	        }

	        if (counties.length > 0) {
	            var i = 0;
	            var selectedCounties = {};
	            ko.utils.arrayForEach(counties, function (t) { selectedCounties[i] = $(t).val(); queryString += '&Counties.SelectedItems[' + i + ']=' + $(t).val(); i++; });
	            wdot.Paging.data['Counties.SelectedItems'] = selectedCounties;
	        } else {
	            wdot.Paging.data['Counties.SelectedItems'] = {};
	        }

	        wdot.Paging.data.filterKeyword = filterKeyword;
	        wdot.Paging.data.Id = id;
	        wdot.Paging.data.Name = name;

	        return queryString;
	    },
		resetListingFilter = function () {
		    $("input[name='filterKeyword']").val('');
		    $("input[name='Id']").val('');
		    $("input[name='Name']").val('');
		    $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);

		    var counties = wdot.Listing.listingViewModel.Items.counties();

		    for (var i = counties.length - 1; i >= 0; i--) {
		        wdot.Listing.listingViewModel.Remove(counties[i], 'counties', $("#AllItemsList[data-uniquelistingkey='counties']").find("input[id*='viewAllItem_Name_']"));
		    }
		};

	    return {
	        generateListingFilterJSON: generateListingFilterJSON,
	        resetListingFilter: resetListingFilter
	    };
	} ();

    return {
        loadCityAutocomplete: loadCityAutocomplete,
        loadChildCitiesAutocomplete: loadChildCitiesAutocomplete,
        loadLocationCityAutocomplete: loadLocationCityAutocomplete,
        lastSelectedCity: lastSelectedCity,
        ListingPageViewModel: listingPageViewModel,
        loadCityAutocompleteForPublicSite: loadCityAutocompleteForPublicSite,
        loadCityAutocompleteForCitiesAndRegions: loadCityAutocompleteForCitiesAndRegions,
        loadCityAutocompleteForPublicSiteMenu: loadCityAutocompleteForPublicSiteMenu
    };
} ();