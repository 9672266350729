var wdot = wdot || {};
wdot.StateAndCountryHelper = function () {
    var changeStateToTextbox = function () {
        if ($("#Country").val() != 'US') {
            $("#State[data-is-for-us='true']").addClass("hidden");
            $("#State[data-is-for-us='true']").attr('disabled', 'disabled');
            $("#State[data-is-for-us='false']").removeAttr('disabled');
            $("#State[data-is-for-us='false']").removeClass("hidden");
            $("#State[data-is-for-us='false']").siblings(".customStyleSelectBox").addClass("hidden");
        } else {
            $("#State[data-is-for-us='true']").removeClass("hidden");
            $("#State[data-is-for-us='true']").removeAttr('disabled');
            $("#State[data-is-for-us='false']").attr('disabled', 'disabled');
            $("#State[data-is-for-us='false']").addClass("hidden");
            $("#State[data-is-for-us='false']").siblings(".customStyleSelectBox").removeClass("hidden");
        }
    };

    return {
        ChangeStateToTextbox: changeStateToTextbox
    };
} ();