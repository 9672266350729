var wdot = wdot || {};
wdot.LatLongSelector = function () {
    var latLongItem = function () {
        var pinText = ko.observable(),
            name = ko.observable(),
            inputNameForLat = ko.observable(),
            inputNameForLong = ko.observable(),
            inputNameForIsOverridden = ko.observable(),
            inputNameForKey = ko.observable(),
            latitude = ko.observable(),
            longitude = ko.observable(),
            originalLat = ko.observable(),
            originalLong = ko.observable(),
            id = ko.observable(),
            isOverridden = ko.observable(),
            key = '',
            index = 0;
        return {
            pinText: pinText,
            name: name,
            inputNameForLat: inputNameForLat,
            inputNameForLong: inputNameForLong,
            inputNameForIsOverridden: inputNameForIsOverridden,
            inputNameForKey: inputNameForKey,
            isOverridden: isOverridden,
            id: id,
            latitude: latitude,
            longitude: longitude,
            originalLat: originalLat,
            originalLong: originalLong,
            key: key,
            index: index
        };
    } (),
    latLongViewModel = function () {
        var self = this,
            currentIndex = 0,
            listingKeysUsedForLatLongSelector = new Array(),
            latLongItemsByKey = {},
            scrollToMapMethod = undefined,
            latLongItems = ko.observableArray(),
            orderByIndex = function (item1, item2) {
                return item1.index - item2.indexl;
            },
            pinOnClickWhenMapIsEmpty = true,
            bingMap = null,
            mapClick = function (event) {
                if (latLongItemsByKey['geoCoded'] != undefined && (pinOnClickWhenMapIsEmpty || (!pinOnClickWhenMapIsEmpty && latLongItemsByKey['geoCoded']().length > 0))) {
                    var point1 = new Microsoft.Maps.Point(event.getX(), event.getY());
                    var location = event.target.tryPixelToLocation(point1);
                    var longitude = location.longitude;
                    var latitude = location.latitude;
                    var length = latLongItemsByKey['geoCoded']().length;
                    latLongItemsByKey['geoCoded'].removeAll();
                    if (length > 0) {
                        $(latLongItems()).each(function (i) {
                            if (this.key == 'geoCoded') {
                                bingMap.removePinByText(this.pinText());
                                var latLongItem = latLongItems.splice(i, 1)[0];
                                currentIndex--;
                                var item = { name: latLongItem.name(), id: latLongItem.id(), data: { LatitudeOverride: latitude, LongitudeOverride: longitude, Latitude: latLongItem.originalLat(), Longitude: latLongItem.originalLong(), IsOverridden: false, DisplayOrder: 0} };
                                addItem(item, 'geoCoded');
                            }
                        });
                    } else {
                        var item = { name: "Custom Location", id: 1, data: { LatitudeOverride: latitude, LongitudeOverride: longitude, Latitude: latitude, Longitude: longitude, IsOverridden: false, DisplayOrder: 0} };
                        addItem(item, 'geoCoded');
                    }
                    setBestMapView();
                }
            },
            init = function (map, includeListings, includeGeocode, includeSubscription) {
                if (includeListings == undefined)
                    includeListings = true;
                if (includeGeocode == undefined)
                    includeGeocode = true;

                if (includeSubscription == undefined)
                    includeSubscription = true;
                if (map) {
                    bingMap = map;
                }

                if (includeListings) {
                    $(listingKeysUsedForLatLongSelector).each(function () {
                        var self1 = this;
                        if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey == undefined)
                            wdot.LatLongSelector.latLongViewModel.latLongItemsByKey = {};
                        wdot.LatLongSelector.latLongViewModel.latLongItemsByKey[self1.toString()] = ko.observableArray();
                        if (includeSubscription) {
                            try {
                                wdot.Listing.listingViewModel.Items[self1.toString()].subscribe(function (newValue) {
                                    updateLatLongListing(newValue, self1.toString());
                                });
                            }
                            catch(e) {
                            }
                        }
                    });
                }

                if (includeGeocode) {
                    if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey == undefined)
                        wdot.LatLongSelector.latLongViewModel.latLongItemsByKey = {};
                    if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey['geoCoded'] == undefined)
                        wdot.LatLongSelector.latLongViewModel.latLongItemsByKey['geoCoded'] = ko.observableArray();

                    //                    wdot.Event.eventLocationListingViewModel.geoCodedLatLong.subscribe(function (value) {
                    //                        if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey == undefined)
                    //                            wdot.LatLongSelector.latLongViewModel.latLongItemsByKey = {};
                    //                        if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey['event-locations'] == undefined)
                    //                            wdot.LatLongSelector.latLongViewModel.latLongItemsByKey['event-locations'] = ko.observableArray();
                    //                        if (value.Latitude != undefined && value.Longitude != undefined) {
                    //                            var address = wdot.Event.eventLocationListingViewModel.addressAsString();
                    //                            if ($.trim(address) == ', WI') {
                    //                                address = 'Custom Location';
                    //                            }
                    //                            updateLatLongListingForGeoCodedItem(address, value, 'event-locations');
                    //                            wdot.Event.eventLocationListingViewModel.geoCodedLat(undefined);
                    //                            wdot.Event.eventLocationListingViewModel.geoCodedLong(undefined);
                    //                        }
                    //                    });

                    wdot.Place.createEditViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            var address = wdot.Place.createEditViewModel.addressAsString();
                            removeLatLongItemsByKey('geoCoded');
                            if ($.trim(address) == ', WI') {
                                address = 'Custom Location';
                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Place.createEditViewModel.geoCodedLat(undefined);
                            wdot.Place.createEditViewModel.geoCodedLong(undefined);
                        }
                    });
                    wdot.Reports.crossCountrySkiingDetailReportViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            removeLatLongItemsByKey('geoCoded');
                            var address = 'Report Location';
                            //                            wdot.Reports.crossCountrySkiingDetailReportViewModel.addressAsString();
                            //                            if (address.trim() == ', WI') {
                            //                                address = 'Custom Location';
                            //                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Reports.crossCountrySkiingDetailReportViewModel.geoCodedLat(undefined);
                            wdot.Reports.crossCountrySkiingDetailReportViewModel.geoCodedLong(undefined);
                        }
                    });
                    wdot.Reports.downhillSkiingDetailReportViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            removeLatLongItemsByKey('geoCoded');
                            var address = 'Report Location';
                            //                            wdot.Reports.downhillSkiingDetailReportViewModel.addressAsString();
                            //                            if (address.trim() == ', WI') {
                            //                                address = 'Custom Location';
                            //                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Reports.downhillSkiingDetailReportViewModel.geoCodedLat(undefined);
                            wdot.Reports.downhillSkiingDetailReportViewModel.geoCodedLong(undefined);
                        }
                    });
                    wdot.Reports.fallColorDetailReportViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            removeLatLongItemsByKey('geoCoded');
                            var address = 'Report Location';
                            //                             wdot.Reports.fallColorDetailReportViewModel.addressAsString();
                            //                            if (address.trim() == ', WI') {
                            //                                address = 'Custom Location';
                            //                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Reports.downhillSkiingDetailReportViewModel.geoCodedLat(undefined);
                            wdot.Reports.downhillSkiingDetailReportViewModel.geoCodedLong(undefined);
                        }
                    });
                    wdot.Reports.geopointDetailReportViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            removeLatLongItemsByKey('geoCoded');
                            var address = 'Report Location';
                            //                             wdot.Reports.fallColorDetailReportViewModel.addressAsString();
                            //                            if (address.trim() == ', WI') {
                            //                                address = 'Custom Location';
                            //                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Reports.geopointDetailReportViewModel.geoCodedLat(undefined);
                            wdot.Reports.geopointDetailReportViewModel.geoCodedLong(undefined);
                        }
                    });
                    wdot.Reports.snowmobileDetailReportViewModel.geoCodedLatLong.subscribe(function (value) {
                        if (value.Latitude != undefined && value.Longitude != undefined) {
                            removeLatLongItemsByKey('geoCoded');
                            var address = 'Report Location';
                            //                            wdot.Reports.snowmobileDetailReportViewModel.addressAsString();
                            //                            if (address.trim() == ', WI') {
                            //                                address = 'Custom Location';
                            //                            }
                            updateLatLongListingForGeoCodedItem(address, value);
                            wdot.Reports.snowmobileDetailReportViewModel.geoCodedLat(undefined);
                            wdot.Reports.snowmobileDetailReportViewModel.geoCodedLong(undefined);
                        }
                    });
                }
            },
            resetLatLongItems = function () {
                latLongItems.removeAll();
                $(listingKeysUsedForLatLongSelector).each(function () {
                    latLongItemsByKey[this].removeAll();
                });
                currentIndex = 0;
                wdot.BingMaps.removeAllPins();
            },
            removeLatLongItemsByKey = function (key) {
                for (i = latLongItems().length - 1; i >= 0; i--) {
                    var item = latLongItems()[i];
                    if (item.key == key) {

                        if (bingMap) {
                            bingMap.removePinByText(item.pinText());
                        }
                        
                        latLongItems.splice(i, 1);
                        currentIndex--;
                    }
                }
                latLongItemsByKey[key] = ko.observableArray();
                for (var i = 0; latLongItems().length > i; i++) {
                    var item = latLongItems()[i];
                    item.index = i;
                    item.inputNameForLat('LatLongSelectorModel.LatLongItems[' + i + '].Lat');
                    item.inputNameForLong('LatLongSelectorModel.LatLongItems[' + i + '].Long');
                    item.inputNameForIsOverridden('LatLongSelectorModel.LatLongItems[' + i + '].IsOverridden');
                    item.inputNameForKey('LatLongSelectorModel.LatLongItems[' + i + '].Key');
                    item.inputNameForId('LatLongSelectorModel.LatLongItems[' + i + '].Id');
                    item.pinText((i + 1).toString());
                }
                setBestMapView();
            },
            updateLatLongListingForGeoCodedItem = function (address, latLong, key) {
                if (key == undefined)
                    key = 'geoCode';
                if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey == undefined)
                    wdot.LatLongSelector.latLongViewModel.latLongItemsByKey = {};


                var newItem = new wdot.LatLongSelector.LatLongItem.constructor();
                newItem.pinText = ko.observable((currentIndex + 1).toString());
                newItem.name = ko.observable(address);
                newItem.inputNameForLat = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Lat');
                newItem.inputNameForLong = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Long');
                newItem.inputNameForLatOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].LatOriginal');
                newItem.inputNameForLongOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].LongOriginal');
                newItem.inputNameForIsOverridden = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].IsOverridden');
                newItem.inputNameForDisplayOrder = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].DisplayOrder');
                newItem.inputNameForKey = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Key');
                newItem.inputNameForId = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Id');
                newItem.id = ko.observable(currentIndex);
                newItem.key = 'geoCoded';
                newItem.displayOrder = ko.observable(currentIndex);
                newItem.latitude = ko.observable(latLong.Latitude);
                newItem.longitude = ko.observable(latLong.Longitude);
                newItem.originalLat = ko.observable(latLong.Latitude);
                newItem.originalLong = ko.observable(latLong.Longitude);
                newItem.isOverridden = ko.observable('true');
                newItem.index = currentIndex++;
                latLongItemsByKey['geoCoded'].push(newItem);
                latLongItems.push(newItem);

                if (bingMap) {
                    bingMap.addPin(newItem.latitude(), newItem.longitude(), newItem.pinText(), wdot.LatLongSelector.latLongViewModel.updateListingOnPinDrop);
                }

                setBestMapView();
                if (wdot.LatLongSelector.latLongViewModel.scrollToMapMethod != undefined)
                    wdot.LatLongSelector.latLongViewModel.scrollToMapMethod();
            },
            updateLatLongListing = function (list, key) {
                if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey == undefined)
                    wdot.LatLongSelector.latLongViewModel.latLongItemsByKey = {};

                if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey[key] == undefined)
                    wdot.LatLongSelector.latLongViewModel.latLongItemsByKey[key] = ko.observableArray();

                if (wdot.LatLongSelector.latLongViewModel.latLongItemsByKey[key]().length > 0) {
                    if (list.length == 0) {
                        for (var x = 0; x < latLongItemsByKey[key]().length; x++) {
                            var item = latLongItemsByKey[key]()[x];
                            removeItem(item, key);
                        }
                    } else {
                        for (var x = 0; x < latLongItemsByKey[key]().length; x++) {
                            var item = latLongItemsByKey[key]()[x];
                            var isItemRemoved = true;
                            for (var i = 0; i < list.length; i++) {
                                if (!$.isFunction(item.id) ? list[i].id == item.id : list[i].id == item.id()) {
                                    isItemRemoved = false;
                                }
                                if (i == list.length - 1 && isItemRemoved) {
                                    removeItem(item, key);
                                }
                            }
                        }
                        $(list).each(function () {
                            var item = this;
                            var isItemAdded = true,
                            length = latLongItemsByKey[key]().length;
                            for (var y = 0; y < length; y++) {
                                if (!$.isFunction(item.id) ? latLongItemsByKey[key]()[y].id() == item.id : latLongItemsByKey[key]()[y].id() == item.id()) {
                                    isItemAdded = false;
                                }

                                if (y == latLongItemsByKey[key]().length - 1 && isItemAdded) {
                                    addItem(item, key);
                                }
                            }
                        });
                    }
                } else {
                    $(list).each(function () {
                        addItem(this, key);
                    });
                }
                setBestMapView();
            },
            revert = function (data) {
                data.latitude(data.originalLat());
                data.longitude(data.originalLong());
                wdot.BingMaps.movePinByText(data.pinText(), data.latitude(), data.longitude());
                data.isOverridden("false");
                setBestMapView();
            },
            addItem = function (item, key) {
                var newItem = new wdot.LatLongSelector.LatLongItem.constructor();
                newItem.pinText = ko.observable((currentIndex + 1).toString());
                newItem.name = ko.observable(item.name);
                newItem.inputNameForLat = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Lat');
                newItem.inputNameForLong = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Long');
                newItem.inputNameForLatOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].LatOriginal');
                newItem.inputNameForLongOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].LongOriginal');
                newItem.inputNameForIsOverridden = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].IsOverridden');
                newItem.inputNameForDisplayOrder = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].DisplayOrder');
                newItem.inputNameForKey = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Key');
                newItem.inputNameForId = ko.observable('LatLongSelectorModel.LatLongItems[' + currentIndex + '].Id');
                newItem.id = ko.observable(item.id);
                newItem.displayOrder = item.index != undefined && item.index != null ? ko.observable(item.index) : ko.observable(0);
                newItem.key = key;

                var data;
                if (typeof item.data == "string")
                    data = ko.utils.parseJson(item.data);
                else
                    data = item.data;

                if (data) {
                    newItem.latitude = ko.observable(data.LatitudeOverride != 0 ? data.LatitudeOverride : data.Latitude);
                    newItem.longitude = ko.observable(data.LongitudeOverride != 0 ? data.LongitudeOverride : data.Longitude);
                    newItem.originalLat = ko.observable(data.Latitude);
                    newItem.originalLong = ko.observable(data.Longitude);
                    newItem.isOverridden = ko.observable(data.IsOverridden.toString());
                }
                newItem.index = currentIndex++;
                latLongItemsByKey[key].push(newItem);
                latLongItems.push(newItem);

                if (bingMap) {
                    bingMap.addPin(newItem.latitude(), newItem.longitude(), newItem.pinText(), wdot.LatLongSelector.latLongViewModel.updateListingOnPinDrop);
                }

                forceUpdateOfLatLongs(key);

                setBestMapView();
                if (wdot.LatLongSelector.latLongViewModel.scrollToMapMethod != undefined)
                    wdot.LatLongSelector.latLongViewModel.scrollToMapMethod();
            },
            forceUpdateOfLatLongs = function (key) {
                $(latLongItemsByKey[key]()).each(function () {
                    this.latitude.valueHasMutated();
                    this.longitude.valueHasMutated();
                });

                $(latLongItems()).each(function () {
                    this.latitude.valueHasMutated();
                    this.longitude.valueHasMutated();
                });
            },
        removeItem = function (item, key) {

           var indexRemoved = bingMap.removePinByText(item.pinText());

            latLongItems.remove(item);
            latLongItemsByKey[key].remove(item);

            for (var i = indexRemoved; latLongItems().length > i; i++) {
                var item = latLongItems()[i];
                item.index = item.index > 0 ? item.index - 1 : 0;
                item.inputNameForLat('LatLongSelectorModel.LatLongItems[' + item.index + '].Lat');
                item.inputNameForLong('LatLongSelectorModel.LatLongItems[' + item.index + '].Long');
                item.inputNameForLatOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + item.index + '].LatOriginal');
                item.inputNameForLongOriginal = ko.observable('LatLongSelectorModel.LatLongItems[' + item.index + '].LongOriginal');
                item.inputNameForDisplayOrder = ko.observable('LatLongSelectorModel.LatLongItems[' + item.index + '].DisplayOrder');
                item.inputNameForIsOverridden('LatLongSelectorModel.LatLongItems[' + item.index + '].IsOverridden');
                item.inputNameForKey('LatLongSelectorModel.LatLongItems[' + item.index + '].Key');
                item.inputNameForId('LatLongSelectorModel.LatLongItems[' + item.index + '].Id');
                item.pinText((item.index + 1).toString());
            }

            if (currentIndex > 0) {
                currentIndex--;
            }

            setBestMapView();
        },
            updateListingOnPinDrop = function (e) {
                var key = '', id = 0;
                for (var i = 0; latLongItems().length > i; i++) {
                    if (latLongItems()[i].pinText() == e.primitive.entity.iconText) {
                        latLongItems()[i].latitude(e.location.latitude);
                        latLongItems()[i].longitude(e.location.longitude);
                        key = latLongItems()[i].key;
                        id = latLongItems()[i].id();
                        latLongItems()[i].isOverridden("true");
                    }
                    if (wdot.LatLongSelector.latLongViewModel.isCityCreateOrEdit()) {
                        $('[name=Longitude]').val(e.location.longitude);
                        $('[name=Latitude]').val(e.location.latitude);
                    }
                }
                for (var i = 0; latLongItemsByKey[key]().length > i; i++) {
                    if (latLongItemsByKey[key]()[i].id() == id) {
                        latLongItemsByKey[key]()[i].latitude(e.location.latitude);
                        latLongItemsByKey[key]()[i].longitude(e.location.longitude);
                    }
                }

                setBestMapView();
            },
            isCityCreateOrEdit = ko.observable(false),
            setBestMapView = function () {
                if (bingMap) {
                    bingMap.bestMapView($("#latLongMap .MicrosoftMap").width(), $("#latLongMap .MicrosoftMap").height(), 70);
                }
            };
        return {
            latLongItems: latLongItems,
            latLongItemsByKey: latLongItemsByKey,
            resetLatLongItems: resetLatLongItems,
            removeLatLongItemsByKey: removeLatLongItemsByKey,
            revert: revert,
            addItem: addItem,
            listingKeysUsedForLatLongSelector: listingKeysUsedForLatLongSelector,
            init: init,
            updateListingOnPinDrop: updateListingOnPinDrop,
            updateLatLongListing: updateLatLongListing,
            mapClick: mapClick,
            removeItem: removeItem,
            scrollToMapMethod: scrollToMapMethod,
            isCityCreateOrEdit: isCityCreateOrEdit,
            pinOnClickWhenMapIsEmpty: function (value) {
                if (value != undefined) {
                    pinOnClickWhenMapIsEmpty = value;
                }
                return pinOnClickWhenMapIsEmpty;
            },
            forceUpdateOfLatLongs: forceUpdateOfLatLongs
        };
    } ();
    return {
        latLongViewModel: latLongViewModel,
        LatLongItem: latLongItem
    };
} ();