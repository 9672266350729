$(function() {
    //for preparing card blocks to be a slider

    // $('.js-setup-card-slider').find('.js-cards-wrapper').addClass('js-feature-slider--dots');
    // $('.js-setup-card-slider').find('.js-card-container').wrap('<div class="js-feature-slide slide"></div>');



	//console.log('sliders.js loaded');
	

	if($('.js-feature-slider--stories').find('.js-feature-slide').length > 0) {
		//console.log('FOUND js-feature-slider--stories');
		
		//i don't think we need this anymore after the latest layout updates
		var numSlides = $('.js-feature-slider--stories .js-feature-slide').length,
			initSlide = numSlides >= 3 ? 2 : 0;
		// console.log('js-feature-slider--stories numSlides:', numSlides);
		// console.log('js-feature-slider--stories initSlide:', initSlide);

		$('.js-feature-slider--stories').slick({
			centerMode: true,
			// centerPadding: '180px',
			initialSlide: initSlide,
			slide: '.js-feature-slide',
			infinite: false,
			slidesToShow: 1.1,
			slidesToScroll: 1,
			appendArrows: '.js-slick-nav-stories',
			prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-quaternary" data-event-action="Episode Slider" data-event-label="Left arrow click"><i class="fas fa-long-arrow-alt-left text-light text-32"></i></a>',
			nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-quaternary" data-event-action="Episode Slider" data-event-label="Right arrow click"><i class="fas fa-long-arrow-alt-right text-light text-32"></i></a>',
			variableWidth: false,
			responsive: [
				{
					breakpoint: 9000,
					settings: {
						slidesToShow: 4.25,//6.5,
						slidesToScroll: 4,
					}
				},
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 4.25,//5.5,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3.25,//4.5,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 3.25,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2.25,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1.1,
						slidesToScroll: 1,
					}
				}
			]
		});
	}




	// LEGACY METHODS BELOW



     //look for slider slides, if more than one, initialize slider
    $('.js-feature-slider').each(function() {
			if ($(this).find('.js-feature-slide').length > 1) {
				$(this).slick({
					slide: '.js-feature-slide',
					arrows: false,
					dots: true,
					dotsClass: 'carousel-squares',
					mobileFirst: true,
					responsive: [
					{
						breakpoint: 760,
						settings: {
						dotsClass: 'carousel-tabs',
						}
					}
						]
					});
				}

			});

    //sliders with no pagination
         $('.js-feature-slider--simple').each(function() {
					if ($(this).find('.js-feature-slide').length > 1) {
            $(this).slick({
                slide: '.js-feature-slide',
                arrows: false,
                dots: false,
                speed: 300,
                autoplay: true
              });
					}
					
				});

    //sliders with dot pagination
    $('.js-feature-slider--dots').each(function() {
			if ($(this).find('.js-feature-slide').length > 1) {
        $(this).slick({
            slide: '.js-feature-slide',
            arrows: false,
            dots: true,
            dotsClass: 'carousel-dots'
        });
		}
		
	});
	
	//sliders showing slide number out of total
	  $('.js-feature-slider--count').each(function() {
			if ($(this).find('.js-feature-slide').length > 1) {
				var totalSlides = $(this).find('.js-feature-slide').length;
				$('.js-feature-slider--count').on('init', function(slick){
						$(this).after('<span class="js-feature-slider-count-container"></span>');
						var container = $(this).siblings('.js-feature-slider-count-container');
						container.text(1 + ' / ' + totalSlides);
					}).slick({
					slide: '.js-feature-slide',
					arrows: false,
					dots: false
					}).on('swipe', function() {
						var container = $(this).siblings('.js-feature-slider-count-container'),
							currentSlide = $(this).slick('slickCurrentSlide');
						container.text((currentSlide + 1) + ' / ' + totalSlides);
					});
				}
			});
	  
	  //sliders that show three items at a time with arrows
	  $('.js-feature-slider--trio').each(function() {
			if ( $(this).children('.js-feature-slide').length > 3) {
				$('.js-feature-slider--trio').slick({
					slide: '.js-feature-slide',
					arrows: true,
					dots: false,
					slidesToShow: 2,
					prevArrow: '<button class="Media-Scroller_Previous-Button" type="button"><span class="visuallyhidden">Previous Slides</span></button>',
					nextArrow: '<button class="Media-Scroller_Next-Button" type="button"><span class="visuallyhidden">Next Slides</span></button>',
					mobileFirst: true,
					responsive: [
					{
						breakpoint: 1382,
						settings: {
						slidesToShow: 3
						}
					}
					]
				});
		}

	});
	 
	$('.js-media-slider').each(function() {
		if ($(this).find('.js-media-slide').length > 1) {
			var totalSlides = $(this).find('.js-media-slide').length;
			$(this).on('init', function(slick){
					var $this = $(this);
					$this.append('<span class="js-media-slider-container"><button class="js-media-slider-prev" type="button" title="Previous"><</button><span class="js-media-slider-count-container"></span><button class="js-media-slider-next" title="Next" type="button">></button></span>');
					var container = $this.find('.js-media-slider-count-container');
					container.text(1 + ' / ' + totalSlides);
					$this.find('.js-media-slider-prev').click(function() {
						$this.slick('slickPrev');
					});
					$this.find('.js-media-slider-next').click(function() {
						$this.slick('slickNext');
					});
					
			  }).slick({
				slide: '.js-media-slide',
				arrows: false,
				dots: false
			  }).on('afterChange', function() {
					var container = $(this).find('.js-media-slider-count-container'),
					currentSlide = $(this).slick('slickCurrentSlide');
					//console.log(currentSlide);
					//current slide + 1 because of zero-based index
					container.text((currentSlide + 1) + ' / ' + totalSlides);
			  });
		  }
	  });

    $('.mediaSliderHideImage').removeClass('mediaSliderHideImage');

});