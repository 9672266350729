wdot.BreakPoint = (function () {
    var shareContainer,
        loadShareIconBreakPointsForDesktop = function() {
            $.breakpoint((function () {
                var shareIconsLI;
                return {
                    condition: function () {
                        return window.matchMedia('only screen and (max-width:1023px)').matches;
                    },
                    first_enter: function () {
                        shareContainer = $('.social-sharing__container').detach();
                        if (shareContainer === null) {
                            shareContainer = $('#gigya-like').detach();
                        }
                        shareIconsLI = $('li.shareIcons').detach();
                    },
                    enter: function () {
                        shareIconsLI.detach();
                        shareContainer.appendTo('div.breadcrumbs');
                    },
                    exit: function () {
                        if ($('li.callout-subscribe')[0]) {
                            $('.callOuts > ul > li.callout-subscribe').after(shareIconsLI);
                            shareContainer.appendTo(shareIconsLI);

                        }
                        else {
                            shareIconsLI.prependTo('.callOuts > ul');
                            shareContainer.appendTo(shareIconsLI);
                        }
                    }
                }
            }()));
        },
        loadShareIconBreakPointsForTablet = function () {
            $.breakpoint((function () {
                return {
                    condition: function () {
                        return window.matchMedia('only screen and (min-width:1023px)').matches;
                    },
                    first_enter: function () {
                        shareContainer = $('.social-sharing__container').detach();
                        if (shareContainer === null) {
                            shareContainer = $('#gigya-like').detach();
                        }
                    },
                    enter: function () {
                        $('.callOuts > ul').prepend("<li class=\"shareIcons\"><h2>Share With Friends</h2></li>");
                        shareContainer.appendTo('li.shareIcons');
                    },
                    exit: function () {
                        $('li.shareIcons').detach();
                        $('.featureContent h1').after(shareContainer);
                    }
                }
            }()));
        };
    return {
        LoadShareIconBreakPoints: function () {
            var deviceType = wdot.Utils.DeviceType,
                browserType = $("body").data("browsertype");
            switch (browserType) {
                case deviceType.Mobile:
                    break;
                case deviceType.Tablet:
                    loadShareIconBreakPointsForTablet();
                    break;
                case deviceType.Desktop:
                default:
                    loadShareIconBreakPointsForDesktop();
                    break;
            }
        }
    };
})();