(function () {
    if (!$("body").hasClass("js-use-gtm")) {
        try {
            $.address.tracker(false);
        }
        catch (err) { }


        var googleAnalytics = function _gaq() {
            this.push = function (args) {



                var eventName = '';
                var category = '';
                var action = '';
                var optLabel = '';
                var optValue = '';

                if (args[0] === undefined || args[0].indexOf("Pageview") > -1) {
                    eventName = 'pageview';
                } else {
                    eventName = 'event';
                }

                if (args[1] !== undefined) {
                    category = args[1];
                }
                if (args[2] !== undefined) {
                    action = args[2];
                }
                if (args[3] !== undefined) {
                    optLabel = args[3];
                }
                //if (args[4] !== undefined) {
                //    optValue = args[4];
                //}

                if (args[0] === undefined || args[0].indexOf("Pageview") > -1) {
                    ga('send', eventName, category);
                } else {
                    ga('send', eventName, category, action, optLabel);
                }


            }
        };

        //Tracking for the Inspiration/Trip Ideas pages
        //These classes are set in the CMS

        //2nd featured section CTA button
        $('.insp-feat2__info__btn').click(function () {
            ga('send', 'event', 'Trip Ideas', 'Section 2 CTA', window.location.pathname);
        });

        //Discover more stories button
        $('.insp-must__intro__btn').click(function () {
            ga('send', 'event', 'Trip Ideas', 'Discover More CTA', window.location.pathname);
        });

        window._gaq = new googleAnalytics();
    }
})();
