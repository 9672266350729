var wdot = wdot || {};
wdot.Event = function () {
	var lastUniqueId = 0,
	eventLocationItem = function () {
		var self = this;
		self.uniqueIdForJS = 0;
		self.id = ko.observable(),
		self.name = ko.observable(),
		self.index = ko.observable(),
		self.data = {},
		self.CityId = ko.observable(),
		self.isPlace = ko.observable(),
		self.inputGenerator = function (field) {
			return 'PlaceListingModel.EventLocations[' + self.index() + '].' + field;
		},
		self.inputNameId = ko.computed(function () {
			return self.inputGenerator('Id');
		}),
		self.inputNameDisplayOrder = ko.computed(function () {
			return self.inputGenerator('DisplayOrder');
		}),
		self.inputNameIsPlace = ko.computed(function () {
			return self.inputGenerator('IsPlace');
		}),
		self.inputNameName = ko.computed(function () {
			return self.inputGenerator('Name');
		}),
		self.inputNameStreet = ko.computed(function () {
			return self.inputGenerator('Street');
		}),
		self.inputNameCity = ko.computed(function () {
			return self.inputGenerator('City');
		}),
		self.inputNameCityId = ko.computed(function () {
			return self.inputGenerator('CityId');
		}),
		self.inputNameZipCode = ko.computed(function () {
			return self.inputGenerator('ZipCode');
		}),
		self.isFirst = ko.computed(function () {
			return self.index() == 0;
		}),
		self.isLast = ko.computed(function () {
			var places = wdot.Event.eventLocationListingViewModel.places();
			return self.index() == (places.length - 1);
		}),
		self.moveDown = function () {
			if (!self.isLast()) {
				var places = wdot.Event.eventLocationListingViewModel.places;
				var item = wdot.Event.eventLocationListingViewModel.getItemByUniqueId(self.uniqueIdForJS);
			    var firstItem = places()[0];
				places.remove(item);
				places.splice(self.index() + 1, 0, item);
				wdot.Event.eventLocationListingViewModel.Render();

                if (places.indexOf(firstItem) !== 0) {
                    Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(places()[0].data.Latitude, places()[0].data.Longitude);
                }
			}
		},
		self.moveUp = function () {
			if (!self.isFirst()) {
				var places = wdot.Event.eventLocationListingViewModel.places;
				var item = wdot.Event.eventLocationListingViewModel.getItemByUniqueId(self.uniqueIdForJS);
				var firstItem = places()[0];
				places.remove(item);
				places.splice(self.index() - 1, 0, item);
				wdot.Event.eventLocationListingViewModel.Render();

                if (places.indexOf(firstItem) !== 0) {
                    Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(places()[0].data.Latitude, places()[0].data.Longitude);
                }
			}
		};
		lastUniqueId++;
		self.uniqueIdForJS = lastUniqueId;
		return {
			id: self.id,
			name: self.name,
			data: self.data,
			inputNameId: self.inputNameId,
			inputNameName: self.inputNameName,
			inputNameStreet: self.inputNameStreet,
			inputNameCity: self.inputNameCity,
			inputNameCityId: self.inputNameCityId,
			inputNameZipCode: self.inputNameZipCode,
			inputNameDisplayOrder: self.inputNameDisplayOrder,
			inputNameIsPlace: self.inputNameIsPlace,
			index: self.index,
			isPlace: self.isPlace,
			uniqueIdForJS: self.uniqueIdForJS,
			isFirst: self.isFirst,
			isLast: self.isLast,
			moveUp: self.moveUp,
			moveDown: self.moveDown,
			CityId: self.CityId
		};
	},
	eventLocationListingViewModel = function () {
        var name = ko.observable(),
            bingMap,
		street = ko.observable(),
		city = ko.observable(),
		state = ko.observable('WI'),
		zip = ko.observable(),
		address = ko.computed(function () {
			return { street: street(), city: city(), state: state(), zip: zip() };
		}),
		places = ko.observableArray(),
		render = function () {
			var j = 0;
			ko.utils.arrayForEach(places(), function (item) {
				if (typeof item.data == "string")
					item.data = ko.utils.parseJson(item.data);
				item.index(j);
				$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].ZipCode']").val(item.data.LocationAddress.ZipCode);
				$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].Street']").val(item.data.LocationAddress.Street1);
				$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].City']").val(item.data.LocationAddress.City);
				$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].DisplayOrder']").val(j);

				if (!item.isPlace()) {
					$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].IsPlace']").val('false');
				}
				else {
					$("[name='UniqueIdForJS'][value='" + item.uniqueIdForJS + "']").parent().find("[name='PlaceListingModel.EventLocations[" + item.index() + "].IsPlace']").val('true');
				}

				j++;
			});
			wdot.LatLongSelector.latLongViewModel.removeLatLongItemsByKey('eventlocations');
			var t = ko.toJS(places);
			wdot.LatLongSelector.latLongViewModel.updateLatLongListing(t, 'eventlocations');
			setTimeout(function () { wdot.LatLongSelector.latLongViewModel.forceUpdateOfLatLongs('eventlocations'); }, 100);
			street('');
			city('');
			zip('');
			name('');
			geoCodedLat(undefined);
			geoCodedLong(undefined);
			$("#saveCustomLocation").removeAttr("data-uniqueIdForJS");
			$($("form")[2]).validate().element("#listItem");
		},
		loadEventLocationList = function (list) {
			var i = 0;
			ko.utils.arrayForEach(list, function (item) {
				if (item.isPlace) {
					if (typeof item.data == "string")
						item.data = ko.utils.parseJson(item.data);
					add(item.name, item.data.LocationAddress.Id, $("#eventPlacesListing").find("input[id*='viewAllItem_Id_']"), item.data, i == list.length - 1, false);
				} else {
					if (typeof item.data == "string")
						item.data = ko.utils.parseJson(item.data);

					addCustomLocation(item.name, item.id, item.data, false);
				}

				i++;
			});
			render();
		},
		loadViewAll = function (data, event) {
			if ($(event.currentTarget).next("#AllItemsList").length == 0) {
				Wdot.Ajax.prototype.Get("/admin/places/viewallplacesforeventlocation", { title: $(event.currentTarget).parent().attr("title") }, function (data, status, xhr) {
					$(event.currentTarget).parent().children("#AllItemsList").remove();
					$(event.currentTarget).parent().append(data);
					$(event.currentTarget).next().children("#AllItemsList").find("button").click(addItemsFromViewAll);
					$(event.currentTarget).next().children("#AllItemsList").find("input[id*='viewAllItem_Id_']").each(function () {
						if (isItemAlreadyAdded($(this).val())) {
							$(this).siblings("input").attr('checked', 'checked');
						}
					});
					$(event.currentTarget).parent().children("#list-view-all").dialog({ resizable: false, modal: true, height: 500, close: function () {
						$(this).dialog('destroy').remove();
					}
					});
				});
			}
		},
		addItemsFromViewAll = function (event) {
			var allItems = $(event.currentTarget).parents("#list-view-all").find("ul").find("input[type='checkbox']"),
			checkedItems = allItems.filter(":checked"),
			uncheckedItems = allItems.not(":checked");


			uncheckedItems.each(function (i) {
				var id = $(this).next("input").val();
				if (isItemAlreadyAdded(id)) {
					removeItemById($(this).siblings("input").val());
				}
			});

			checkedItems.each(function (i) {
				var id = $(this).next("input").val();
				if (!isItemAlreadyAdded(id)) {
					add($(this).val(), id, $(event.currentTarget).parent().find("input[id*='viewAllItem_Id_']"), $(this).attr("data-listItemData"), i == (checkedItems.length - 1));
				}
			});
			$(event.currentTarget).parent().parent().dialog('close');
		},
		addItem = function (data, event) {
			var listItem = $(event.currentTarget).parent().find("#listItem");
			add(listItem.val(), listItem.attr("data-itemid"), $(event.currentTarget).parent().siblings("#list-view-all").find("input[id*='viewAllItem_Id_']"), listItem.attr("data-listItemData"), true);
			$(event.currentTarget).parent().find("#listItem").val('');
		},
		add = function (value, itemid, viewAllItemsSelector, data, rerender) {
			if (value != '' && itemid != 0) {
			    var id = itemid;
				if (!isItemAlreadyAdded(id)) {
					var item = new wdot.Event.EventLocationItem();
					item.data = typeof data == "object" ? data : $.parseJSON(data);
					item.name(value);
					item.id(item.data.LocationAddress.Id);
					item.isPlace(true);

					if (item.data.City != undefined && typeof item.data.City == "object" && item.data.City.Id)
						item.CityId(item.data.City.Id);
					else if (item.data.CityId != undefined)
						item.CityId(item.data.CityId);
					else
						item.CityId(0);

					places.push(item);
					viewAllItemsSelector.each(function () {
						if (isItemAlreadyAdded($(this).val())) {
							$(this).siblings("input").attr('checked', 'checked');
						}

					});

					if ((places.indexOf(item) === 0)) {
					    Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(item.data.Latitude, item.data.Longitude);
					}


				    //}
					//                    if (item.data.City != undefined) {
					//                        var city = item.data.City;
					//                        var d = city.Data;
					//                        wdot.Listing.listingViewModel.Add("cities", city.Name, city.Id, null, $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Id_']"), d);
					//                    }
				}

			    //If this is the first place, then we need to update the nearby items
				//if (places.indexOf(value) === 0) {
				//    Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(data.latitutde, data.longitutde);
			    
			}

			if (rerender) 
			    render(true);
		},
		removeItem = function (item) {
			if (typeof item.data == "string")
				item.data = ko.utils.parseJson(item.data);
			var cityId = item.data.City != undefined ? item.data.City.Id : item.data.CityId;
			var cities = $("#event-location .sprite-remove[data-cityid='" + cityId + "']");
			if (cities.length == 1) {
				var city = ko.utils.arrayFilter(wdot.Listing.listingViewModel.Items.cities(), function (i) {
					return i.id == cityId;
				});
				if (city.length != undefined && city.length > 0)
					city = city[0];
				if (city != undefined)
					wdot.Listing.listingViewModel.Remove(city, 'cities', $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Id_']"));
			}
			places.remove(item);
			render();
		},
		isItemAlreadyAdded = function (id) {
			return ko.utils.arrayFilter(places(), function (item) {
				return item.id() == id;
			}).length > 0;
		},
		removeItemById = function (id) {
			places.remove(ko.utils.arrayFilter(places(), function (item) {
				return item.id() == id;
			})[0]);
		},
		getItemByUniqueId = function (id) {
			return ko.utils.arrayFilter(places(), function (item) {
				return item.uniqueIdForJS == id;
			})[0];
		},
		addCustomItem = function () {
			if (city() != undefined && city() != '' && zip() != undefined && zip() != '')
				this.bingMap.geoCodeAddress(address(), 'wdot.Event.eventLocationListingViewModel.GeoCodeCallback');
		},
		addCustomLocation = function (name, id, data, rerender) {
		    
			if (rerender == undefined)
				rerender = true;
			var item, isNew;
			var cityTB = $("#City");
			if ($("#saveCustomLocation").attr("data-uniqueIdForJS")) {
				isNew = false;
				item = ko.utils.arrayFilter(places(), function (i) {
					return i.uniqueIdForJS == $("#saveCustomLocation").attr("data-uniqueIdForJS");
				})[0];
				item.data.HasName = data.HasName;
				item.data.IsOverridden = data.IsOverridden;
				item.data.Latitude = data.Latitude;
				item.data.LatitudeOverride = data.LatitudeOverride;
				item.data.Longitude = data.Longitude;
				item.data.LongitudeOverride = data.LongitudeOverride;
				item.data.LocationAddress = data.LocationAddress;
				if (cityTB.attr("data-itemid") != undefined && cityTB.attr("data-itemid") != item.data.CityId) {
					var placesWithCity = ko.utils.arrayFilter(places(), function (i) {
						return i.CityId() == item.data.CityId;
					});

					if (placesWithCity.length <= 1) {
						var cityToRemove = ko.utils.arrayFilter(wdot.Listing.listingViewModel.Items.cities(), function (i) {
							return i.id == item.data.CityId;
						})[0];
						var indexToRemove = wdot.Listing.listingViewModel.Items.cities.indexOf(cityToRemove);
						wdot.Listing.listingViewModel.Items.cities.splice(indexToRemove, 1);
					}

					item.data.City = {};
					item.data.City = cityTB.attr("data-itemid");
					item.CityId(cityTB.attr("data-itemid"));
					wdot.Listing.listingViewModel.Add("cities", cityTB.val(), item.CityId(), null, $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Id_']"), cityTB.attr("data-listItemData"));
				}
			} else {
				isNew = true;
				item = new wdot.Event.EventLocationItem();
				item.id(id);
				item.data = data;
			}

			if (name == undefined || name == "") {
				if (data.LocationAddress != undefined) {
					var la = data.LocationAddress;
					name = la.Street1 + " " + la.City + ", " + la.State + " " + la.ZipCode;
				}
			}
			item.name(name);
			item.isPlace(false);
		    //myObservableArray.indexOf('Blah')
			if (!item.CityId()) {
			    if (item.data.CityId != undefined && item.data.CityId > 0)
			        item.CityId(item.data.CityId);
			    else
			        item.CityId(cityTB.attr("data-itemid"));
		    }

			if (isNew) {
				places.push(item);
				wdot.Listing.listingViewModel.Add("cities", cityTB.val(), item.CityId(), null, $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Id_']"), cityTB.attr("data-listItemData"));
			}
			if (rerender)
			    render(true);

		    //If this is the first place, then we need to update the nearby items
            //if (places.indexOf(item) === 0) {
            //    Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(item.data.Latitude, item.data.Longitude);
            //}

		},
		geoCodedLat = ko.observable(),
		geoCodedLong = ko.observable(),
		geoCodeCallback = function (data) {
			var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
			var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
			geoCodedLat(latitutde);
			geoCodedLong(longitutde);
			addCustomLocation(name() != undefined && name() != '' ? name() : addressAsString(), 0, {
				HasName: (name() != undefined && name() != '' ? true : false),
				Latitude: geoCodedLat(),
				Longitude: geoCodedLong(),
				LatitudeOverride: geoCodedLat(),
				LongitudeOverride: geoCodedLong(),
				IsOverridden: false,
				LocationAddress:
				{
					Street1: street(),
					City: city(),
					State: state(),
					ZipCode: zip(),
					Id: 0
				}
			});
		},
		geoCodedLatLong = ko.computed(function () {
			return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
		}).extend({ throttle: 100 }),
		addressAsString = ko.computed(function () {
			return street() + " " + city() + ", " + state() + " " + zip();
		}),
		editCustomLocation = function (item) {
			if (!item.isPlace()) {
				if (typeof item.data == "string")
					item.data = ko.utils.parseJson(item.data);
				street(item.data.LocationAddress.Street1);
				city(item.data.LocationAddress.City);
				zip(item.data.LocationAddress.ZipCode);
				if (item.data.HasName) {
					name(item.name());
				} else {
					name();
				}
				$("#saveCustomLocation").attr("data-uniqueIdForJS", item.uniqueIdForJS);
				$("#event-location").tabs("option", "selected", 1);
			}
		};
		return {
			AddItem: addItem,
			places: places,
			RemoveItem: removeItem,
			LoadViewAll: loadViewAll,
			Render: render,
			LoadEventLocationList: loadEventLocationList,
			EditCustomLocation: editCustomLocation,
			AddCustomLocation: addCustomLocation,
			AddCustomItem: addCustomItem,
			GeoCodeCallback: geoCodeCallback,
			street: street,
			city: city,
			state: state,
			zip: zip,
			address: address,
			name: name,
			geoCodedLatLong: geoCodedLatLong,
			geoCodedLat: geoCodedLat,
			geoCodedLong: geoCodedLong,
			addressAsString: addressAsString,
			getItemByUniqueId: getItemByUniqueId,
            addItemsFromViewAll: addItemsFromViewAll,
            bingMap: bingMap
		};
	} (),
	loadEventAutocomplete = function (eventSelector, selectorFind) {
		var cache = {},
			lastXhr = '',
			itemSelected = false;
		$(eventSelector).find(selectorFind).autocomplete({
			source: function (request, response) {
				itemSelected = false;
				response([{ Id: "AJAX", Name: ''}]);
				Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
				var term = request.term;
				if (term in cache) {
					Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
					response(cache[term]);
					return;
				}
				lastXhr = Wdot.Ajax.prototype.Get("/admin/events/autocomplete", request, function (data, status, xhr) {
					cache[term] = data;
					if (xhr === lastXhr) {
						response(data);
					}
				});
			},
			focus: function (event, ui) {
				$(eventSelector).find(selectorFind).val(ui.item.Name);
				return false;
			},
			select: function (event, ui) {
				itemSelected = true;
				$(eventSelector).find(selectorFind).val(ui.item.Name);
				$(eventSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
				$(eventSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
				return false;
			},
			close: function (event) {
				Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
			},
			minLength: 1
		}).data("ui-autocomplete")._renderItem = function (ul, item) {
			return $("<li></li>")
					.data("ui-autocomplete-item", item)
					.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
					.appendTo(ul);
		};
	},
	verifyViewModel = function () {
		var verifyEvent = function () {
			if (name() != undefined) {
				var json = {};
				json.name = name();
				json.currentId = $('#Id').val();
				Wdot.ModalWindow.ajaxContentType = "application/json";
				Wdot.Ajax.prototype.AddAjaxLoader($("body"), true);
                Wdot.ModalWindow.loadAjaxPost(550, "auto", "/admin/events/verify", ko.utils.stringifyJson(json), function () {
					Wdot.Ajax.prototype.RemoveAjaxLoader($("body"), true);
				});
			}
		},
		isVerifed = ko.observable(false),
		name = ko.observable();
		return {
			verifyEvent: verifyEvent,
			name: name,
			isVerifed: isVerifed
		};
	} (),
	createEditViewModel = function () {
		var selectedCategories = ko.observableArray(),
		throttledSelectedCategories = ko.computed(function () {
			return selectedCategories();
		}).extend({ throttle: 500 }),
		previousPrimaryCategory,
		initobservables = function (nameParam) {
			wdot.Event.verifyViewModel.name(wdot.Utils.HtmlDecode(nameParam));
			$("#event-location").tabs();
			$("#eventPlacesListing span.expdBtn.sprite-expand").click();
		},
		lastSelectedCity,
		init = function () {
			wdot.Attribute.initCollapsible();
			var initialCategories = [];
			$("[name='CategoryListingModel.PrimaryCategory']:checked,[name='CategoryListingModel.SelectedCategories']:checked").each(function () {
				if ($(this).val() != 0)
					initialCategories.push($(this).val());
			});
			wdot.Event.eventLocationListingViewModel.state("WI");
			if (initialCategories.length > 0)
				selectedCategories(initialCategories);

			$("[name='CategoryListingModel.PrimaryCategory'],[name='CategoryListingModel.SelectedCategories']").change(function () {
				selectedCategories($("[name='CategoryListingModel.PrimaryCategory']:checked,[name='CategoryListingModel.SelectedCategories']:checked").map(function (i, item) { return $(item).val(); }));
			});
			if (selectedCategories().length == 0)
				selectedCategories.push(0);


			wdot.Category.buildCategoryList();
		};
		throttledSelectedCategories.subscribe(function (value) {
			wdot.Category.getAttributesSelector(value, "attributes-Selector", "Event", $("#Id").val());
		});
		return {
			init: init,
			initObservables: initobservables,
			lastSelectedCity: lastSelectedCity
		};
	} (),
	listingPageViewModel = function () {
		var generateListingFilterJSON = function () {
			var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");
			var categories = $("select[name='SelectedCategories'] :selected");
			var filterKeyword = $("input[name='filterKeyword']").val();
			var id = $("input[name='Id']").val();
			var name = $("input[name='Name']").val();
			var zipCode = $("input[name='ZipCode']").val();
			var cities = $("input[name*='Cities.SelectedItems']");
			var startDate = $("input[name='StartDate']").val();
			var endDate = $("input[name='EndDate']").val();
			var queryString = '';

			if (checkedStatuses.length > 0) {
				var i = 0;
				var selectedVersionStatuses = {};
				ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
				wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;
			}
			else {
				wdot.Paging.data.SelectedVersionStatuses = {};
			}
			if (cities.length > 0) {
				var i = 0;
				var selectedCities = {};
				ko.utils.arrayForEach(cities, function (t) { selectedCities[i] = $(t).val(); queryString += '&Cities.SelectedItems[' + i + ']=' + $(t).val(); i++; });
				wdot.Paging.data['Cities.SelectedItems'] = selectedCities;
			}
			else {
				wdot.Paging.data['Cities.SelectedItems'] = {};
			}


			if (categories.length > 0) {
				var i = 0;
				var selectedCategories = {};
				ko.utils.arrayForEach(categories, function (t) { selectedCategories[i] = $(t).val(); queryString += '&SelectedCategories[' + i + ']=' + $(t).val(); i++; });
				wdot.Paging.data.SelectedCategories = selectedCategories;
			}
			else {
				wdot.Paging.data.SelectedCategories = {};
			}

			//removeOfficeUseOnlyFilters
			for (var i in wdot.Paging.data) {
				if (new RegExp('ForOfficeUseOnlyModel*').test(i)) {
					delete wdot.Paging.data[i];
				}
			}

			var attrs = {};
			$("[type='text'][name^='ForOfficeUseOnlyModel'],textarea[name^='ForOfficeUseOnlyModel'],[type='checkbox'][name^='ForOfficeUseOnlyModel']:checked,[type='hidden'][name^='ForOfficeUseOnlyModel']").each(function () {
				if (attrs[$(this).attr("name")] == undefined && $(this).attr("name").indexOf("AttributeName") == -1 && $(this).attr("name").indexOf("AttributeResponseMethod") == -1) {
					attrs[$(this).attr("name")] = $(this).val();
					queryString += '&' + $(this).attr("name") + '=' + $(this).val();
				}
			});

			$.extend(true, wdot.Paging.data, attrs);

			queryString += '&filterKeyword=' + filterKeyword;
			queryString += '&Id=' + id;
			queryString += '&Name=' + name;
			queryString += '&ZipCode=' + zipCode;
			queryString += '&StartDate=' + startDate;
			queryString += '&EndDate=' + endDate;

			wdot.Paging.data.filterKeyword = filterKeyword;
			wdot.Paging.data.Id = id;
			wdot.Paging.data.Name = name;
			wdot.Paging.data.ZipCode = zipCode;
			wdot.Paging.data.StartDate = startDate;
			wdot.Paging.data.EndDate = endDate;
			return queryString;
		},
		resetListingFilter = function () {
			$("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
			$("select[name='SelectedCategories'] :selected").attr("selected", false);
			$("input[name='filterKeyword']").val('');
			$("input[name='Id']").val('');
			$("input[name='Name']").val('');
			$("input[name='ZipCode']").val('');
			$("input[name='StartDate']").val('');
			$("input[name='EndDate']").val('');
			$("[name^='ForOfficeUseOnlyModel'][type='checkbox']:checked").attr("checked", false);
			$("[name^='ForOfficeUseOnlyModel'][type='text'],textarea[name^='ForOfficeUseOnlyModel']").val('');
			var cities = wdot.Listing.listingViewModel.Items.cities();

			for (var i = cities.length - 1; i >= 0; i++) {
				wdot.Listing.listingViewModel.Remove(cities[i], 'cities', $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Name_']"));
			}
		},
		selectedType = ko.observable();

		//        selectedType.subscribe(function() {
		//            var href = $("#addItemButton").attr("href");
		//            var lastIndex = href.lastIndexOf('/');
		//            href = href.substr(0, lastIndex + 1);
		//            $("#addItemButton").attr("href", href + selectedType());
		//        });

		return {
			selectedType: selectedType,
			generateListingFilterJSON: generateListingFilterJSON,
			resetListingFilter: resetListingFilter
		};
	} ();
	return {
		loadEventAutocomplete: loadEventAutocomplete,
		createEditViewModel: createEditViewModel,
		verifyViewModel: verifyViewModel,
		eventLocationListingViewModel: eventLocationListingViewModel,
		EventLocationItem: eventLocationItem,
		ListingPageViewModel: listingPageViewModel
	};
} ();