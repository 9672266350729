var tw = tw || {};
tw.Popup = function () {

	var to_resize = null,
		isActive = false;

	/**
	 * initialize Popup
	*/
	var init = function () {
		//console.log('tw.popup.init()');

		$('.js-popup-close').on('click', function () {
			var marketId = $('.js-popup').data('marketid'),
				marketName = $('.js-popup').data('marketname');
			//console.log('tw.popup.market:', marketId, marketName);
			$('.js-popup').remove();
			tw.Analytics.ga4('Popup Traveler', 'Close ' + marketName, document.title, 'popup');
			addCookie();
		});

		$('.js-popup-cta').on('click', function () {
			var marketName = $('.js-popup').data('marketname');
			tw.Analytics.ga4('Popup Traveler', 'CTA Click ' + marketName, document.title, 'popup');
			addCookie();
		});

		trackScroll();
		
	}


	/**
	 * add cookie
	*/
	var addCookie = function () {
		$.cookie('popupTraveler', 'true', { expires: 2, path: "/" });
	}


	/**
	 * Track scroll halfway down page before displaying
	*/
	var trackScroll = function () {
		var body = document.body,
			html = document.documentElement,
			windowHeight = window.innerHeight,
			contentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight),
			scrollY = $(window).scrollTop(),
			percScrolled = scrollY / (contentHeight - windowHeight),
			percRounded = Math.floor(percScrolled * 100),
			scrollYPercTrigger = window.innerWidth > 768 ? 45 : 22;
		// console.group('scrollTracker:');
		// console.log('scrollY:', scrollY);
		// console.log('windowHeight:', windowHeight);
		// console.log('contentHeight:', contentHeight);
		//console.log('scrollYPercTrigger:', scrollYPercTrigger);
		// console.log('percScrolled:', percScrolled, '->', percRounded);
		// console.groupEnd();

		if(percRounded > scrollYPercTrigger) {
			$('.js-popup').removeClass('hidden');
			setTimeout(() => {
				$('.js-popup').addClass('active');//slight delay, otherwise the css animation doesn't work
			}, 10);
			isActive = true;
		}
	}


	$(window).scroll(function (event) {
		clearTimeout(to_resize);
		to_resize = setTimeout(function () {
			if(!isActive) trackScroll();
		}, 100);
	});

	return {
		init: init
	}

}();


$( document ).ready(function() {
	//console.log("$('.js-popup').length:", $('.js-popup').length);
	if($('.js-popup').length > 0) {
		//console.log('found Popup, initialize');
		setTimeout(() => {
			tw.Popup.init();//slight delay to let the rest of the page load
		}, 1500);
	}
});