var wdot = wdot || {};
wdot.Favorites = function() {
	var showAddToTripPopUp = function (contentType, contentId, selector) {
        // console.group('showAddToTripPopUp()');
        // console.log('contentType:', contentType);
        // console.log('contentId:', contentId);
        // console.log('selector:', selector);
        // console.groupEnd();
        wdot.Favorites.ContentId = contentId;
        $('#AddToGroups').data('isselectable', 'true');
        wdot.TripPlannerAddToGroup.TripGroupViewModel().GetTripGroups(contentId, contentType, selector);
        wdot.TripPlannerAddToGroup.FavoriteSelector(selector);

        // Adding custom event that popup-position.js relies on
        var e = $.Event('call-popup', { activeItem: selector });
        selector.trigger(e);
   },

   showLoginOrTripGroupPopUp = function (isUserLoggedIn, contentType, dataId, currentSelector, sessionUserPopUpSelector) {
        // console.group('showLoginOrTripGroupPopUp()');
        // console.log('isUserLoggedIn:', isUserLoggedIn);
        // console.log('contentType:', contentType);
        // console.log('dataId:', dataId);
        // console.log('currentSelector:', currentSelector);
        // console.log('sessionUserPopUpSelector:', sessionUserPopUpSelector);
        // console.log('$.cookie(sessionLoginConfirm):', $.cookie('sessionLoginConfirm'));
        // console.groupEnd();
        if (!$.cookie('sessionLoginConfirm') && (isUserLoggedIn == 0 ||  isUserLoggedIn == "false")) {
            //console.log('**no cookie or not logged in');
            sessionUserPopUpSelector.removeClass('hidden').position({
                my: "bottom-20", /* Include offset for arrow in :after */
                at: "center top",
                of: currentSelector,
                collision: "fit"
            });
        } else {
            //console.log('**logged in, show trip popup');
            showAddToTripPopUp(contentType, dataId, currentSelector);
        }

        currentSelector.parents('.js-favorites-parent').find('.js-session-favorite-cancel').click(function () {
            $(this).closest('.js-session-favorite-prompt').addClass('hidden');
            $.cookie('sessionLoginConfirm', 'true', { expires: 1 });
            showAddToTripPopUp(contentType, dataId, currentSelector.parents('.js-favorites-parent'));
        });
   },

   setUpFavorites = function() {
        //console.log('setUpFavorites()');
        $("body").on("click", ".js-favorites", function () {
            // console.log('.js-favorites.click');
            // console.log('.js-user-logged-in:', $('.js-user-logged-in').length);
            if($('.js-user-logged-in').length > 0) {
                var isUserLoggedIn = $('.js-user-logged-in').val().trim();
                showLoginOrTripGroupPopUp(
                    isUserLoggedIn, 
                    $(this).attr("data-content-type"), 
                    $(this).attr("data-id"), 
                    $(this), 
                    $(this).parents('.js-favorites-parent').find('.js-session-favorite-prompt')
                );
            }
            return false;
        });
   };
   
   return {
        SetUpFavorites: setUpFavorites
    };
} ();

wdot.Favorites.SetUpFavorites();