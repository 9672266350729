var tw = tw || {};
tw.AccessibilityFilter = function () {

    var _log = false,
        accessibilityFilters = [],
        resultsStr,
        alreadyInitialized = false;


    var init = function () {
        if (_log) console.log('tw.AccessibilityFilter.init()');

        //if (_log) console.log('tw.AccessibilityFilter. $(.js-accessibility-modal).length', $('.js-accessibility-modal').length);
        if($('.js-accessibility-modal').length <= 0) {
            if (_log) console.log('tw.AccessibilityFilter.no modal');
            return false;
        }

        if (alreadyInitialized) {
            if (_log) console.log('tw.AccessibilityFilter.alreadyInitialized');
            return false;
        }

        loadAccessibilityFromCookie();

        /**
         * initialize buttons
        */
        $(".js-directory").on('click', '.js-accessibility-modal-open', function () {
            //if(_log)console.log('tw.AccessibilityFilter js-accessibility-modal-open');
            $('.js-accessibility-modal').removeClass('hidden');
            loadAccessibilityFromCookie();
        });

        $(".js-directory").on('click', '.js-accessibility-modal-close', function () {
            //if(_log)console.log('tw.AccessibilityFilter. js-accessibility-modal-close');
            closeAccessibilityModal();
            loadAccessibilityFromCookie();
        });

        $(".js-directory").on('click', '.js-accessibility-modal-submit', function () {
            if(_log)console.log('tw.AccessibilityFilter. js-accessibility-modal-submit');
            submitAccessibilityFormValues();
        });

        $(".js-directory").on('click', '.js-accessibility-filter-clear', function () {
            if(_log)console.log('tw.AccessibilityFilter. js-accessibility-filter-clear');
            clearAll();
        });

        $(".js-directory").on('click', '.js-filter-form-clear', function () {
            //if(_log)console.log('tw.AccessibilityFilter. js-accessibility-filter-clear');
            clearAll();
            submitAccessibilityFormValues();
        });

        // $(".js-directory").on('change', '.js-accessibility-modal input[type="checkbox"]', function () {
        //     if(_log)console.log('tw.AccessibilityFilter. .js-accessibility-modal input[type="checkbox"] change');
        //     updateAccessbilityFiltersArray();
        //     updateActiveButtons();
        // });

        // $(".js-directory").on('click', '.js-accessibility-modal .js-directory-attribute-container', function () {
        //     if(_log)console.log('tw.AccessibilityFilter. .js-accessibility-modal  .js-directory-attribute-container click');
        //     updateAccessbilityFiltersArray();
        //     updateActiveButtons();
        // });

        $(document).on('click', '.js-accessibility-modal-filters-active-btn', function () {
            var filterId = $(this).data('filter-id');
            //if(_log)console.log('tw.AccessibilityFilter. js-accessibility-modal-filters-active-btn:', filterId);
            $('#' + filterId).prop("checked", false);
            $(this).remove();
            updateAccessbilityFiltersArray();
            updateActiveButtons();
        });

        alreadyInitialized = true;
    };


    var loadIfNoHash = function () {
        //if(wdot) wdot.ContentPages.DirectoryListing.updateFilters();

        //todo, instead of just triggering the display, need to trigger the full update if dettected
        var hash = window.location.hash ? window.location.hash : null;
        if (_log) console.log('tw.AccessibilityFilter.hash:', hash);
        if (_log) console.log('tw.AccessibilityFilter.accessibilityFilters.length:', accessibilityFilters.length);

        if(!hash && accessibilityFilters.length > 0) {
            $('.js-filter-form-submit').trigger( "click" );
        }
    }




    /**
     * onload get accessibility checkbox values
    */
    var loadAccessibilityFromCookie = function () {
        var cookieRaw = $.cookie('accessibilityFilters');

        if (_log) console.log('tw.AccessibilityFilter.cookieRaw:', cookieRaw);

            var cookieAccessibilityFilters = cookieRaw ? JSON.parse(cookieRaw) : null;

        accessibilityFilters = [];

        if (_log) console.log('tw.AccessibilityFilter.loadAccessibilityFromCookie:', cookieAccessibilityFilters);
        if(cookieAccessibilityFilters) {
            //console.log('tw.AccessibilityFilter.try to parse cookies');

            cookieAccessibilityFilters.forEach(item => {
                //console.log('tw.AccessibilityFilter.loadAccessibilityFromCookie.filter:', item);
                
                $('.js-accessibility-modal').find('input[type="checkbox"]').each(function( index2 ) {
                    var attrName = $(this).data('attribute-name');
                    //thisText = $(this).siblings('label').text(),
        
                    if(item.Name == attrName) {
                        accessibilityFilters.push(item);
                        $(this).prop("checked", true);
                    }
                });
            });
        }
        updateActiveButtons();
    }



    /**
     * Update Modal Display - triggered from Directory.JS
    */
    var updateModalDisplay = function () {
        if(_log)console.log('tw.AccessibilityFilter.updateModalDisplay()');
        updateAccessbilityFiltersArray();
        updateActiveButtons();
    }


    /**
     * update accessibility filters array by looping through checkboxes
    */
    var updateAccessbilityFiltersArray = function () {
        if(_log)console.log('tw.AccessibilityFilter.updateAccessbilityFiltersArray()');
        accessibilityFilters = [];

        $('.js-accessibility-modal-filter-group').each(function( index ) {
            if(_log)console.log('tw.AccessibilityFilter. js-accessibility-modal-filter-group:', index);
            $(this).find('input[type="checkbox"]').each(function( index2 ) {
                var isChecked = $(this).is(':checked'),
                    thisId = $(this).attr('id'),
                    attrName = $(this).data('attribute-name'),
                    attrCoveoName = $(this).data('attribute-coveo-name'),
                    attrResponseMethodId = $(this).data('attribute-response-method-id'),
                    attrId = $(this).data('attribute-id'),
                    attrIdentifier = $(this).data('attribute-identifier');

                //if(_log)console.log('tw.AccessibilityFilter. g-' + index + '-cb-' + index2 + ": " + isChecked + ": " +  attrName + ": " +  thisId + ": " +  attrIdentifier);
                if(isChecked) {
                    accessibilityFilters.push({
                        id: thisId, 
                        //title: thisText,
                        AttributeId: attrId,
                        Value: true,//value is always true for checked
                        Name: attrName,
                        CoveoName: attrCoveoName,
                        ResponseMethodId: attrResponseMethodId
                    });
                }
            });
        });

    }




    /**
     * add/remove accessibility filter active buttons
    */
    var updateActiveButtons = function () {
        if(_log)console.log('tw.AccessibilityFilter.updateActiveButtons:', accessibilityFilters);

        $('.js-accessibility-modal-filters-active').html('');

        resultsStr = '';
        accessibilityFilters.forEach(item => {
            if(_log)console.log('tw.AccessibilityFilter.updateActiveButtons.activate:', item.Name);

            var activeBtnHtml = `<div class="button--gray button--pill button--icon mr-1 mb-1 js-accessibility-modal-filters-active-btn" data-filter-id="` + item.id + `">` + 
                `<i class="fal fa-times mr-2"></i>` + 
                `<span>` + item.Name + `</span>` + 
            `</div>`;

            $('.js-accessibility-modal-filters-active').append(activeBtnHtml);

            if(resultsStr != '') resultsStr += ', ';
            resultsStr += item.Name;

        });

        if(!accessibilityFilters || accessibilityFilters.length <= 0) {
            $('.js-accessibility-modal-filters-active').html('<span class="small">No filters selected.</span>');
        }

        updateFilterCountDisplay();

    }


    var updateFilterCountDisplay = function () {
        if(_log)console.log('tw.AccessibilityFilter.updateFilterCountDisplay:', accessibilityFilters);
        var filterCount = accessibilityFilters ? accessibilityFilters.length : 0;
        $('.js-accessibility-filter-count').html(filterCount);
    }


    var getResultsStr = function () {
        updateActiveButtons();
        return resultsStr;
    }



    /**
     * submit accessibility checkbox values
     * update values, save as cookie, close modal, remove modal, submit main form
     * logic in contentPages.js reads back in $.cookie('accessibilityFilters') and adds to pagingAjaxData
    */
    var submitAccessibilityFormValues = function () {
        //if(_log)console.log('tw.AccessibilityFilter. submitAccessibilityFormValues');

        updateAccessbilityFiltersArray();

        if (_log) console.log('tw.AccessibilityFilter. submitAccessibilityFormValues:', accessibilityFilters);
        
        $.cookie('accessibilityFilters', JSON.stringify(accessibilityFilters), { expires: 3, path: '/' });

        setTimeout(() => {
            //alert('filters: ' + accessibilityFilters.join(', '));
            closeAccessibilityModal();
            //trigger main form to submit
            $('.js-filter-form-submit').trigger( "click" );
        }, 300);
    }



    /**
     * close accessibility modal
    */
    var closeAccessibilityModal = function () {
        $('.js-accessibility-modal').addClass('hidden');
        $('.js-accessibility-modal').find('input[type="checkbox"]').prop('checked', false);
    }


    var clearAll = function () {
        //if(_log)console.log('tw.Accessibility.clearAll');
        $('.js-accessibility-modal input[type="checkbox"]').prop("checked", false);
        updateAccessbilityFiltersArray();
        updateActiveButtons();
    }

    var deleteAccessibilityFilter = function (filterToDelete) {
        if(_log)console.log('tw.Accessibility.deleteAccessibilityFilter.filterToDelete:', filterToDelete);

        var cookieRaw = $.cookie('accessibilityFilters'),
            cookieAccessibilityFilters = cookieRaw ? JSON.parse(cookieRaw) : null;

        accessibilityFilters = [];

        if (_log) console.log('tw.AccessibilityFilter.deleteAccessibilityFilter:', cookieAccessibilityFilters);
        if(cookieAccessibilityFilters) {

            cookieAccessibilityFilters.forEach(item => {
                if(_log)console.log('tw.AccessibilityFilter.deleteAccessibilityFilter.filter:', item);

                if (filterToDelete != item.AttributeId) {
                    accessibilityFilters.push(item);
                }

            });
        }

        //debugger;

        $.cookie('accessibilityFilters', JSON.stringify(accessibilityFilters), { expires: 3 , path: '/'});


    }


    return {
        init: init,
        clearAll: clearAll,
        deleteAccessibilityFilter: deleteAccessibilityFilter,
        getResultsStr: getResultsStr,
        loadIfNoHash: loadIfNoHash,
        updateAccessbilityFiltersArray: updateAccessbilityFiltersArray,
        updateFilterCountDisplay: updateFilterCountDisplay,
        updateModalDisplay: updateModalDisplay
    }

}();


$(document).ready(function() {
    if(_log)console.log('tw.AccessibilityFilter.doc.ready()');
    tw.AccessibilityFilter.init();
    
    setTimeout(() => {
        tw.AccessibilityFilter.loadIfNoHash();
    }, 500);

});

//if(_log)console.log('tw.AccessibilityFilter.loaded');