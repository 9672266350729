$.validator.setDefaults({ ignore: '' });
$.validator.addMethod('checkminrequired', function (value, element) {
    return $(element).parent().find("ul li") != undefined && $(element).parent().find("ul li").length >= $(element).attr('data-val-checkminrequired-minnum');
});
$.validator.unobtrusive.adapters.add('checkminrequired', ['minnum'], function (options) {
    options.rules['checkminrequired'] = { minnum: options.params.minnum };
    options.messages['checkminrequired'] = options.message;
});
$.validator.addMethod('mediauploadlimit', function (value, element, params) {
    //    return wdot.MediaUploaderCollection.GetNumberOfFilesByIndex(params.index) >= params.min && wdot.MediaUploaderCollection.GetNumberOfFilesByIndex(params.index) <= params.max;
    return true;
});
$.validator.unobtrusive.adapters.add('mediauploadlimit', ['min', 'max', 'index'], function (options) {
    options.rules['mediauploadlimit'] = { min: options.params.min, max: options.params.max, index: options.params.index };
    options.messages['mediauploadlimit'] = options.message;
});
$.validator.addMethod('eventlocationrequired', function (value, element) {
    return wdot.Event.eventLocationListingViewModel.places().length > 0;
});
$.validator.unobtrusive.adapters.add('eventlocationrequried', [], function (options) {
    options.rules['eventlocationrequired'] = {};
    options.messages['eventlocationrequired'] = options.message;
});
$.validator.addMethod('geopointlocationrequired', function (value, element) {
    return wdot.ReportsLocations.eventLocationListingViewModel.places().length > 0;
});
$.validator.unobtrusive.adapters.add('geopointlocationrequired', [], function (options) {
    options.rules['geopointlocationrequired'] = {};
    options.messages['geopointlocationrequired'] = options.message;
});
$.validator.addMethod('locationrequired', function (value, element) {
    return wdot.LatLongSelector.latLongViewModel.latLongItems().length > 0;
});
$.validator.unobtrusive.adapters.add('locationrequired', [], function (options) {
    options.rules['locationrequired'] = {};
    options.messages['locationrequired'] = options.message;
});