var wdot = wdot || {};
wdot.Role = function () {
    var loadRoleAutocomplete = function (roleSelector, selectorFind) {
        var cache = {},
                lastXhr = '',
                itemSelected = false;
        $(roleSelector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/roles/autocompleteforroles", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (role, ui) {
                $(roleSelector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(roleSelector).find(selectorFind).val(ui.item.Name);
                $(roleSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(roleSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                        .appendTo(ul);
        };
    },
            id = ko.observable(),
            name = ko.observable(),
            createEditRoleViewModel = function () {
                var users = new Array(),
                    currentUser,
                    usersInRole = ko.observableArray(),
                    removeUserFromRole = function (user) {
                        currentUser = user;
                        Wdot.Ajax.prototype.Post("/admin/roles/removeuserfromroleajax?roleid=" + $("#Id").val() + "&userid=" + user.id, null, removeUserAjaxSuccess, null);
                    },
                    removeUserAjaxSuccess = function (data) {
                        if (data.Success) {
                            usersInRole.remove(currentUser);
                        }
                    },
                    addUserToRole = function () {
                        var alreadyAdded = false;
                        var userId = $("#UsersDropDown").val();
                        for (var i = 0; i < usersInRole().length; i++) {
                            if (usersInRole()[i].id === parseInt(userId)) {
                                alreadyAdded = true;
                            }
                        }
                        if (!alreadyAdded) {
                            Wdot.Ajax.prototype.Post("/admin/roles/addusertoroleajax?roleId=" + $("#Id").val() + "&userId=" + userId, null, addUserAjaxSucces, null);
                        }
                    },
                    addUserAjaxSucces = function (data) {
                        if (data.Success) {
                            $.each(users, function () {
                                if (this.id === parseInt($("#UsersDropDown").val())) {
                                    usersInRole.push(this);
                                }
                            });
                        }
                    },
                    loadUsersInRole = function (usersInRoleJsonAsString) {
                        var usersInRoleJson = $.parseJSON(usersInRoleJsonAsString);
                        for (var prop in usersInRoleJson) {
                            if (!usersInRoleJson.hasOwnProperty(prop)) {
                                continue;
                            }

                            $.each(users, function () {
                                if (this.id === usersInRoleJson[prop]["id"]) {
                                    usersInRole.push(this);
                                }
                            });
                        }
                    },
                    loadAllUsers = function (usersJsonAsString) {
                        users = $.parseJSON(usersJsonAsString);
                    };
                return {
                    users: users,
                    usersInRole: usersInRole,
                    removeUserFromRole: removeUserFromRole,
                    addUserToRole: addUserToRole,
                    loadUsersInRole: loadUsersInRole,
                    loadAllUsers: loadAllUsers
                };
            } ();
    return {
        id: id,
        name: name,
        createEditRoleViewModel: createEditRoleViewModel,
        loadRoleAutocomplete: loadRoleAutocomplete
    };
} ();