window.log = function f() {
    log.history = log.history || [];
    log.history.push(arguments);

    if (this.console) {
        var args = arguments, newarr;
        args.callee = args.callee.caller;
        newarr = [].slice.call(args);

        if (typeof console.log === 'object') {
            log.apply.call(console.log, console, newarr);
        } else {
            console.log.apply(console, newarr);
        }
    }
};

// Make it safe to use console.log always
(function (a) {
    function b() { }
    for (var c = "assert,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profileEnd,time,timeEnd,trace,warn".split(","), d; !!(d = c.pop()) ;) {
        a[d] = a[d] || b;
    }
});

(function () {
    try {
        console.log();

        return window.console;
    } catch (a) {
        return (window.console = {});
    }
});


function globalNavLazyLoad() {
    //console.log('globalNavLazyLoadglobalNavLazyLoadglobalNavLazyLoadglobalNavLazyLoad');
    $('.js-global-nav-lazy-img').each(function (index) {
        var src = $(this).attr('data-src'),
            preset = $(this).attr('data-preset'),
            fullSrc = src + '?preset=' + preset,
            srcArr = src.split('/'),
            file = srcArr[srcArr.length - 1],
            title = file.slice(0, -4)
        ;
        //console.log('gnli.' + index + ': ' + fullSrc);
        $(this).prepend('<img loading="lazy" src="' + fullSrc + '" alt="' + title + '">');
    });
}


$(document).ready(function () {

    // to utilize the custom dropdown
    
    $('.sponsors li:last-child').addClass('last');
    $('.sponsors li:nth-child(3n)').addClass('third');
    $('.sponsors li:nth-child(3n+1)').addClass('first');

    // Variables for dom elements
    var slideShow;
    var locationSocialLinks;
    var featureMoved = 0;

    globalNavLazyLoad();

    // Trunk8 plugin initialization for captions for subcategory slideshows
    if ($('.slide-info').length > 0) {
        $('.slide-info').trunk8({
            lines: 2,
            fill: '&hellip; <a id="read-more" href="#">read more</a>'
        });
    }

    $(document).on('click', '#read-more', function (event) {
        $(this).parent().trunk8('revert').append(' <a id="read-less" href="#">read less</a>');

        return false;
    });

    $(document).on('click', '#read-less', function (event) {
        $(this).parent().trunk8();

        return false;
    });

    // Expandable List
    $('a.listingToggle').click(function () {
        // Basic accordion functionality
        if ($(this).siblings('ul').hasClass('opened')) {
            $(this).siblings('ul').slideUp("slow").removeClass('opened');
        } else {
            $('a.listingToggle').siblings('ul.opened').slideUp("slow").removeClass('opened');
            $(this).siblings('ul').slideToggle("slow").toggleClass('opened');
        }

        return false;
    });

    $('.category.dining a.viewAllToggle').text("collapse").siblings('ul').slideToggle("fast").toggleClass('opened');
    $('body').on('click', 'a.viewAllToggle', function () {
        if ($(this).text() == "expand") {
            $(this).parents(".filterList").find(".category a.viewAllToggle").text("expand");
            $(this).text("collapse");
        } else {
            $(this).text("expand");
        }

        // Basic accordion functionality
        if ($(this).siblings('ul').hasClass('opened')) {
            $(this).siblings('ul').slideToggle("slow").toggleClass('opened');
        } else {
            $('a.viewAllToggle').siblings('ul.opened').slideToggle("slow").toggleClass('opened');
            $(this).siblings('ul').slideToggle("slow").toggleClass('opened');
        }

        return false;
    });

    //// Travel Green Score Display
    //var tgScore = ($('div.travelGreenScore').text() / 150) * 100;
    //$('div.travelGreenScore').css('width', tgScore + '%');

    // Travel Green Score Display
    var tgScore = ($('div.travelGreenScore').text() / 150) * 100;//PHASE OUT THIS CODE. MIGRATE TO BELOW
    $('div.travelGreenScore').css('width', tgScore + '%');//PHASE OUT THIS CODE. MIGRATE TO BELOW

    var tgScoreRaw = $('.js-travel-green__indicator-bar').attr('data-score');
    var tgScorePerc = (tgScoreRaw / 150) * 100;
    var tgScoreMax = $('.js-travel-green__indicator-bar').attr('data-max');
    $('.js-travel-green__score-text').html(tgScoreRaw + '<span class="pts">pts</span><span class="arrow-dn"></div>').css({ 'left': tgScorePerc + '%' });
    $('.js-travel-green__score').css({ 'width': tgScorePerc + '%' });
    $('.js-travel-green__perc-mark').each(function () {
        //this used to be set up by percent. switched to points with marks
        var mark = $(this).attr('data-mark');
        var perc = (mark / tgScoreMax) * 100;
        //console.log('perc: ' + perc);
        $(this).html('<span class="perc">' + mark + '</span>').css({ 'left': perc + '%' });
    });

    // Search Expand for Tablet Functionality
    var searchBox = $('a.searchToggle').siblings('form');
    $('a.searchToggle').click(function (e) {
        //Cancel the link behavior
        e.preventDefault();
        searchBox.animate({ width: 284 }, 200);
        searchBox.removeClass('inHiding');
        $('li.search').addClass('active');
    });

    // Close search when clicking on anything else
    var search = $('a.searchToggle').parent('li');
    $(document).bind('click', function (e) {
        var windowWidth = $(window).width();

        if (windowWidth < 1024) {
            var target = $(e.target);

            if (target.closest(search).length < 1) {
                searchBox.animate({ width: 0 }, 200, function () {
                    searchBox.addClass('inHiding');
                    searchBox.removeAttr('style');
                    $('li.search').removeClass('active');
                });

                return;
            }
        }
    });

    // Get window size and do things as page loads, resizes, or changes orientation
    var windowSize = $(window).width();

    $.breakpoint((function () {
        return {
            condition: function () {
                return window.matchMedia('only screen and (max-width:760px)').matches;
            },
            first_enter: function () {
                // grab slider and social links
            },
            enter: function () {
                // Add slider to DOM when condition is true.
                $('<div class="prev_btn">&laquo;</div><div class="next_btn">&raquo;</div>').prependTo(".contentNav");
                $("nav.contentNav").removeClass("subCatHid");
                if ($(".contentNav ul").width() > 5) {
                    $(".contentNav ul").carouFredSel({
                        scroll: 1,
                        auto: false,
                        responsive: true,
                        prev: ".prev_btn",
                        next: ".next_btn",
                        height: 39,
                        items: { width: 200, visible: { min: 1, max: 1 } }
                    });
                }
                $("nav.mobileSubCats").removeClass("subCatHid");
                if ($(".mobileSubCats ul").width() > 5) {
                    $(".mobileSubCats ul").carouFredSel({
                        scroll: 1,
                        auto: false,
                        responsive: true,
                        prev: ".prev_btn",
                        next: ".next_btn",
                        height: 39,
                        items: { width: 260, visible: { min: 1, max: 1 } }
                    });
                }
            },
            exit: function () {
                // Detach slider when condition is false.
                $(".contentNav ul").trigger("destroy");
                $(".contentNav ul").removeAttr("style");
                $(".contentNav li").removeAttr("style");
                $(".contentNav .prev_btn").detach();
                $(".contentNav .next_btn").detach();
            }
        };
    }()));
});          	// END DOCUMENT READY

var wdot = wdot || {};
wdot.Favorites = function () {
    var isPlace = null,
		contentId = null,
        addToFavorites = function (linkSelector) {
            if ($(linkSelector).hasClass("isFavorite")) {
                return false;
            }

            if (wdot.Favorites.IsPlace != null && wdot.Favorites.ContentId != null && wdot.Favorites.ContentId > 0) {
            }
        },
		removeFavorite = function (linkSelector) {
		    var id = parseInt($(linkSelector).attr('data-id'));

		},
		handleUnauthenticatedLogin = function () {
		    var location = window.location.toString();
		    window.location = '/account/logon?redirecturl=' + escape(location.toLowerCase()) + '&contentid=' + wdot.Favorites.ContentId + '&isplace=' + wdot.Favorites.IsPlace;
		};

    return {
        IsPlace: isPlace,
        ContentId: contentId,
        AddToFavorites: addToFavorites,
        RemoveFavorite: removeFavorite
    };
}();