var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var NearByLocationItem = (function (_super) {
        __extends(NearByLocationItem, _super);
        function NearByLocationItem(id, placeTypeId, placeType, distance, isManuallySelected, isNewlyAdded, isRemoved) {
            if (id === void 0) { id = 0; }
            if (placeTypeId === void 0) { placeTypeId = -1; }
            if (placeType === void 0) { placeType = -1; }
            if (distance === void 0) { distance = 0; }
            if (isManuallySelected === void 0) { isManuallySelected = false; }
            if (isNewlyAdded === void 0) { isNewlyAdded = false; }
            if (isRemoved === void 0) { isRemoved = false; }
            var _this = _super.call(this) || this;
            _this.id = id;
            _this.placeTypeId = placeTypeId;
            _this.placeType = placeType;
            _this.distance = distance;
            _this.isManuallySelected = isManuallySelected;
            _this.isNewlyAdded = isNewlyAdded;
            _this.isRemoved = isRemoved;
            return _this;
        }
        NearByLocationItem.prototype.loadAutoComplete = function (selector, url) {
            var cache = {};
            var lastXhr;
            var itemSelected = false;
            var ajax = new Wdot.Ajax();
            //let autoCompeteHelper = new AutocompleteHelper();
            $(selector).autocomplete({
                source: function (request, response) {
                    itemSelected = false;
                    response([{ Id: "AJAX", Name: '' }]);
                    ajax.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    var term = request.term;
                    if (term in cache) {
                        ajax.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(cache[term]);
                        return;
                    }
                    var paramaters = { term: term, placeType: selector.data("placetype") };
                    lastXhr = ajax.Get(url, paramaters, function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(data);
                        }
                    });
                },
                focus: function (event, ui) {
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    return false;
                },
                select: function (event, ui) {
                    itemSelected = true;
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    $(selector).attr("data-placetypeid", ui.item.PlaceTypeId);
                    return false;
                },
                close: function (event) {
                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                },
                minLength: 1
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                //if the Id is less than 0, then it is a heading
                if (item.Id > 0) {
                    return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a>" + item.Name + "</a>")
                        .appendTo(ul);
                }
                else {
                    return $("<li></li>")
                        .append("<h2>" + item.Name + "</h2>")
                        .appendTo(ul);
                }
            };
        };
        return NearByLocationItem;
    }(Wdot.BaseSelectedItem));
    Wdot.NearByLocationItem = NearByLocationItem;
})(Wdot || (Wdot = {}));