var wdot = wdot || {};
wdot.Attribute = function () {
    var multiChoiceItem = function (nameParam, valueParam, displayOrderParam, indexParam) {
        var name = ko.observable(nameParam),
            value = ko.observable(valueParam),
            displayOrder = ko.observable(isNaN(displayOrderParam) ? indexParam : displayOrderParam),
            index = ko.observable(indexParam),
            inputNamePrefix = "MultiChoiceItems",
            inputNameForValue = ko.computed(function () {
                return inputNamePrefix + "[" + index() + "].MultiChoiceValue";
            }),
            inputNameForDisplayOrder = ko.computed(function () {
                return inputNamePrefix + "[" + index() + "].MultiChoiceDisplayOrder";
            }),
            inputNameForName = ko.computed(function () {
                return inputNamePrefix + "[" + index() + "].MultiChoiceName";
            });
        return {
            name: name,
            value: value,
            index: index,
            displayOrder: displayOrder,
            inputNameForName: inputNameForName,
            inputNameForDisplayOrder: inputNameForDisplayOrder,
            inputNameForValue: inputNameForValue,
            inputNamePrefix: inputNamePrefix
        };
    },
    createEditViewModel = function () {
        var responseMethodId = ko.observableArray(),
            multiChoiceItems = ko.observableArray(),
            newName = ko.observable(),
            newValue = ko.observable(),
            currentIndex = 0,
            currentMaxDisplayOrder = 1,
            numberOfItemsToLoad = 0,
            addMultiChoiceItem = function () {
                var item = new multiChoiceItem(newName(), newValue(), currentMaxDisplayOrder++, currentIndex++);
                numberOfItemsToLoad++;
                $("#multiChoice-table .sortDown").removeClass("hidden");
                multiChoiceItems.push(item);
                newName('');
                newValue('');
            },
            moveDisplayOrderUp = function (itemFromTable, event) {
                var row = $(event.currentTarget).parent().parent();
                row.insertBefore(row.prev());

                var rows = $(event.currentTarget).parent().parent().parent().find("tr.sortable");

                $(rows).each(function (i) {
                    var nameAttr = $(this).children().first().find("input").attr('name');
                    var index = nameAttr.substring(nameAttr.lastIndexOf('[') + 1, nameAttr.lastIndexOf(']'));
                    updateItemdByIndex(index, i, i + 1);
                });

                multiChoiceItems.sort(sortItems);
            },
            moveDisplayOrderDown = function (itemFromTable, event) {
                var row = $(event.currentTarget).parent().parent();
                row.insertAfter(row.next());

                var rows = $(event.currentTarget).parent().parent().parent().find("tr.sortable");

                $(rows).each(function (i) {
                    var nameAttr = $(this).children().first().find("input").attr('name');
                    var index = nameAttr.substring(nameAttr.lastIndexOf('[') + 1, nameAttr.lastIndexOf(']'));
                    updateItemdByIndex(index, i, i + 1);
                });

                multiChoiceItems.sort(sortItems);
            },
            removeMultiChoiceItem = function (item, event) {
                multiChoiceItems.remove(function (p) {
                    if (p.displayOrder() == item.displayOrder()) {
                        numberOfItemsToLoad--;
                        return true;
                    }
                    return false;
                });
                reOrderItems(item.index());
                $(event.currentTarget).parent().parent().remove();
            },
            updateItemdByIndex = function (index, newIndex, newDisplayOrder) {
                for (var i = 0; i < multiChoiceItems().length; i++) {
                    if (multiChoiceItems()[i].index() == index) {
                        multiChoiceItems()[i].index(newIndex);
                        multiChoiceItems()[i].displayOrder(newDisplayOrder);
                        return;
                    }
                }
            },
            reOrderItems = function (indexRemoved) {
                for (var i = indexRemoved; i < multiChoiceItems().length; i++) {
                    var currentItem = multiChoiceItems()[i];
                    currentItem.index(indexRemoved);
                    currentItem.displayOrder(indexRemoved + 1);
                    indexRemoved++;
                }
                currentIndex--;
                currentMaxDisplayOrder--;
            },
            sortItems = function (item1, item2) {
                return item1.displayOrder < item2.displayOrder;
            },
            loadItems = function (jsonAsString) {
                if (jsonAsString != '') {
                    var json = $.parseJSON(jsonAsString);
                    numberOfItemsToLoad = json.length;

                    for (var prop in json) {
                        if (!json.hasOwnProperty(prop)) {
                            continue;
                        }
                        var item = new multiChoiceItem(json[prop]["MultiChoiceName"], json[prop]["MultiChoiceValue"], json[prop]["MultiChoiceDisplayOrder"], currentIndex++);
                        currentMaxDisplayOrder++;
                        multiChoiceItems.push(item);
                    }
                }
            },
            reSortItems = function (event, ui) {
                var oldArray = multiChoiceItems();
                var oldIndex = parseInt(ui.item.find("td input:hidden").val()) - 1;

                oldArray[oldIndex].displayOrder(ui.item.index());
                oldArray[oldIndex].index(ui.item.index() - 1);

                var newIndex = ui.item.index() - 1;

                var item = oldArray.splice(oldIndex, 1)[0];
                oldArray.splice(newIndex, 0, item);

                var indexForReorder = newIndex > oldIndex ? oldIndex : newIndex;

                for (var i = indexForReorder; i < oldArray.length; i++) {
                    var currentItem = oldArray[i];
                    currentItem.index(indexForReorder);
                    currentItem.displayOrder(indexForReorder + 1);
                    indexForReorder++;
                }
            },
            subscribeToAttributeSorting = function () {
                multiChoiceItems.subscribe(function (value) {
                    for (var i = 0; i < multiChoiceItems().length; i++) {
                        var currentItem = multiChoiceItems()[i];
                        currentItem.index(i);
                    }
                });
            },
            subscribeToResponseMethodIdChange = function (id) {
                responseMethodId.subscribe(function (value) {
                    if (value == id) {
                        $(".responseMethodMultiChoiceEditor").removeClass("hidden");
                    } else {
                        $(".responseMethodMultiChoiceEditor").addClass("hidden");
                    }
                });
            },
            isLastItem = function (index) {
                return index == (numberOfItemsToLoad - 1);
            };
        return {
            multiChoiceItems: multiChoiceItems,
            newName: newName,
            newValue: newValue,
            addMultiChoiceItem: addMultiChoiceItem,
            removeMultiChoiceItem: removeMultiChoiceItem,
            reOrderItems: reOrderItems,
            loadItems: loadItems,
            responseMethodId: responseMethodId,
            subscribeToResponseMethodIdChange: subscribeToResponseMethodIdChange,
            reSortItems: reSortItems,
            moveDisplayOrderDown: moveDisplayOrderDown,
            moveDisplayOrderUp: moveDisplayOrderUp,
            currentIndex: currentIndex,
            isLastItem: isLastItem,
            subscribeToAttributeSorting: subscribeToAttributeSorting
        };
    } (),
    loadAttributesAutocomplete = function (eventSelector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(eventSelector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/attributes/autocomplete", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (event, ui) {
                $(eventSelector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(eventSelector).find(selectorFind).val(ui.item.Name);
                $(eventSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(eventSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    initCollapsible = function () {
        $(function () {

            $('#attributes-Selector').on('click', '.collapsible-button', function () {
                var self = $(this);
                var text;
                if (self.hasClass('expanded')) {
                    self.parent().next(".collapsible").hide();
                    text = self.attr("data-collapsed-text");
                    self.removeClass('expanded');
                    self.addClass('collapsed');
                } else {
                    self.parent().next(".collapsible").show();
                    text = self.attr("data-expanded-text");
                    self.addClass('expanded');
                    self.removeClass('collapsed');
                }
                self.html(text);
                return false;
            }).next().hide();
        });
    };
    return {
        loadAttributesAutocomplete: loadAttributesAutocomplete,
        createEditViewModel: createEditViewModel,
        multiChoiceItem: multiChoiceItem,
        initCollapsible: initCollapsible
    };
} ();