wdot.Utils = function () {
    var setCookie = function(c_name, value, exdays, exhours) {
            var exdate = new Date();
            if (exdays != undefined && exdays != null) {
                exdate.setDate(exdate.getDate() + exdays);
            }
            if (exhours != undefined && exhours != null) {
                exdate.setHours(exdate.getHours() + exhours);
            }
            var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = c_name + "=" + c_value;
        },
        deleteCookie = function(c_name) {
            var date = new Date();
            date.setDate(date.getDate() - 1);
            setCookie(c_name, '', date);
        },
        getCookieValue = function(c_name) {
            var i, x, y, ARRcookies = document.cookie.split(";");
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                if (x == c_name) {
                    return unescape(y);
                }
            }
            return '';
        },
        htmlDecode = function(text) {
            return $("<div/>").html(text).text();
        },
        htmlEncode = function(text) {
            return $("<div/>").text(text).html();
        },
        resetPlaceHolders = function() {
            $('[placeholder]').each(function() {
                var input = $(this);
                if (input.val() == input.attr('placeholder')) {
                    input.val('');
                }
            });
        },
        serializeObject = function(selector) {
            var o = {};
            var a = selector.serializeArray();
            $.each(a, function() {
                if (o[this.name] !== undefined) {
                    if (!o[this.name].push) {
                        o[this.name] = [o[this.name]];
                    }
                    o[this.name].push(this.value || '');
                } else {
                    o[this.name] = this.value || '';
                }
            });
            return o;
        },
        getValueFromQueryStringByKey = function(key, queryString, ignoreCase) {
            var value = '';
            if (ignoreCase)
                key = key.toLowerCase();

            if (window.location.search != '' && queryString == '') {
                queryString = window.location.search;
            }
            if (typeof queryString === "string" && queryString != '') {
                $.each(queryString.split('&'), function(item) {
                    var splitItem = this.split('=');
                    if (splitItem[0] == key) {
                        value = splitItem[1];
                    }
                });
            }
            return value;
        },
        buildToggles = function() {
            //Adding Toggle buttons to containers with toggle class
            $('.toggle > .catNav').prepend('<span class="expdBtn sprite sprite-expand"></span>');
            $('.toggle > header').prepend('<span class="expdBtn sprite sprite-expand"></span>');
            $('#category-list > h3').addClass('toggle');
            $('#category-list > h3 > a').addClass('toggleCat');
            $('#category-list > h3').prepend('<span class="expdBtn sprite sprite-expand"></span>');
            $('#category-list > ul').addClass('toggle-control');

            $(".toggle span.expdBtn").toggle(
                function() { // START FIRST CLICK FUNCTION
                    $(this).parent('*').next('*').slideDown();
                    if ($(this).hasClass('sprite-expand')) {
                        $(this).removeClass('sprite-expand').addClass('sprite-collapse');
                    }
                }, // END FIRST CLICK FUNCTION
                function() { // START FIRST CLICK FUNCTION
                    $(this).parent('*').next('*').slideUp();
                    if ($(this).hasClass('sprite-collapse')) {
                        $(this).removeClass('sprite-collapse').addClass('sprite-expand');
                    }
                } // END SECOND CLICK FUNCTION
            ); // END TOGGLE FUNCTION  

            $(".toggleCat").click(function(event) {
                var toggleCat = true;

                if ($(event.target).is("[type=radio]") || $(event.target).is("[type=checkbox]")) {
                    if ($(this).siblings("span.expdBtn").hasClass("sprite-collapse")) {
                        toggleCat = false;
                    }
                }

                if (toggleCat) {
                    $(this).siblings("span.expdBtn").click();
                }
            });
        },
        buildToggleForSingleElement = function (element) {
            $(element).prepend('<span class="expdBtn sprite sprite-collapse"></span>');

            var expandSpan = $(element).find('span.expdBtn');
            $(expandSpan).parent('*').next('*').slideDown();

            $(expandSpan).toggle(
                function () { // START FIRST CLICK FUNCTION
                    $(this).parent('*').next('*').slideUp();
                    if ($(this).hasClass('sprite-collapse')) {
                        $(this).removeClass('sprite-collapse').addClass('sprite-expand');
                    }
                }, // END SECOND CLICK FUNCTION
                function () { // START FIRST CLICK FUNCTION
                    $(this).parent('*').next('*').slideDown();
                    if ($(this).hasClass('sprite-expand')) {
                        $(this).removeClass('sprite-expand').addClass('sprite-collapse');
                    }
                } // END FIRST CLICK FUNCTION
                
            ); // END TOGGLE FUNCTION  
        },
        getFunctionInWindowOpenerFromString = function(functionString) {
            try {
                var parts = functionString.split(".");
                return _getCurrentNameSpaceLevel(parts, window.opener);
            } catch (e) {
                return undefined;
            }
        },
        _getCurrentNameSpaceLevel = function(parts, object) {
            if (parts.length > 0) {
                object = _getCurrentNameSpaceLevel(parts.slice(1), object[parts[0]]);
            }
            return object;
        },
        addCustomError = function(formSelector, errorMessageHolderSelector, message) {
            var form = $(formSelector);
            form.find(errorMessageHolderSelector).val("");
            form.find(errorMessageHolderSelector).attr("data-val-required", message).updateValidation();
            form.valid();
        },
        getUrlParameter = function(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };
        (function ($) {
            $.fn.updateValidation = function () {
                var $this = $(this);
                var form = $this.closest("form")
            .removeData("validator")
            .removeData("unobtrusiveValidation");

                $.validator.unobtrusive.parse(form);

                return $this;
            };
            $.fn.scrollTo = function(target, options, callback) {
                if (typeof options == 'function' && arguments.length == 2) {
                    callback = options;
                    options = target;
                }
                var settings = $.extend({
                    scrollTarget: target,
                    offsetTop: 50,
                    duration: 500,
                    easing: 'swing'
                }, options);
                return this.each(function() {
                    var scrollPane = $(this);
                    var scrollTarget = (typeof settings.scrollTarget == "number") ? settings.scrollTarget : $(settings.scrollTarget);
                    var scrollY = (typeof scrollTarget == "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
                    scrollPane.animate({ scrollTop: scrollY }, parseInt(settings.duration), settings.easing, function() {
                        if (typeof callback == 'function') {
                            callback.call(this);
                        }
                    });
                });
            };
        })(jQuery);
    return {
        SetCookie: setCookie,
        DeleteCookie: deleteCookie,
        HtmlDecode: htmlDecode,
        HtmlEncode: htmlEncode,
        ResetPlaceHolders: resetPlaceHolders,
        SerializeObject: serializeObject,
        GetCookieValue: getCookieValue,
        GetValueFromQueryStringByKey: getValueFromQueryStringByKey,
        BuildToggles: buildToggles,
        BuildToggleForSingleElement: buildToggleForSingleElement,
        GetFunctionInWindowOpenerFromString: getFunctionInWindowOpenerFromString,
        AddCustomError: addCustomError,
        GetUrlParameter: getUrlParameter,
        DeviceType: { 'Desktop': 0, 'Tablet': 1, 'Mobile': 2 }
    };
} ();