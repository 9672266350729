var wdot = wdot || {};
wdot.Place = function () {
    var listingPageViewModel = function () {
        var selectedType = ko.observable(),
        typeOptions = ko.observableArray();
        selectedType.subscribe(function () {
            var href = $("#addItemButton").attr("href");
            var lastIndex = href.lastIndexOf('/');
            href = href.substr(0, lastIndex + 1);
            $("#addItemButton").attr("href", href + selectedType());
        });
        var generateListingFilterJSON = function () {
            var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");
            var categories = $("select[name='SelectedCategories'] :selected");
            var filterKeyword = $("input[name='filterKeyword']").val();
            var id = $("input[name='Id']").val();
            var name = $("input[name='Name']").val();
            var zipCode = $("input[name='ZipCode']").val();
            var cities = $("input[name*='Cities.SelectedItems']");
            var contentTypeId = $("#CurrentPlaceType_Id").val();
            var queryString = '';

            if (checkedStatuses.length > 0) {
                var i = 0;
                var selectedVersionStatuses = {};
                ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;

            }
            else {
                wdot.Paging.data.SelectedVersionStatuses = {};
            }
            if (cities.length > 0) {
                var i = 0;
                var selectedCities = {};
                ko.utils.arrayForEach(cities, function (t) { selectedCities[i] = $(t).val(); queryString += '&Cities.SelectedItems[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data['Cities.SelectedItems'] = selectedCities;
            }
            else {
                wdot.Paging.data['Cities.SelectedItems'] = {};
            }


            if (categories.length > 0) {
                var i = 0;
                var selectedCategories = {};
                ko.utils.arrayForEach(categories, function (t) { selectedCategories[i] = $(t).val(); queryString += '&SelectedCategories[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data.SelectedCategories = selectedCategories;
            }
            else {
                wdot.Paging.data.SelectedCategories = {};
            }
            //removeOfficeUseOnlyFilters
            for (var i in wdot.Paging.data) {
                if (new RegExp('ForOfficeUseOnlyModel*').test(i)) {
                    delete wdot.Paging.data[i];
                }
            }

            var attrs = {};
            $("[type='text'][name^='ForOfficeUseOnlyModel'],textarea[name^='ForOfficeUseOnlyModel'],[type='checkbox'][name^='ForOfficeUseOnlyModel']:checked,[type='hidden'][name^='ForOfficeUseOnlyModel']").each(function () {
                if (attrs[$(this).attr("name")] == undefined && $(this).attr("name").indexOf("AttributeName") == -1 && $(this).attr("name").indexOf("AttributeResponseMethod") == -1) {
                    attrs[$(this).attr("name")] = $(this).val();
                    queryString += '&' + $(this).attr("name") + '=' + $(this).val();
                }
            });

            $.extend(true, wdot.Paging.data, attrs);

            queryString += '&contentTypeId=' + contentTypeId;
            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&Id=' + id;
            queryString += '&Name=' + name;
            queryString += '&ZipCode=' + zipCode;
            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.Id = id;
            wdot.Paging.data.Name = name;
            wdot.Paging.data.ZipCode = zipCode;
            return queryString;
        },
        updatePlaceTypeTabCount = function () {
            var index = 0,
             tabs = $("#placeTypeList li a");
            ko.utils.arrayForEach(arguments, function (item) {
                var tab = tabs.get(index);
                var tabName = tab.text.split('(');
                $(tabs.get(index)).text(tabName[0] + '( ' + item + ' )');
                index++;
            });
        },
        resetListingFilter = function () {
            $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
            $("select[name='SelectedCategories'] :selected").attr("selected", false);
            $("input[name='filterKeyword']").val('');
            $("input[name='Id']").val('');
            $("input[name='Name']").val('');
            $("input[name='ZipCode']").val('');
            $("[name^='ForOfficeUseOnlyModel']:checked").attr("checked", false);
            $("[name^='ForOfficeUseOnlyModel']").val('');

            var cities = wdot.Listing.listingViewModel.Items.cities();

            for (var i = cities.length - 1; i >= 0; i--) {
                wdot.Listing.listingViewModel.Remove(cities[0], 'cities', $("#AllItemsList[data-uniquelistingkey='cities']").find("input[id*='viewAllItem_Name_']"));
            }
        };
        return {
            selectedType: selectedType,
            typeOptions: typeOptions,
            generateListingFilterJSON: generateListingFilterJSON,
            updatePlaceTypeTabCount: updatePlaceTypeTabCount,
            resetListingFilter: resetListingFilter
        };
    } (),
    verifyViewModel = function () {
        var verifyPlace = function () {
            if (title() != undefined) {
                var json = {};
                json.title = title();
                json.street = wdot.Place.createEditViewModel.address().street;
                json.city = wdot.Place.createEditViewModel.address().city;
                json.state = wdot.Place.createEditViewModel.address().state;
                json.zip = wdot.Place.createEditViewModel.address().zip;
                json.currentId = $('#Id').val();
                Wdot.ModalWindow.ajaxContentType = "application/json";
                Wdot.Ajax.prototype.AddAjaxLoader($("body"), true);
                Wdot.ModalWindow.loadAjaxPost(550, "auto", "/admin/places/verify", ko.utils.stringifyJson(json), function () {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($("body"), true);
                });
            }
        },
        isTitleVerifed = ko.observable(false),
        title = ko.observable();
        return {
            verifyPlace: verifyPlace,
            title: title,
            isTitleVerified: isTitleVerifed
        };
    } (),
    createEditViewModel = function () {
        var selectedCategories = ko.observableArray(),
            bingMap,
            throttledSelectedCategories = ko.computed(function () {
                return selectedCategories();
            }).extend({ throttle: 500 }),
            previousPrimaryCategory,
            geoCodedLat = ko.observable(),
            geoCodedLong = ko.observable(),
            geoCodedLatLong = ko.computed(function () {
                return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
            }),
            street = ko.observable(),
            city = ko.observable(),
            state = ko.observable(),
            zip = ko.observable(),
            address = ko.computed(function () {
                return { street: street(), city: city(), state: state(), zip: zip() };
            }),
            addressAsString = ko.computed(function () {
                return street() + " " + city() + ", " + state() + " " + zip();
            }),
            geoCodeCallback = function (data) {
                var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                geoCodedLat(latitutde);
                geoCodedLong(longitutde);

                Wdot.BaseSelectedItem.prototype.getUpdatedNearbyLocations(latitutde, longitutde);
            },
            init = function (map) {
                this.bingMap = map;
                wdot.Attribute.initCollapsible();
                var initialCategories = [];
                $("[name='CategoryListingModel.PrimaryCategory']:checked,[name='CategoryListingModel.SelectedCategories']:checked").each(function () {
                    if ($(this).val() != 0)
                        initialCategories.push($(this).val());
                });
                if (initialCategories.length > 0)
                    selectedCategories(initialCategories);
                $("[name='CategoryListingModel.PrimaryCategory'],[name='CategoryListingModel.SelectedCategories']").change(function (event) {
                    selectedCategories($("[name='CategoryListingModel.PrimaryCategory']:checked,[name='CategoryListingModel.SelectedCategories']:checked").map(function (i, item) { return $(item).val(); }));
                });

                wdot.Category.updateAttributesMethod = function () {
                    selectedCategories($("[name='CategoryListingModel.PrimaryCategory']:checked,[name='CategoryListingModel.SelectedCategories']:checked").map(function (i, item) { return $(item).val(); }));
                };
                wdot.Category.buildCategoryList();
            },
            initobservables = function (titleParam, streetParam, cityParam, stateParam, zipParam, latParam, longParam, cityIdParam) {
                wdot.Place.verifyViewModel.title(wdot.Utils.HtmlDecode(titleParam));
                street(streetParam);
                state(stateParam);
                city(cityParam);
                zip(zipParam);
                $("#City").attr("data-itemid", cityIdParam);
                wdot.City.lastSelectedCity = cityParam;
                if (latParam != undefined && latParam != null && longParam != undefined && longParam != null && longParam != '' && latParam != '') {
                    geoCodedLat(latParam);
                    geoCodedLong(longParam);
                }
                if (selectedCategories().length == 0)
                    selectedCategories.push(0);

                isPageLoad = false;
            },
            geoCodeAddress = function () {
                this.bingMap.geoCodeAddress(this.address(), 'wdot.Place.createEditViewModel.geoCodeCallback');
            },
            isPageLoad = true,
            moveToMap = function () {
                if (!isMapVisible() && !isPageLoad) {
                    var target = $("#latLongMap").parent().parent();
                    if (target.length) {
                        var top = target.offset().top - 100;
                        $("html,body").animate({ scrollTop: top }, 1000);
                    }
                }
            },
            isMapVisible = function () {
                var docViewTop = $(window).scrollTop();
                var docViewBottom = docViewTop + $(window).height();

                var elemTop = $("#latLongMap .MicrosoftMap").offset().top;
                var elemBottom = elemTop + $("#latLongMap .MicrosoftMap").height();

                return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
            };
        throttledSelectedCategories.subscribe(function (value) {
            wdot.Category.getAttributesSelector(value, "attributes-Selector", "Place", $("#Id").val());
        });
        return {
            geoCodedLat: geoCodedLat,
            geoCodedLong: geoCodedLong,
            street: street,
            city: city,
            state: state,
            zip: zip,
            address: address,
            addressAsString: addressAsString,
            geoCodedLatLong: geoCodedLatLong,
            geoCodeCallback: geoCodeCallback,
            geoCodeAddress: geoCodeAddress,
            initObservables: initobservables,
            init: init,
            moveToMap: moveToMap
        };
    } (),
    loadPlaceAutocomplete = function (placeSelector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(placeSelector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/places/autocomplete", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (event, ui) {
                $(placeSelector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(placeSelector).find(selectorFind).val(ui.item.Name);
                $(placeSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(placeSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    loadTourismOrganizationsAutocomplete = function (placeSelector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(placeSelector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/places/autocompletetourismorganizations", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (event, ui) {
                $(placeSelector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(placeSelector).find(selectorFind).val(ui.item.Name);
                $(placeSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(placeSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + "</a>")
                .appendTo(ul);
        };
    };

    return {
        loadPlaceAutocomplete: loadPlaceAutocomplete,
        loadTourismOrganizationsAutocomplete: loadTourismOrganizationsAutocomplete,
        listingPageViewModel: listingPageViewModel,
        createEditViewModel: createEditViewModel,
        verifyViewModel: verifyViewModel
    };
} ();