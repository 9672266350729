var wdot = wdot || {};
wdot.Paging = function () {
	var data = {},
    bindAdminDashboardPagingSortingEvents = function (container, filterJSONMethod) {
        $('body').off('click', container.selector + ' a.pagingbtn');
        $('body').off('click', container.selector + ' .sortableColumn');
        $('body').on('click', container.selector + ' a.pagingbtn', function () {
            var btn = $(this);
            if (btn.attr('data-pagenumber') != "") {
                container.find('#CurrentPage').val(btn.attr('data-pagenumber'));
                //$(this).closest("form").submit();
                var baseDataUrl = container.find('#BaseDataUrl').val();
                var content = container.find('#listingContent');
                var newPageNumber = container.find('#CurrentPage').val();
                var sortField = container.find('#SortField').val();
                var sortDirection = container.find('#SortDirection').val();

                if (filterJSONMethod != undefined && filterJSONMethod != "")
                    filterJSONMethod();

                wdot.Paging.data.currentPage = newPageNumber;
                wdot.Paging.data.sortField = sortField;
                wdot.Paging.data.sortDirection = sortDirection;
                wdot.Paging.processPaging(baseDataUrl, content);
            }

            return false;
        });

        $('body').on('click', container.selector + ' .sortableColumn', function () {
            var col = $(this);

            if (col.attr('data-pagenumber') != "") {
                container.find('#SortField').val(col.attr('data-sortname'));

                if (container.find('#SortDirection').val() == '' || container.find('#SortDirection').val() == 'asc') {
                    container.find('#SortDirection').val("desc");
                } else {
                    container.find('#SortDirection').val("asc");
                }

                //$(this).closest("form").submit();
                var baseDataUrl = container.find('#BaseDataUrl').val();
                var content = container.find('#listingContent');
                var newPageNumber = container.find('#CurrentPage').val();
                var sortField = container.find('#SortField').val();
                var sortDirection = container.find('#SortDirection').val();

                if (filterJSONMethod != undefined && filterJSONMethod != "")
                    filterJSONMethod();

                wdot.Paging.data.currentPage = 1;
                wdot.Paging.data.sortField = sortField;
                wdot.Paging.data.sortDirection = sortDirection;
                wdot.Paging.processPaging(baseDataUrl, content);
            }
            return false;
        });
    },
    processPaging = function (baseDataUrl, content) {
        content.html('<div class="ajaxLoader"><img src="/images/ajax-loader-big.gif" alt = "loading..." /></div>');       
        Wdot.Ajax.prototype.ClearQueueAndPushNewCall($.ajax({
            type: "POST",
            url: baseDataUrl,
            data: data
        }).done(function (d) {
            content.html(d);
        }));
    },
    resetPaging = function () {
        $('#CurrentPage').val(1);
        $('#SortField').val('Id');
        $('#SortDirection').val('asc');
    };
    return {
        data: data,
        processPaging: processPaging,
        resetPaging: resetPaging,
        bindAdminDashboardPagingSortingEvents: bindAdminDashboardPagingSortingEvents
    };
} ();