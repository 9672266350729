var wdot = wdot || {};
wdot.Category = function () {
    var includeAttributes = false,
        updateAttributesMethod,
        onPrimaryCategoryChange = function (isPrimaryRequired, id) {
            if (isPrimaryRequired == undefined) {
                isPrimaryRequired = false;
            }
            $("[name='CategoryListingModel.SelectedCategories']").removeAttr("disabled");
            $("input[name='CategoryListingModel.PrimaryCategory']:checked").not("[value='" + id + "']").attr('checked', false);
            var primaryCategory = $("#Category_" + id);

            if (primaryCategory.is(":checked")) {
                primaryCategory.removeAttr("checked");
            }
            if ($("input[name='CategoryListingModel.PrimaryCategory'][value='" + id + "']").is(":checked")) {
                primaryCategory.attr("disabled", "disabled");
            }
            if (wdot.Category.includeAttributes) {
                Wdot.Ajax.prototype.Post('/Admin/Attributes/AttributesForCategoriesJSON/' + id, null, function (data, event) {
                    wdot.Listing.listingViewModel.LoadInheritedItems(data, 'attributes', $("#attributesListing"));
                });
            }
            buildCategoryList();
        },
        onOtherCategoryChange = function () {
            buildCategoryList();
        },
        buildCategoryList = function () {
            var selectedCategories = $("[id^='PrimaryCategory_']:checked,[id^='Category_']:checked");
            var categoryList = $(".categoryList");
            categoryList.html('');
            selectedCategories.each(function () {
                categoryList.append("<li>" + $(this).attr('data-displayname') + "<a href='javascript:void(0)' class='removeCategory sprite sprite-remove' data-id='" + $(this).attr("id").split('_')[1] + "'></a></li>");
            });

            $(".categoryList .removeCategory").click(function () {
                var self = this;
                $("[id^='PrimaryCategory_']:checked,[id^='Category_']:checked").filter(function () { return $(this).val() == $(self).attr("data-id"); }).attr("checked", false);

                if (wdot.Category.updateAttributesMethod != undefined && $.isFunction(wdot.Category.updateAttributesMethod))
                    wdot.Category.updateAttributesMethod();
                
                $(this).parent().remove();
            });
        },
        bindCategoryCheckboxClickForAccordion = function () {
            $("#category-accordion input[type='checkbox']").bind('click', function (e) {
                e.stopPropagation();
            });
        },
        categoryListViewModel = function () {
            var activateCategory = function (id) {
                Wdot.Ajax.prototype.Post('/Admin/Categories/ActivateCategory/' + id, null, function (d) {
                    if (d.Success) {
                        $("#deactivateCategory_" + id).removeClass('hidden');
                        $("#activateCategory_" + id).addClass('hidden');
                    }
                });
            },
            deactivateCategory = function (id) {
                Wdot.Ajax.prototype.Post('/Admin/Categories/DeactivateCategory/' + id, null, function (d) {
                    if (d.Success) {
                        $("#deactivateCategory_" + id).addClass('hidden');
                        $("#activateCategory_" + id).removeClass('hidden');
                    }
                });
            };
            return {
                ActivateCategory: activateCategory,
                DeactivateCategory: deactivateCategory
            };
        } (),
        getAttributesSelector = function (categoryIds, divId, type, id) {
            var data = {};
            var saveValues = {};
            if (categoryIds != null && categoryIds != undefined) {
                $(categoryIds).each(function (index, item) {
                    data["selectedCategoryIds[" + index + "]"] = item;
                });
                data.type = type;
                data.id = id;
                ko.utils.arrayForEach($("#attributes-Selector").find(".attribute-item"), function (item) {
                    var type = parseInt($(item).find("#attribute_AttributeResponseMethod_Id").val());
                    switch (type) {
                        case 1:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = { 'type': 1, 'isChecked': $(item).find(".editor-field").find("input[type='checkbox']").is(":checked") };
                            break;
                        case 2:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = { 'type': 2, 'text': $(item).find(".editor-field").find("input[type='text']").val() };
                            break;
                        case 3:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = { 'type': 3, 'text': $(item).find(".editor-field").find("textarea").val() };
                            break;
                        case 4:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = { 'type': 4, 'checkboxes': saveMultiChoiceValues(item) };
                            break;
                        case 5:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = { 'type': 5, 'text': $(item).find(".editor-field").find("textarea").tinymce().getContent() };
                            break;
                        default:
                            saveValues[$(item).find("#attribute_AttributeId").val()] = {};
                            break;
                    }

                });
                Wdot.Ajax.prototype.Post("/admin/attributes/getattributeslisting", data, function (d) {
                    var attributesDiv = $("#" + divId);
                    if (attributesDiv.html() != d) {
                        attributesDiv.empty();
                        attributesDiv.append(d);
                        ko.utils.arrayForEach($("#attributes-Selector").find(".attribute-item"), function (item) {
                            var type = parseInt($(item).find("#attribute_AttributeResponseMethod_Id").val());
                            var value = saveValues[$(item).find("#attribute_AttributeId").val()];
                            if (value != undefined && value != {}) {
                                switch (type) {
                                    case 1:
                                        if (value.isChecked) {
                                            $(item).find(".editor-field").find("input[type='checkbox']").attr("checked", "checked");
                                        }
                                        break;
                                    case 2:
                                        $(item).find(".editor-field").find("input[type='text']").val(value.text);
                                        break;
                                    case 3:
                                        $(item).find(".editor-field").find("textarea").val(value.text);
                                        break;
                                    case 4:
                                        loadMultiChoiceValues(item, value.checkboxes);
                                        break;
                                    case 5:
                                        $(item).find(".editor-field").find("textarea").tinymce().setContent(value.text);
                                        break;
                                }
                            }
                        });
                        saveValues = {};
                    }
                });
            }
        },
        saveMultiChoiceValues = function (item) {
            var values = {};
            ko.utils.arrayForEach($(item).find(".editor-field").find(".multi-choice-item"), function (item) {
                var cb = $(item).find("input[type='checkbox']");
                values[cb.val()] = cb.is(":checked");
            });
            return values;
        },
        loadMultiChoiceValues = function (item, values) {
            ko.utils.arrayForEach($(item).find(".editor-field").find(".multi-choice-item"), function (item) {
                var cb = $(item).find("input[type='checkbox']");
                if (values[cb.val()]) {
                    cb.attr("checked", "checked");
                }
            });
        },
        deleteConfirm = function (event) {
            event.stopImmediatePropagation();
            var id = $(event.currentTarget).attr("data-id");
            var dialog = ($('#ModalDialog').length > 0) ? $('#ModalDialog') : $('<div id="ModalDialog" style="display:hidden"></div>').appendTo('body');
            Wdot.Ajax.prototype.Get('/Admin/Categories/DeleteConfirm/' + id, null,
                function (responseText, textStatus, XMLHttpRequest) {
                    dialog.html(responseText);
                    dialog.dialog({
                        resizable: true,
                        bgiframe: true,
                        modal: true,
                        width: 400,
                        title: 'Confirm Delete',
                        buttons: { 'Yes': function () { $(event.currentTarget).closest("form").submit(); }, 'No': function () { $(this).dialog("close"); } }
                    });
                    $('.ui-button-text').each(function (i) {
                        $(this).html($(this).parent().attr('text'));
                    });
                });
            return false;
        };
    return {
        onPrimaryCategoryChange: onPrimaryCategoryChange,
        onOtherCategoryChange: onOtherCategoryChange,
        categoryListViewModel: categoryListViewModel,
        bindCategoryCheckboxClickForAccordion: bindCategoryCheckboxClickForAccordion,
        includeAttributes: includeAttributes,
        getAttributesSelector: getAttributesSelector,
        deleteConfirm: deleteConfirm,
        buildCategoryList: buildCategoryList,
        updateAttributesMethod: updateAttributesMethod
    };
} ();