var tw = tw || {};
var _log = false;
tw.AttributesFilter = function () {

	var AttributesFilters = [],
		resultsStr,
		alreadyInitialized = false;


	var init = function () {
		if (_log) console.log('tw.AttributesFilter.init()');

		if($('.js-attributes-modal').length <= 0) {
            if (_log) console.log('tw.AttributesFilter.no modal');
            return false;
        }

		if (alreadyInitialized) {
			if (_log) console.log('tw.AttributesFilter.alreadyInitialized');
			return false;
		}

		if(_log)console.log('tw.AttributesFilter.js-view-filters:', $('.js-view-filters').length);


		/**
		 * initialize buttons
		*/
		

		$(".js-directory").on("click", ".js-view-filters", function (e) {
			e.preventDefault();
			if(_log)console.log('tw.AttributesFilter js-view-filters');
			$('.js-attributes-modal').removeClass('hidden');
		});

		$(".js-directory").on("click", '.js-attributes-modal-close', function () {
			//if(_log)console.log('tw.AttributesFilter. js-attributes-modal-close');
			closeAttributesModal();
		});

		$(".js-directory").on("click", '.js-attributes-modal-submit', function () {
			if(_log)console.log('tw.AttributesFilter. js-attributes-modal-submit');
			submitAttributeFormValues();
		});

		alreadyInitialized = true;
	};




	/**
	 * submit attribute checkbox values
	 * update values, save as cookie, close modal, remove modal, submit main form
	 * logic in contentPages.js reads back in $.cookie('AttributesFilters') and adds to pagingAjaxData
	*/
	var submitAttributeFormValues = function () {
		if(_log)console.log('tw.AttributesFilter. submitAttributeFormValues');
		
		// $.cookie('AttributesFilters', JSON.stringify(AttributesFilters), { expires: 3 });

		$('.js-filter-form-submit').trigger( "click" );//main widget filter submit button

		setTimeout(() => {
			//alert('filters: ' + AttributesFilters.join(', '));
			closeAttributesModal();
			
		}, 300);
	}




	/**
	 * close accessibility modal
	*/
	var closeAttributesModal = function () {
		$('.js-attributes-modal').addClass('hidden');
		$('.js-attributes-modal').find('input[type="checkbox"]').prop('checked', false);
	}


	var clearAll = function () {
		if(_log)console.log('tw.Accessibility.clearAll')
		// $('.js-attributes-modal input[type="checkbox"]').prop("checked", false);
		// updateActiveButtons();
	}


	return {
		init: init,
		clearAll: clearAll
	}

}();


$(document).ready(function() {
	if(_log)console.log('tw.AttributesFilter.doc.ready()');
	tw.AttributesFilter.init();
	
});