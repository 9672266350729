wdot = wdot || {};
wdot.Search = function () {
    var map,
        loadMap = function (mapId, latitude, longitude) {
            if (latitude == undefined || latitude == null || longitude == undefined || longitude == null || longitude == 0 || latitude == 0) {
                latitude = 44.832017349893526;
                longitude = -89.84687250000002;
            }

            var center = new Microsoft.Maps.Location(latitude, longitude);
            map = new Microsoft.Maps.Map(document.getElementById(mapId), {
                enableSearchLogo: false,
                showMapTypeSelector: false,
                credentials: wdot.BingMaps.apiKey,
                zoom: 11,
                center: center,
                disableKeyboardInput: true
            });
            wdot.BingMaps.bindMethodToPreventPinterestPinningMapTiles(map);
        },
        clearMap = function () {
            map.entities.clear();
            wdot.Search.HasPin = false;
        },
        hasPin = false,
        addPin = function (latitude, longitude, text, html) {
            var offset = new Microsoft.Maps.Point(0, 5);
            var pinOptions = { visible: true, textOffset: offset, draggable: false };
            if (text != undefined && text != '') {
                pinOptions.text = text;
            } else if (html != undefined && html != '') {
                pinOptions.htmlContent = html;
            }

            var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(latitude, longitude), pinOptions);

            Microsoft.Maps.Events.addHandler(pin, 'click', function (event) {
                window.open($(event.target._htmlContent).attr("href"), "_blank");
            });

            map.entities.push(pin);
            map.setView({ center: new Microsoft.Maps.Location(latitude, longitude), zoom: 10 });
            wdot.Search.HasPin = true;
        },
        bestMapView = function (mapWidth, mapHeight, buffer) {
            var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002),
            zoomLevel = 0,
            maxLat = -85,
            minLat = 85,
            maxLon = -180,
            minLon = 180,
            length = map.entities.getLength();

            for (var i = 0; i < length; i++) {
                var location = map.entities.get(i)._location;
                if (location.latitude > maxLat)
                    maxLat = location.latitude;

                if (location.latitude < minLat)
                    minLat = location.latitude;

                if (location.longitude > maxLon)
                    maxLon = location.longitude;

                if (location.longitude < minLon)
                    minLon = location.longitude;
            }

            var zoom1 = 15, zoom2 = 15;
            if (length > 0) {
                center.latitude = (maxLat + minLat) / 2;
                center.longitude = (maxLon + minLon) / 2;

                if (maxLon != minLon && maxLat != minLat) {
                    zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
                    zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
                }
            } else {
                zoom1 = 7;
                zoom2 = 7;
            }
            zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;

            map.setView({ center: center, zoom: zoomLevel });
        };
    return {
        LoadMap: loadMap,
        HasPin: hasPin,
        AddPin: addPin,
        ClearMap: clearMap
    };
} ();