//var wdot = wdot || {};
$(function () {
    $(".rightCol form").submit(function () {
        var tab = $(".rightCol form .input-validation-error").closest(".ui-tabs-panel").get(0);
        if (tab != undefined) {
            $(".rightCol").tabs('option', 'active', tab.id);
        }

        if ($(".rightCol form .intColRght .input-validation-error") != undefined) {
            var expandBtn = $(".rightCol form .intColRght .input-validation-error").closest("aside.toggle").find(".expdBtn");
            if (expandBtn != undefined) {
                if (expandBtn.has("sprite-collapse")) {
                    expandBtn.click();
                } 
            }
        } 
    });
    $.ajaxSetup({
        timeout: 1600000,
        error: function (xhr, ajaxOptions, thrownError) { Wdot.Ajax.prototype.Error(xhr, ajaxOptions, thrownError); },
        beforeSend: function () {   Wdot.Ajax.prototype.BeforeSend(); },
        complete: function () { Wdot.Ajax.prototype.Complete(); }
    });
});