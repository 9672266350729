var wdot = wdot || {};
wdot.Album = function () {
    var popup = function () {
        var mediaFiles = [],
            addMediaFile = function (mediaFile) {
                if (mediaFiles.indexOf(mediaFile) < 0) {
                    mediaFiles.push(mediaFile);
                }
            },
            removeMediaFile = function (mediaFile) {
                var index = mediaFiles.indexOf(mediaFile);
                mediaFiles.splice(index, 1);
            },
            hasMediaFiles = function () {
                return mediaFiles.length > 0;
            },
            getMediaFiles = function () {
                return mediaFiles;
            };
        return {
            AddMediaFile: addMediaFile,
            RemoveMediaFile: removeMediaFile,
            HasMediaFiles: hasMediaFiles,
            GetMediaFiles: getMediaFiles
        };
    } (),
    addMediaFilesToAlbum = function (mediaFiles) {
        if (mediaFiles.length > 0) {
            $(mediaFiles).each(function (index, mediaFile) {
                wdot.Listing.listingViewModel.Add("mediaFiles", mediaFile.name, mediaFile.id, "", null, mediaFile);
            });
        }
    },
    listingPageViewModel = function () {
        var generateListingFilterJSON = function () {
            var filterKeyword = $("input[name='filterKeyword']").val(),
                selectedIsActiveOption = $("[name=SelectedIsActiveOption]").val();
            var queryString = '';
            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&SelectedIsActiveOption=' + selectedIsActiveOption;
            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.SelectedIsActiveOption = selectedIsActiveOption;
            return queryString;
        },
        resetListingFilter = function () {
            $("input[name='filterKeyword']").val('');
            $("[name=SelectedIsActiveOption]").val(1);
        };
        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    } ();

    return {
        AddMediaFilesToAlbum: addMediaFilesToAlbum,
        Popup: popup,
        ListingPageViewModel: listingPageViewModel
    };
} ();