var wdot = wdot || {};
wdot.DateRanges = function () {
    var repeatOptions = [{ repeatName: 'No Repeat', value: 1 }, { repeatName: 'Repeat Weekly', value: 2}],
    currentUniqueId = 1,
    dateRange = function (d1, d2, t, r, sun, mon, tue, wed, thu, fri, sat, i) {
        var date = new Date();
        var dateString = ''; //date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getUTCFullYear();
        if (d1 == undefined)
            d1 = dateString;
        if (d2 == undefined)
            d2 = dateString;
        if (r == undefined)
            r = repeatOptions[0].value;
        if (sun == undefined)
            sun = false;
        if (mon == undefined)
            mon = false;
        if (tue == undefined)
            tue = false;
        if (wed == undefined)
            wed = false;
        if (thu == undefined)
            thu = false;
        if (fri == undefined)
            fri = false;
        if (sat == undefined)
            sat = false;
        if (i == undefined)
            i = 0;
        if (t == undefined)
            t = '';

        var startDate = ko.observable(d1),
        endDate = ko.observable(d2),
        repeat = ko.observable(r),
        sunday = ko.observable(sun),
        monday = ko.observable(mon),
        tuesday = ko.observable(tue),
        wednesday = ko.observable(wed),
        thursday = ko.observable(thu),
        friday = ko.observable(fri),
        saturday = ko.observable(sat),
        times = ko.observable(t),
        isInEditMode = ko.observable(false),
        isWeeklyRepeat = ko.computed(function () {
            return repeat() == 2;
        }),
        dates = ko.computed(function () {
            if (startDate() == endDate())
                return "Date: " + startDate();

            return "Dates: " + startDate() + ' - ' + endDate();
        }).extend({ throttle: 100 }),
        timesDetails = ko.computed(function () {
            return "Times: " + times();
        }).extend({ throttle: 100 }),
        days = ko.computed(function () {
            if (repeat() == 1)
                return "";

            var repeatName = ko.utils.arrayFilter(repeatOptions, function (item) {
                return item.value == repeat();
            })[0].repeatName;
            var dayArray = new Array();
            if (sunday())
                dayArray.push("Sunday");
            if (monday())
                dayArray.push("Monday");
            if (tuesday())
                dayArray.push("Tuesday");
            if (wednesday())
                dayArray.push("Wednesday");
            if (thursday())
                dayArray.push("Thursday");
            if (friday())
                dayArray.push("Friday");
            if (saturday())
                dayArray.push("Saturday");
            return repeatName + " Every: " + dayArray.join(', ');
        }).extend({ throttle: 100 }),
        showEditor = ko.computed(function () {
            if (wdot.DateRanges.multiRangeViewModel.dateRanges().length <= 1)
                return true;

            return isInEditMode();
        }),
        id = i,
        uniqueId = currentUniqueId;
        currentUniqueId++;
        return {
            startDate: startDate,
            endDate: endDate,
            repeat: repeat,
            sunday: sunday,
            saturday: saturday,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            isWeeklyRepeat: isWeeklyRepeat,
            id: id,
            times: times,
            dates: dates,
            days: days,
            timesDetails: timesDetails,
            showEditor: showEditor,
            isInEditMode: isInEditMode,
            uniqueId: uniqueId
        };
    },
    multiRangeViewModel = function () {
        var dateRanges = ko.observableArray(),
        dateRangesJson = ko.computed(function () {
            return ko.toJSON(dateRanges);
        }),
        addDateRange = function () {
            ko.utils.arrayForEach(dateRanges(), function (i) {
                i.isInEditMode(false);
            });
            var dr = new dateRange();
            dr.isInEditMode(true);
            dateRanges.push(dr);
        },
        removeDateRange = function (r) {
            dateRanges.remove(r);
        },
        loadDateRanges = function (d) {
            if (d.length == 0) {
                var dr = new dateRange('', '');
                dr.isInEditMode(true);
                dateRanges.push(dr);
            }
            else {
                $(d).each(function () {
                    dateRanges.push(new dateRange(this.startDate, this.endDate, this.times, this.repeat, this.sunday, this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday, this.id));
                });
            }
        },
        edit = function (item) {
            ko.utils.arrayForEach(dateRanges(), function (i) {
                i.isInEditMode(false);
            });
            item.isInEditMode(true);
        },
        updateRepeat = function (id, repeat) {
            var dr = ko.utils.arrayFirst(dateRanges(), function (item) {
                return item.uniqueId == id;
            });
            dr.repeat(repeat);
            $(".dateRangeId[value='" + id + "']").parent().find(".customStyleSelectBoxInner").text($(".dateRangeId[value='" + id + "']").parent().find(".repeatSelect").find(':selected').text()).parent().addClass('changed');
            return true;
        };
        return {
            dateRanges: dateRanges,
            dateRangesJson: dateRangesJson,
            repeatOptions: repeatOptions,
            addDateRange: addDateRange,
            loadDateRanges: loadDateRanges,
            removeDateRange: removeDateRange,
            edit: edit,
            updateRepeat: updateRepeat
        };
    } ();
    return {
        multiRangeViewModel: multiRangeViewModel,
        dateRange: dateRange
    };
} ();