var wdot = wdot || {};
wdot.Permissions = function () {
    var onModuleSelectionChange = function () {
        var moduleId = $("#ModuleId").val();
        if (moduleId != undefined) {
            window.location = "/admin/permissions/index/" + moduleId;
        }
    },
            loadPermissionMembersAutocomplete = function (textboxSelector, selectorFind) {
                var cache = {},
                lastXhr = '',
                itemSelected = false;
                $(textboxSelector).find(selectorFind).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: ''}]);
                        Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        lastXhr = Wdot.Ajax.prototype.Get("/admin/permissions/permissionmembers", request, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        return false;
                    },
                    select: function (event, ui) {
                        itemSelected = true;
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        $(textboxSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                        $(textboxSelector).find(selectorFind).attr("data-type", ui.item.Type);
                        $(textboxSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + " (" + item.Type + ")</a>")
                        .appendTo(ul);
                };
            };
    return {
        onModuleSelectionChange: onModuleSelectionChange,
        loadPermissionMembersAutocomplete: loadPermissionMembersAutocomplete
    };
} ();