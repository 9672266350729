var wdot = wdot || {};
wdot.SelectedBlogArticles = function () {
    var contentPageBlogArticlesViewModel = function () {
        var blogArticles = ko.observableArray(),
            index = 0,
            selectedBlogArticleJsonString = ko.observable(),

            isBlogArticleAlreadyAdded = function(blogArticle) {
                for (var i = 0; i < blogArticles().length; i++) {
                    if (blogArticles()[i].Name == blogArticle) {
                        return true;
                    }
                }
                return false;
            },
            addBlogArticle = function () {
                var value = $("#blogarticle-value").val();
                var data = $("#blogarticle-value").attr("data-itemid");
 
                if (value != undefined && value != '' && !isBlogArticleAlreadyAdded(value)) {
                    var blogArticleFromTextbox = new wdot.SelectedBlogArticleItem.constructor();
                    blogArticleFromTextbox.Name = value;
                    blogArticleFromTextbox.Id = data;
                    blogArticleFromTextbox.Index = index++;
                    blogArticles.push(blogArticleFromTextbox);
                }
                $("#blogarticle-value").val('');
                $("#blogarticle-value").attr("data-itemid", 0);

                $('#SelectedBlogArticlesJson').val(ko.toJSON(blogArticles()));
            },
            removeBlogArticle = function(blogArticleToRemove) {
                //var indexRemoved = blogArticleToRemove.Index;
                blogArticles.remove(blogArticleToRemove);
                // for (var i = indexRemoved; i < tags().length; i++) {
                //     var currentTag = blogArticles()[i];
                //     currentTag.Index = indexRemoved++;
                // }
                //index--;
                $('#SelectedBlogArticlesJson').val(ko.toJSON(blogArticles()));
            },
            loadBlogArticles = function (blogArticlesJsonAsString) {
                var blogArticlesJson = $.parseJSON(blogArticlesJsonAsString);
                for (var prop in blogArticlesJson) {
                    if (!blogArticlesJson.hasOwnProperty(prop)) {
                        continue;
                    }
                    var value = blogArticlesJson[prop]["Name"];
                    if (!isBlogArticleAlreadyAdded(value)) {
                        var blogArticleFromTextbox = new wdot.SelectedBlogArticleItem.constructor();
                        blogArticleFromTextbox.Name = value;
                        blogArticleFromTextbox.Id = blogArticlesJson[prop]["Id"];
                        blogArticleFromTextbox.Index = blogArticlesJson[prop]["Index"];
                        blogArticles.push(blogArticleFromTextbox);
                    }
                }
            };

        return {
            AddBlogArticle: addBlogArticle,
            RemoveBlogArticle: removeBlogArticle,
            LoadBlogArticles: loadBlogArticles,
            SelectedBlogArticleJsonString: selectedBlogArticleJsonString,
            BlogArticles: blogArticles
        };
    }();
    return {
        ContentPageBlogArticlesViewModel: contentPageBlogArticlesViewModel
    };
}();