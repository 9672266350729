var wdot = wdot || {};
wdot.MediaGallery = wdot.MediaGallery || {};
wdot.MediaGallery.OtherMedia = function (n, f, u) {
    return {
        Name: n,
        FileLocation: f,
        Url: u
    };
};
wdot.MediaGallery.OtherMediaLists = {};
wdot.MediaGallery.OtherMediaService = function () {
    var addOtherMediaList = function (name) {
        if (typeof wdot.MediaGallery.OtherMediaLists[name] !== "undefined") {
            throw "There is already a list called " + name + ".";
        }
        wdot.MediaGallery.OtherMediaLists[name] = { MediaFiles: ko.observableArray(), ShowLeftArrow: ko.observable(), ShowRightArrow: ko.observable(), CurrentPage: ko.observable(), TotalNumberOfItems: 0, NumItemsPerPage: 0 };
        wdot.MediaGallery.OtherMediaLists[name].MoveLeft = function () {
            var currentPage = wdot.MediaGallery.OtherMediaLists[name].CurrentPage();
            if (currentPage > 1) {
                wdot.MediaGallery.OtherMediaLists[name].CurrentPage(--currentPage);
            }
        };
        wdot.MediaGallery.OtherMediaLists[name].MoveRight = function () {
            var list = wdot.MediaGallery.OtherMediaLists[name],
                currentPage = list.CurrentPage(),
                lastPage = Math.ceil(list.TotalNumberOfItems / list.NumItemsPerPage);

            wdot.MediaGallery.OtherMediaLists[name].CurrentPage();
            if (currentPage < lastPage) {
                wdot.MediaGallery.OtherMediaLists[name].CurrentPage(++currentPage);
            }
        };
    },
        createOtherMediaList = function (name, data) {
            if (typeof wdot.MediaGallery.OtherMediaLists[name] === "undefined") {
                addOtherMediaList(name);
            }
            var list = wdot.MediaGallery.OtherMediaLists[name];

            list.ShowLeftArrow(!!data.ShowLeftArrow);
            list.ShowRightArrow(!!data.ShowRightArrow);
            list.CurrentPage(data.CurrentPage);
            list.TotalNumberOfItems = data.TotalNumberOfItems;
            list.NumItemsPerPage = data.NumItemsPerPage;
            list.CurrentPage.subscribe(function (pageNum) {
                list.ShowLeftArrow(list.TotalNumberOfItems > list.NumItemsPerPage && pageNum > 1);
                list.ShowRightArrow(list.TotalNumberOfItems > list.NumItemsPerPage && (list.CurrentPage * list.NumItemsPerPage) < list.TotalNumberOfItems);
            });
        };

    return {
        AddOtherMediaList: addOtherMediaList,
        CreateOtherMediaList: createOtherMediaList
    };
} ();