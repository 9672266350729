var wdot = wdot || {};
wdot.Debug = function () {
    var debugMode = false,
    addBreakPoint = function () {
        if (debugMode) {
            debugger;
        }
    };
    return {
        AddBreakPoint: addBreakPoint,
        DebugMode: debugMode
    };
} ();