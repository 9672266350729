var wdot = wdot || {};
wdot.MediaUpload = function () {
    var mediaFiles = ko.observableArray([]),
            setMediaFiles = function (arrMediaFiles) {
                if (arrMediaFiles == null)
                    arrMediaFiles = [];

                mediaFiles(arrMediaFiles);
            },
            numberOfFiles = ko.computed(function () {
                if (mediaFiles != undefined && mediaFiles() != undefined)
                    return mediaFiles().length;

                return 0;
            }),
            addMediaFile = function (id, fileName, mediaFile) {
                debugger;
                var file = ko.observable(mediaFile);
                file().DisplayName = fileName;
                file().Caption = "";
                file().PhotoCredit = "";
                file().Id = 0;
                mediaFiles.unshift(file());
                $('#MediaUpload_UploadedItemsJson').val(JSON.stringify(getAllMediaFiles()()));
            },
            removeMediaFile = function () {
                var mediaFile = this;
                mediaFiles.remove(mediaFile);
                $('#upload-message').html('');
                if (numberOfFiles == 0) {
                    $('#MediaUpload_UploadedItemsJson').val('');
                } else {
                    $('#MediaUpload_UploadedItemsJson').val(JSON.stringify(getAllMediaFiles()()));
                }

                //TODO: delete file from file system through ajax call?
                //console.log(mediaFile.fileLocation);
            },
            cleanUrl = function (url) {
                if (url != null && url != '') {
                    return url.replace('~', '');
                } else {
                    return '';
                }
            },
            getAllMediaFiles = function () {
                return mediaFiles;
            },
            popMediaFiles = function () {
                mediaFiles.pop();
            }, updateMediaFiles = function () {
                //alert('updated!');
                $('#MediaUpload_UploadedItemsJson').val(JSON.stringify(getAllMediaFiles()()));
            };
    mediaFiles.subscribe(function () {
        wdot.MediaUpload.UpdateMediaFiles();
    });
    return {
        MediaFiles: mediaFiles,
        NumberOfFiles: numberOfFiles,
        SetMediaFiles: setMediaFiles,
        AddMediaFile: addMediaFile,
        RemoveMediaFile: removeMediaFile,
        CleanUrl: cleanUrl,
        GetAllMediaFiles: getAllMediaFiles,
        PopMediaFiles: popMediaFiles,
        UpdateMediaFiles: updateMediaFiles
    };
} ();