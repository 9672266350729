$(function () {
    var $dialog = $('.js-dialog'),
	    $prompt = $('.js-dialog-prompt'),
        $input = $('.js-dialog-input').add('.js-dialog-button'),
        $confirm = $('.js-dialog-confirm'),
        $close = $('.js-dialog-close');
	
	$input.hide();
	$prompt.click(function () {
		$prompt.hide();
		$input.show();
	});

	$confirm.click(function () {
		$input.hide();
		$prompt.show();
	})

	$close.click(function () {
	    $dialog.hide();
	});
});