var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var BlogArticleMediaItem = (function (_super) {
        __extends(BlogArticleMediaItem, _super);
        function BlogArticleMediaItem(itemTypeId) {
            if (itemTypeId === void 0) { itemTypeId = -1; }
            var _this = _super.call(this) || this;
            _this.itemTypeId = itemTypeId;
            return _this;
        }
        BlogArticleMediaItem.prototype.loadAutoComplete = function (selector, url) {
            var cache = {};
            var lastXhr;
            var itemSelected = false;
            var ajax = new Wdot.Ajax();
            var autoCompeteHelper = new Wdot.AutocompleteHelper();
            $(selector).autocomplete({
                source: function (request, response) {
                    itemSelected = false;
                    response([{ Id: "AJAX", Name: '' }]);
                    ajax.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    var term = request.term;
                    if (term in cache) {
                        ajax.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(cache[term]);
                        return;
                    }
                    var paramaters = { term: term, placeType: selector.data("placetype") };
                    lastXhr = ajax.Get("/admin/help/BlogArticleMediaAutoComplete", paramaters, function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(data);
                        }
                    });
                },
                focus: function (event, ui) {
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    return false;
                },
                select: function (event, ui) {
                    itemSelected = true;
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    $(selector).attr("data-itemTypeId", ui.item.ItemTypeId);
                    return false;
                },
                close: function (event) {
                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                },
                minLength: 1
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                //if the Id is less than 0, then it is a heading
                if (item.Id > 0) {
                    return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a>" + item.Name + "</a>")
                        .appendTo(ul);
                }
                else {
                    return $("<li></li>")
                        .append("<h2>" + item.Name + "</h2>")
                        .appendTo(ul);
                }
            };
        };
        return BlogArticleMediaItem;
    }(Wdot.BaseSelectedItem));
    Wdot.BlogArticleMediaItem = BlogArticleMediaItem;
})(Wdot || (Wdot = {}));