var wdot = wdot || {};
wdot.AjaxStateHandler = function () {
    var init = function (selector) {
        //$.address.init(function () {
        updateHistory($(selector).html());
        //});
    },
    updateHistory = function (value) {
        $.address.value(value);
    },
    externalChange = function (externalChangeFunction) {
        $.address.externalChange(externalChangeFunction);
    };
    return {
        Init: init,
        ExternalChange: externalChange,
        UpdateHistory: updateHistory
    };
} ();