var wdot = wdot || {};
wdot.Deals = function () {
    var self = this;
    //self.setDateTimeCountDownTimer = function(){
    var setDateTimeCountDownTimer = function(){
        countdown.setLabels(
        '| | | | | | | | | |',
        '| | | | | | | | | |',
        ' : ',
        ' : ',
        '00');

        $.each($('.js-countdown-timer'), function (key, value) {
            var element = $(this);
            countdown(
                function (ts) {
                    element.html(ts.toHTML("strong"));
                },
                new Date(element.data("time")),
                countdown.HOURS | countdown.MINUTES | countdown.SECONDS);
        });
    },

    //self.createEditViewModel = function () {
    createEditViewModel = function () {
        return {
            ContentPageBlogArticlesViewModel: wdot.SelectedBlogArticles.ContentPageBlogArticlesViewModel
        };
    },

    listingPageViewModel = function () {
        var generateListingFilterJSON = function () {
            var filterKeyword = $("input[name='filterKeyword']").val();
            var id = $("input[name='Id']").val();
            var name = $("input[name='Name']").val();
            var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");
            var categories = $("select[name='SelectedCategories'] :selected");
            var startDate = $("input[name='StartDate']").val();
            var endDate = $("input[name='EndDate']").val();

            var queryString = '';
            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&Id=' + id;
            queryString += '&Name=' + name;
            queryString += '&StartDate=' + startDate;
            queryString += '&EndDate=' + endDate;

            if (checkedStatuses.length > 0) {
                var status = "";
                switch (checkedStatuses.val()) {
                    case "active":
                        wdot.Paging.data.IncludeActive = true;
                        wdot.Paging.data.IncludeInActive = false;
                        break;
                    case "inactive":
                        wdot.Paging.data.IncludeInActive = true;
                        wdot.Paging.data.IncludeActive = false;
                        break;
                    case "both":
                        wdot.Paging.data.IncludeActive = true;
                        wdot.Paging.data.IncludeInActive = true;

                        break;
                }

                wdot.Paging.data.SelectedVersionStatuses = status;
            } else {
                wdot.Paging.data.IncludeActive = false;
                wdot.Paging.data.IncludeInActive = false;
            }

            if (categories.length > 0) {
                var i = 0;
                var selectedCategories = {};
                ko.utils.arrayForEach(categories, function (t) { selectedCategories[i] = $(t).val(); queryString += '&SelectedCategories[' + i + ']=' + $(t).val(); i++; });
                wdot.Paging.data.SelectedCategories = selectedCategories;
            }
            else {
                wdot.Paging.data.SelectedCategories = {};
            }

            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.Id = id;
            wdot.Paging.data.Name = name;
            wdot.Paging.data.StartDate = startDate;
            wdot.Paging.data.EndDate = endDate;

            return queryString;
        },
            resetListingFilter = function () {
                $("input[name='filterKeyword']").val('');
                $("input[name='Id']").val('');
                $("input[name='Name']").val('');
                $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
                $("input[name=SelectedVersionStatuses][value='both']").attr("checked", true);
                $("input[name='StartDate']").val('');
                $("input[name='EndDate']").val('');
            };

        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    }();

    return {
        setDateTimeCountDownTimer: setDateTimeCountDownTimer,
        CreateEditViewModel: createEditViewModel,
        ListingPageViewModel: listingPageViewModel
    };
}();