var wdot = wdot || {};
wdot.SelectedBlogArticleItem = function () {
    var id = 0,
        name = '',
        index = 0,

        loadAutoComplete = function(selector) {
            var cache = {},
                lastXhr = '',
                itemSelected = false;
            if (selector == undefined) {
                selector = '#blogarticle-value';
            }
            $(selector).autocomplete({
                source: function(request, response) {
                    itemSelected = false;
                    response([{ Id: "AJAX", Name: '' }]);
                    Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));

                    var term = request.term;
                    if (term in cache) {
                        Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(cache[term]);
                        return;
                    }
                    lastXhr = Wdot.Ajax.prototype.Get("/admin/blogarticles/autocomplete", request, function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(data);
                        }
                    });
                },
                focus: function(event, ui) {
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    return false;
                },
                select: function (event, ui) {
                    itemSelected = true;
                    $(selector).val(ui.item.Name);
                    $(selector).attr("data-itemid", ui.item.Id);
                    return false;
                },
                close: function(event) {
                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                },
                minLength: 1
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                //if the Id is less than 0, then it is a heading
                if (item.Id > 0) {
                    return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a>" + item.Name + "</a>")
                        .appendTo(ul);
                } else {
                    return $("<li></li>")
                    .append("<h2>" + item.Name + "</h2>")
                    .appendTo(ul);
                }
                
            };
        };
    return {
        Id: id,
        Name: name,
        Index: index,
        LoadAutoComplete: loadAutoComplete
    };
}();