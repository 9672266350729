function mgThumbnailLocation() {
    if ($(window).width() > 750) {
        $('.media-summary').css('height', $('.media-preview__figure').height() - 145);
        $('.youtube-summary').css('height', $('.youtube__container').height() - 145);
    }
    else {
        $('.media-summary').css('height', 100 + '%');
        $('.youtube-summary').css('height', 100 + '%');
    }
};

var wdot = wdot || {};
wdot.MediaGalleryWidget = wdot.MediaGalleryWidget || {};
wdot.MediaGalleryWidget.PreviewAndThumbnails = function () {
    var container = $(".media-widget-previewandthumbnail"),
        fancyBoxOptions = {
            helpers: {
                title: {
                    type: 'inside'
                }
            }
        },
        previewTallPreset = "media-gallery-widget-preview-tall",
        previewWidePreset = "media-gallery-widget-preview-wide";

    container.find(".media-thumbnail__item").click(function () {
        var title = $(this).data("title"),
            summary = $(this).data("summary"),
            isTall = $(this).data("istall"),
            fileLocation = $(this).find(".media-thumbnail__image").attr("src").split("?")[0],
            currentContainer = $(this).parents(".media-widget-previewandthumbnail"),
            newPreviewImageFileLocation = fileLocation + "?preset=" + (isTall ? previewTallPreset : previewWidePreset),
            actionLink = $(this).data("actionlink");

        currentContainer.find(".media-preview__image").attr("src", newPreviewImageFileLocation).load(mgThumbnailLocation);
        currentContainer.find(".gallery-display__lightbox").attr("href", fileLocation);
        currentContainer.find(".gallery-display__lightbox").attr("title", title);
        currentContainer.find(".gallery-display__lightbox").attr("data-actionlink", actionLink);
        currentContainer.find(".media-summary__header").text(title);
        currentContainer.find(".media-summary__text").text(summary);
        currentContainer.find(".media-thumbnail__item").removeClass("selected");
        $(this).addClass("selected");
    });

    container.find(".gallery-display__lightbox").click(function () {

        var downloadUrl = $(this).attr("href");
        if ($(this).attr("data-actionlink") != '') {
            downloadUrl = $(this).attr("data-actionlink");
        }

        var imageTitle = $(this).attr("title"),
            fileLocation = $(this).attr("href"),
            fancyBoxTitle = imageTitle + " <a href='" + downloadUrl + "' target='_blank'>Download</a>";

        $.fancybox.open({ href: fileLocation, title: fancyBoxTitle }, fancyBoxOptions);
        return false;
    });
} ();


$(window).bind('load resize orientationchange', function () {
    mgThumbnailLocation();
});




