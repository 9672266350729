var wdot = wdot || {};
wdot.MediaGallery = function () {
    var selectFeaturedMedia = function (mediaFile) {
        addMedia(mediaFile, $("#AddFeaturedMediaToGalleryForm"));
    },
    selectMedia = function (mediaFile) {
        addMedia(mediaFile, $("#AddMediaToGalleryForm"));
    },
    addMedia = function (mediaFile, form) {
        form.find("#MediaFileId").val(mediaFile.id);
        Wdot.Ajax.prototype.Post(form.attr("action"), form.serialize(), function (data) {
            if (data.success) {
                $(".ajaxLoader").show();
                window.location.href = '/admin/mediagallery';
            }
            else {
                wdot.Utils.AddCustomError("#" + form.attr("id"), "[name='customErrors']", data.errorMessage);
            }
        });
    },
    allowZoom = function (flag) {
        if (flag == true) {
            $('head meta[name=viewport]').remove();
            $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1" />');
        } else {
            $('head meta[name=viewport]').remove();
            $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />');
        }
    },
    clearFilters = function () {
        $("select[name='FilterCategories'] option:first").attr("selected", true);
        $("select[name='FilterType'] option:first").attr("selected", true);
        $("select[name='FilterAlbums'] option:first").attr("selected", true);
        $("input[name='FilterKeyword']").val("");
        $("input[name='AlbumFilterKeyword']").val("");
        loadPageAnimation(0, 0, 0, "", 1, 0, false);
        $("#mediaGalleryGrid a").click();
    },
    loadPageAnimation = function (filterCategory, filterType, filterAlbum, filterKeyword, page, listType, deepLink) {
        function urlParameter() {
            var url = window.location.href,
            retObject = {},
            params;
            if (url.indexOf('?') === -1) {
                return null;
            }
            url = url.split('?')[1];
            params = url.split('&');
            for (var i = 0; i < params.length; i++) {
                retObject[params[i].split('=')[0]] = params[i].split('=')[1];
            }
            return retObject;
        }

        var parameters = urlParameter();

        if (parameters != null && typeof parameters.listType !== "undefined") {
            init = false;
        }

        if (page == null) {
            return;
        }
        if (!init) {
            function deepLinkURL() {
                var queryString = "?listType=" + listType;
                if (listType == 0) {
                    if (filterCategory != "")
                        queryString += "&category=" + filterCategory;
                    if (filterType != "")
                        queryString += "&type=" + filterType;
                    if (filterAlbum != "")
                        queryString += "&album=" + filterAlbum;
                    if (filterKeyword != "")
                        queryString += "&keyword=" + filterKeyword;
                } else if (listType == 1) {
                    if (filterKeyword != "")
                        queryString += "&keyword=" + filterKeyword;
                }
                queryString += "&page=" + page;
                wdot.AjaxStateHandler.UpdateHistory(queryString);
            }

            var height = $("#media-grid__list").outerHeight(true);
            $("#mediaGalleryMediaContainer").height(height);
            var div = "#mediaListView";
            if (listType == 0) {
                div = "#mediaListView";
            } else if (listType == 1) {
                div = "#albumListView";
            }

            $(".ajaxLoader").removeClass("hidden");
            $(div).load('/MediaGallery/GetListingForMediaGallery', { BaseDataUrl: $('#BaseDataUrl').val(), filterCategory: filterCategory, filterType: filterType, filterAlbum: filterAlbum, filterKeyword: filterKeyword, currentPage: page, pageType: listType }, function () {

                $(".ajaxLoader").addClass("hidden");
                $('.truncateJS').trunk8({
                    lines: 8,
                    fill: "&hellip;<a href='#' class='read-more'>read more</a>"
                });

                $(document).on('click', '.read-more', function (event) {
                    $(this).parent().trunk8('revert').append(' <a class="read-less" href="#">read less</a>');

                    return false;
                });

                $(document).on('click', '.read-less', function (event) {
                    $(this).parent().trunk8({ lines: 8 });

                    return false;
                });
            });
            if (deepLink) {
                deepLinkURL();
            }
        } else {
            init = false;
        }
    },
    mediaGalleryListPageType = {
        MediaGallery: 0,
        Album: 1,
        getType: function (value) {
            for (key in mediaGalleryListPageType) {
                if (typeof mediaGalleryListPageType[key] === "number" && mediaGalleryListPageType[key] === value) {
                    return key;
                }
            }
            return undefined;
        }
    },
    loadMediaGalleryByPage = function (page, listType) {
        if (typeof $("select[name='FilterType']").val() == "undefined") {
            var filterCategory = 0;
            var filterType = 0;
            var filterAlbum = $("#Album_Id").val();
            var filterKeyword = "";
            init = false;
            loadPageAnimation(filterCategory, filterType, filterAlbum, filterKeyword, page, listType, true);
            isNavigating = false;
            return;
        }

        if (listType == 0) {
            var filterCategory = $("select[name='FilterCategories']").val();
            var filterType = $("select[name='FilterType']").val();
            var filterAlbum = $("select[name='FilterAlbums']").val();
            var filterKeyword = $("input[name='FilterKeyword']").val();
            if (filterCategory < 1) {
                filterCategory = 0;
            }
            if (filterType < 1) {
                filterType = 0;
            }
            if (filterAlbum < 1) {
                filterAlbum = 0;
            }
            loadPageAnimation(filterCategory, filterType, filterAlbum, filterKeyword, page, listType, true);

        } else if (listType == 1) {
            var filterCategory = $("select[name='AlbumFilterCategories']").val();
            var filterType = 0;
            var filterAlbum = 0;
            var filterKeyword = $("input[name='AlbumFilterKeyword']").val();
            loadPageAnimation(filterCategory, filterType, filterAlbum, filterKeyword, page, listType, true);
        }

        switch (mediaGalleryListPageType.getType(listType)) {
            case "MediaGallery":
                document.getElementById("mediaGalleryGrid").scrollIntoView();
                break;
            case "Album":
                document.getElementById("mediaGalleryAlbum").scrollIntoView();
                break;
        }
    },
    loadMediaGalleryByCategoryTag = function (categoryId) {
        if (typeof $("select[name='FilterType']").val() == "undefined") {
            var filterCategory = 0;
            var filterType = 0;
            var filterAlbum = $("#Album_Id").val();
            var filterKeyword = "";
            init = false;
            loadPageAnimation(filterCategory, filterType, filterAlbum, filterKeyword, 1, 0, true);
            isNavigating = false;
            return;
        }

        $("select[name='FilterCategories']").val(categoryId);
        var filterCategory = categoryId;
        var filterType = $("select[name='FilterType']").val();
        var filterAlbum = $("select[name='FilterAlbums']").val();
        var filterKeyword = $("input[name='FilterKeyword']").val();
        if (filterCategory < 1) {
            filterCategory = 0;
        }
        if (filterType < 1) {
            filterType = 0;
        }
        if (filterAlbum < 1) {
            filterAlbum = 0;
        }
        loadPageAnimation(filterCategory, filterType, filterAlbum, filterKeyword, 1, 0, true);
    },
    isNavigating = null,
    activeTab = 0,
    init = true,
    loadPageWithFilteredValues = function (e) {
        function urlParameter() {
            var url = window.location.href,
            retObject = {},
            parameters;
            if (url.indexOf('?') === -1) {
                return null;
            }
            url = url.split('?')[1];
            parameters = url.split('&');
            for (var i = 0; i < parameters.length; i++) {
                retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
            }
            return retObject;
        }

        var queryStringParameters = urlParameter();

        if (queryStringParameters == null) {
            $("select[name='FilterCategories']").val(0);
            $("select[name='FilterType']").val(0);
            $("select[name='FilterAlbums']").val(0);
            $("input[name='FilterKeyword']").val("");
            loadPageAnimation(0, 0, 0, "", 1, 0, false);
            $("#mediaGalleryGrid a").click();
            isNavigating = false;
            return;
        }

        var listType = queryStringParameters.listType;
        var category = queryStringParameters.category;
        var type = queryStringParameters.type;
        var album = queryStringParameters.album;
        var keyword = queryStringParameters.keyword;
        var page = queryStringParameters.page;

        if (listType == null) {
            listType = 0;
        }
        if (category == null) {
            category = 0;
        }
        if (type == null) {
            type = 0;
        }
        if (album == null) {
            album = 0;
        }
        if (keyword == null) {
            keyword = "";
        }
        if (page == null) {
            page = 0;
        }

        if (listType == 0) {
            $("select[name='FilterCategories']").val(category);
            $("select[name='FilterType']").val(type);
            $("select[name='FilterAlbums']").val(album);
            $("input[name='FilterKeyword']").val(keyword);
            loadPageAnimation(category, type, album, keyword, page, listType, false);
        }
        else if (listType == 1) {
            category = 0;
            type = 0;
            album = 0;
            $("input[name='AlbumFilterKeyword']").val(keyword);
            loadPageAnimation(category, type, album, keyword, page, listType, false);
        } else {
            listType = 0;
            loadPageAnimation(category, type, album, keyword, page, listType, false);
        }

        if (activeTab != listType) {
            if (listType == 0) {
                $("#mediaGalleryGrid a").click();
                activeTab = 0;
            }
            if (listType == 1) {
                $("#mediaGalleryAlbum a").click();
                activeTab = 1;
            }
        }

        isNavigating = false;
    },
    loadMediaGalleryItemsAutocomplete = function (selector, selectorFind) {
        var cache = {},
                    lastXhr = '',
                    itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/mediagallery/autocompletemediagalleryitems", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (event, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                        .data("ui-autocomplete-item", item)
                        .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.Name + "</a>")
                        .appendTo(ul);
        };
    },
    savedQueryString = null,
    updateTabForDeepLink = function (listType) {
        if (!isNavigating) {
            var queryString = "?listType=" + listType;
            var page = 1;
            if (listType == 0) {
                var filterCategory = $("select[name='FilterCategories']").val();
                var filterType = $("select[name='FilterType']").val();
                var filterAlbum = $("select[name='FilterAlbums']").val();
                var filterKeyword = $("input[name='FilterKeyword']").val();
                page = $('#mediaListView > .pagination > .carousel-active-tab > .pagingbtn').data("pagenumber");
                if (filterCategory != "")
                    queryString += "&category=" + filterCategory;
                if (filterType != "")
                    queryString += "&type=" + filterType;
                if (filterAlbum != "")
                    queryString += "&album=" + filterAlbum;
                if (filterKeyword != "")
                    queryString += "&keyword=" + filterKeyword;
            } else if (listType == 1) {
                var albumFilterKeyword = $("input[name='AlbumFilterKeyword']").val();
                page = $('#albumListView > .pagination > .carousel-active-tab > .pagingbtn').data("pagenumber");
                if (albumFilterKeyword != "")
                    queryString += "&keyword=" + albumFilterKeyword;
            }
            wdot.AjaxStateHandler.UpdateHistory(queryString);
            queryString += "&page=" + page;

            activeTab = listType;
        }
    },
    loadMediaGalleryAutocomplete = function () {

    },
    listingPageViewModel = function () {
        var generateListingFilterJSON = function () {
            var filterKeyword = $("input[name='filterKeyword']").val();
            var baseDataUrl = $('#BaseDataUrl').val();
            var queryString = '';
            queryString += '&filterKeyword=' + filterKeyword;
            queryString += '&BaseDataUrl=' + baseDataUrl;
            wdot.Paging.data.filterKeyword = filterKeyword;
            wdot.Paging.data.BaseDataUrl = baseDataUrl;
            return queryString;
        },
        resetListingFilter = function () {
            $("input[name='filterKeyword']").val('');
        };
        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    } ();

    return {
        SelectFeaturedMedia: selectFeaturedMedia,
        SelectMedia: selectMedia,
        ClearFilters: clearFilters,
        ActiveTab: function (val) {
            if (typeof val !== "undefined") {
                activeTab = val;
            }
            return activeTab;
        },
        IsNavigating: function (val) {
            if (typeof val !== "undefined") {
                isNavigating = val;
            }
            return isNavigating;
        },
        Init: function (val) {
            if (typeof val !== "undefined") {
                init = val;
            }
            return init;
        },
        LoadMediaGalleryByPage: loadMediaGalleryByPage,
        LoadMediaGalleryByCategoryTag: loadMediaGalleryByCategoryTag,
        LoadPageWithFilteredValues: loadPageWithFilteredValues,
        LoadMediaGalleryItemsAutocomplete: loadMediaGalleryItemsAutocomplete,
        UpdateTabForDeepLink: updateTabForDeepLink,
        ListingPageViewModel: listingPageViewModel,
        loadMediaGalleryAutocomplete: loadMediaGalleryAutocomplete,
        AllowZoom: allowZoom
    };
} ();