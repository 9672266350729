var wdot = wdot || {};
wdot.Reports = function () {
    var loadFallColorReportsAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/reports/autocompleteforfallcolorreports", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
        loadGeopointReportsAutocomplete = function (selector, selectorFind) {
            var cache = {},
                lastXhr = '',
                itemSelected = false;
            $(selector).find(selectorFind).autocomplete({
                source: function (request, response) {
                    itemSelected = false;
                    response([{ Id: "AJAX", Name: '' }]);
                    Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    var term = request.term;
                    if (term in cache) {
                        Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        response(cache[term]);
                        return;
                    }
                    lastXhr = Wdot.Ajax.prototype.Get("/admin/reports/autocompleteforgeopointreports", request, function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(data);
                        }
                    });
                },
                focus: function (group, ui) {
                    $(selector).find(selectorFind).val(ui.item.Name);
                    return false;
                },
                select: function (event, ui) {
                    itemSelected = true;
                    $(selector).find(selectorFind).val(ui.item.Name);
                    $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                    $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                    return false;
                },
                close: function (event) {
                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                },
                minLength: 1
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                return $("<li></li>")
                    .data("ui-autocomplete-item", item)
                    .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                    .appendTo(ul);
            };
        },
    loadDownhillSkiingReportsAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/reports/autocompletefordownhillskiingreports", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    loadCrossCountrySkiingReportsAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/reports/autocompleteforcrosscountryskiingreports", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    loadSnowmobileReportsAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/reports/autocompleteforsnowmobilingreports", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    fallColorDetailReportViewModel = function () {
        
        var county = ko.observable(),
                geoCodedLat = ko.observable(),
                geoCodedLong = ko.observable(),
                geoCodedLatLong = ko.computed(function () {
                    return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
                }),
                street = ko.observable(),
                city = ko.observable(),
                state = ko.observable(),
                zip = ko.observable(),
                bingMap = ko.observable();
                var address = ko.computed(function () {
                    return { street: street(), city: city(), state: state(), zip: zip() };
                }),
                addressAsString = ko.computed(function () {
                    return street() + " " + city() + ", " + state() + " " + zip();
                }),
                geoCodeCallback = function (data) {
                    var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                    var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                    geoCodedLat(latitutde);
                    geoCodedLong(longitutde);
                },
                initobservables = function (streetParam, cityParam, stateParam, zipParam, latParam, longParam, map) {
                    street(streetParam);
                    state(stateParam);
                    city(cityParam);
                    zip(zipParam);
                    if (latParam != undefined && latParam != null && longParam != undefined && longParam != null) {
                        geoCodedLat(latParam);
                        geoCodedLong(longParam);
                    }
                    bingMap(map);
                },
                geoCodeAddress = function () {
                    bingMap().geoCodeAddress(address(), 'wdot.Reports.fallColorDetailReportViewModel.geoCodeCallback');
                };
        return {
            county: county,
            geoCodedLat: geoCodedLat,
            geoCodedLong: geoCodedLong,
            street: street,
            city: city,
            state: state,
            zip: zip,
            geoCodeCallback: geoCodeCallback,
            addressAsString: addressAsString,
            geoCodedLatLong: geoCodedLatLong,
            initObservables: initobservables,
            geoCodeAddress: geoCodeAddress,
            bingMap: bingMap
        };
    }(),
        geopointDetailReportViewModel = function () {

            var county = ko.observable(),
                geoCodedLat = ko.observable(),
                geoCodedLong = ko.observable(),
                geoCodedLatLong = ko.computed(function () {
                    return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
                }),
                street = ko.observable(),
                city = ko.observable(),
                state = ko.observable(),
                zip = ko.observable(),
                bingMap = ko.observable();
            var address = ko.computed(function () {
                return { street: street(), city: city(), state: state(), zip: zip() };
            }),
                addressAsString = ko.computed(function () {
                    return street() + " " + city() + ", " + state() + " " + zip();
                }),
                geoCodeCallback = function (data) {
                    var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                    var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                    geoCodedLat(latitutde);
                    geoCodedLong(longitutde);
                },
                initobservables = function (streetParam, cityParam, stateParam, zipParam, latParam, longParam, map) {
                    street(streetParam);
                    state(stateParam);
                    city(cityParam);
                    zip(zipParam);
                    if (latParam != undefined && latParam != null && longParam != undefined && longParam != null) {
                        geoCodedLat(latParam);
                        geoCodedLong(longParam);
                    }
                    bingMap(map);
                },
                geoCodeAddress = function () {
                    bingMap().geoCodeAddress(address(), 'wdot.Reports.geopointDetailReportViewModel.geoCodeCallback');
                };
            return {
                county: county,
                geoCodedLat: geoCodedLat,
                geoCodedLong: geoCodedLong,
                street: street,
                city: city,
                state: state,
                zip: zip,
                geoCodeCallback: geoCodeCallback,
                addressAsString: addressAsString,
                geoCodedLatLong: geoCodedLatLong,
                initObservables: initobservables,
                geoCodeAddress: geoCodeAddress,
                bingMap: bingMap
            };
        }(),
    downhillSkiingDetailReportViewModel = function () {
        var county = ko.observable(),
                geoCodedLat = ko.observable(),
                geoCodedLong = ko.observable(),
                geoCodedLatLong = ko.computed(function () {
                    return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
                }),
                street = ko.observable(),
                city = ko.observable(),
                state = ko.observable(),
                zip = ko.observable(),
                bingMap = ko.observable(),
                address = ko.computed(function () {
                    return { street: street(), city: city(), state: state(), zip: zip() };
                }),
                addressAsString = ko.computed(function () {
                    return street() + " " + city() + ", " + state() + " " + zip();
                }),
                geoCodeCallback = function (data) {
                    var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                    var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                    geoCodedLat(latitutde);
                    geoCodedLong(longitutde);
                },
                initobservables = function (streetParam, cityParam, stateParam, zipParam, latParam, longParam, map) {
                    street(streetParam);
                    state(stateParam);
                    city(cityParam);
                    zip(zipParam);
                    if (latParam != undefined && latParam != null && longParam != undefined && longParam != null) {
                        geoCodedLat(latParam);
                        geoCodedLong(longParam);
                    }
                    bingMap(map);
                },
                geoCodeAddress = function () {
                    bingMap().geoCodeAddress(address(), 'wdot.Reports.downhillSkiingDetailReportViewModel.geoCodeCallback');
                };
        return {
            county: county,
            geoCodedLat: geoCodedLat,
            geoCodedLong: geoCodedLong,
            street: street,
            city: city,
            state: state,
            zip: zip,
            geoCodeCallback: geoCodeCallback,
            addressAsString: addressAsString,
            geoCodedLatLong: geoCodedLatLong,
            initObservables: initobservables,
            geoCodeAddress: geoCodeAddress,
            bingMap: bingMap
        };
    } (),
     crossCountrySkiingDetailReportViewModel = function () {
         var county = ko.observable(),
                geoCodedLat = ko.observable(),
                geoCodedLong = ko.observable(),
                geoCodedLatLong = ko.computed(function () {
                    return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
                }),
                street = ko.observable(),
                city = ko.observable(),
                state = ko.observable(),
                zip = ko.observable(),
                bingMap = ko.observable(),
                address = ko.computed(function () {
                    return { street: street(), city: city(), state: state(), zip: zip() };
                }),
                addressAsString = ko.computed(function () {
                    return street() + " " + city() + ", " + state() + " " + zip();
                }),
                geoCodeCallback = function (data) {
                    var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                    var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                    geoCodedLat(latitutde);
                    geoCodedLong(longitutde);
                },
                initobservables = function (streetParam, cityParam, stateParam, zipParam, latParam, longParam, map) {
                    street(streetParam);
                    state(stateParam);
                    city(cityParam);
                    zip(zipParam);
                    if (latParam != undefined && latParam != null && longParam != undefined && longParam != null) {
                        geoCodedLat(latParam);
                        geoCodedLong(longParam);
                    }
                    bingMap(map);
                },
                geoCodeAddress = function () {
                    bingMap().geoCodeAddress(address(), 'wdot.Reports.crossCountrySkiingDetailReportViewModel.geoCodeCallback');
                };
         return {
             county: county,
             geoCodedLat: geoCodedLat,
             geoCodedLong: geoCodedLong,
             street: street,
             city: city,
             state: state,
             zip: zip,
             geoCodeCallback: geoCodeCallback,
             addressAsString: addressAsString,
             geoCodedLatLong: geoCodedLatLong,
             initObservables: initobservables,
             geoCodeAddress: geoCodeAddress,
             bingMap: bingMap
         };
     } (),
     snowmobileDetailReportViewModel = function () {
         var county = ko.observable(),
                geoCodedLat = ko.observable(),
                geoCodedLong = ko.observable(),
                geoCodedLatLong = ko.computed(function () {
                    return { Latitude: geoCodedLat(), Longitude: geoCodedLong() };
                }),
                street = ko.observable(),
                city = ko.observable(),
                state = ko.observable(),
                zip = ko.observable(),
                bingMap = ko.observable(),
                address = ko.computed(function () {
                    return { street: street(), city: city(), state: state(), zip: zip() };
                }),
                addressAsString = ko.computed(function () {
                    return street() + " " + city() + ", " + state() + " " + zip();
                }),
                geoCodeCallback = function (data) {
                    var latitutde = data.resourceSets[0].resources[0].point.coordinates[0];
                    var longitutde = data.resourceSets[0].resources[0].point.coordinates[1];
                    geoCodedLat(latitutde);
                    geoCodedLong(longitutde);
                },
                initobservables = function (streetParam, cityParam, stateParam, zipParam, latParam, longParam, map) {
                    street(streetParam);
                    state(stateParam);
                    city(cityParam);
                    zip(zipParam);
                    if (latParam != undefined && latParam != null && longParam != undefined && longParam != null) {
                        geoCodedLat(latParam);
                        geoCodedLong(longParam);
                    }
                    bingMap(map);
                },
                geoCodeAddress = function () {
                    debugger;
                    bingMap().geoCodeAddress(address(), 'wdot.Reports.snowmobileDetailReportViewModel.geoCodeCallback');
                };
         return {
             county: county,
             geoCodedLat: geoCodedLat,
             geoCodedLong: geoCodedLong,
             street: street,
             city: city,
             state: state,
             zip: zip,
             geoCodeCallback: geoCodeCallback,
             addressAsString: addressAsString,
             geoCodedLatLong: geoCodedLatLong,
             initObservables: initobservables,
             geoCodeAddress: geoCodeAddress,
             bingMap: bingMap
         };
     } ();
    return {
        loadFallColorReportsAutocomplete: loadFallColorReportsAutocomplete,
        loadGeopointReportsAutocomplete: loadGeopointReportsAutocomplete,
        loadDownhillSkiingReportsAutocomplete: loadDownhillSkiingReportsAutocomplete,
        loadCrossCountrySkiingReportsAutocomplete: loadCrossCountrySkiingReportsAutocomplete,
        loadSnowmobileReportsAutocomplete: loadSnowmobileReportsAutocomplete,
        fallColorDetailReportViewModel: fallColorDetailReportViewModel,
        geopointDetailReportViewModel: geopointDetailReportViewModel,
        downhillSkiingDetailReportViewModel: downhillSkiingDetailReportViewModel,
        crossCountrySkiingDetailReportViewModel: crossCountrySkiingDetailReportViewModel,
        snowmobileDetailReportViewModel: snowmobileDetailReportViewModel
    };
}();

