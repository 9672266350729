var wdot = wdot || {};
wdot.County = function () {
    var loadCountyAutocomplete = function (selector, selectorFind) {
        var cache = {},
		lastXhr = '',
		itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;

                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);

                    return;
                }

                lastXhr = Wdot.Ajax.prototype.Get("/admin/counties/autocomplete", request, function (data, status, xhr) {
                    cache[term] = data;

                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);

                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);

                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
				.data("ui-autocomplete-item", item)
				.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
				.appendTo(ul);
        };
    },

	loadCountyAutocompleteForPublicSite = function (selector, selectorFind, citySelector) {
	    var cache = {},
			lastXhr = '',
			itemSelected = false;
	    if ($(selector).find(selectorFind).length > 0) {
	        $(selector).find(selectorFind).autocomplete({
	            source: function (request, response) {
	                itemSelected = false;
	                response([{ Id: "AJAX", Name: ''}]);
	                var term = request.term;

	                if (term in cache) {
	                    response(cache[term]);

	                    return;
	                }

	                var regionFilter = parseInt($(selector).find(selectorFind).data("regionfilter"), 10);

	                if (isNaN(regionFilter)) {
	                    regionFilter = 0;
	                }

	                request.regionFilter = regionFilter;

	                $('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]').append("<div class='ajaxLoaderAutocomplete' style='width: 100%; height: 16px; padding-top: 5px; padding-bottom:5px;'><div class='ajaxImage' style='background-image:url(/Images/ajax-loader.gif); margin:auto; width:16px; height:16px; padding:0px'></div></div>");

	                lastXhr = Wdot.Ajax.prototype.Get("/Counties/AutocompleteForCounties", request, function (data, status, xhr) {
	                    cache[term] = data;
	                    if (xhr === lastXhr) {
	                        $(".ajaxLoaderAutocomplete").remove();
	                        response(data);
	                    }
	                }, 'json', false);
	            },
	            focus: function (event, ui) {
	                $(selector).find(selectorFind).val(ui.item.Name);
	                return false;
	            },
	            select: function (event, ui) {
	                
	                itemSelected = true;
	                $(selector).find(selectorFind).attr("data-countyname", ui.item.Name);
	                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
	                $(selector).find(selectorFind).attr("data-cityid", "");
	                $(selector).find(selectorFind).attr("data-countyid", ui.item.Id);
	                $(selector).find(selectorFind).attr("data-regionid", ui.item.RegionId);
	                $(selector).find(selectorFind).attr("data-countyurl", ui.item.CountyUrl);
	                $(selector).find(selectorFind).val(ui.item.Name);
	                $(selector).find(citySelector).attr("data-cityname", "");
	                $(selector).find(citySelector).attr("data-countyname", ui.item.Name);
	                $(selector).find(citySelector).attr("data-itemid", ui.item.Id);
	                $(selector).find(citySelector).attr("data-cityid", "");
	                $(selector).find(citySelector).attr("data-countyid", ui.item.Id);
	                $(selector).find(citySelector).attr("data-regionid", ui.item.RegionId);
	                $(selector).find(citySelector).attr("data-countyurl", ui.item.CountyUrl);
	                $(selector).find(citySelector).val("");
	                return false;
	            },
	            close: function (event) {
	                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	            },
	            minLength: 1
	        }).data("ui-autocomplete")._renderItem = function (ul, item) {
	            return $("<li></li>")
	                .data("ui-autocomplete-item", item)
	                .append("<a data-itemid='" + item.Id + "' data-cityname='" + item.CityName + "' data-countyname='" + item.CountyName + "' data-cityid='" + item.CityId + "' data-countyid='" + item.CountyId + "' data-regionid='" + item.RegionId + "'>" + item.Name + "</a>")
	                .appendTo(ul);
	        };
	    }
	},

	listingPageViewModel = function () {
	    var generateListingFilterJSON = function () {
	        var filterKeyword = $("input[name='filterKeyword']").val();
	        var id = $("input[name='Id']").val();
	        var name = $("input[name='Name']").val();
	        var regions = $("input[name*='Regions.SelectedItems']");
	        var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");

	        var queryString = '';

	        queryString += '&filterKeyword=' + filterKeyword;
	        queryString += '&Id=' + id;
	        queryString += '&Name=' + name;

	        if (regions.length > 0) {
	            var i = 0;
	            var selectedRegions = {};
	            ko.utils.arrayForEach(regions, function (t) { selectedRegions[i] = $(t).val(); queryString += '&Regions.SelectedItems[' + i + ']=' + $(t).val(); i++; });
	            wdot.Paging.data['Regions.SelectedItems'] = selectedRegions;
	        }
	        else {
	            wdot.Paging.data['Regions.SelectedItems'] = {};
	        }

	        if (checkedStatuses.length > 0) {
	            var i = 0;
	            var selectedVersionStatuses = {};
	            ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
	            wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;

	        }
	        else {
	            wdot.Paging.data.SelectedVersionStatuses = {};
	        }


	        wdot.Paging.data.filterKeyword = filterKeyword;
	        wdot.Paging.data.Id = id;
	        wdot.Paging.data.Name = name;
	        return queryString;
	    },
		resetListingFilter = function () {
		    $("input[name='filterKeyword']").val('');
		    $("input[name='Id']").val('');
		    $("input[name='Name']").val('');
		    $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
		    var regions = wdot.Listing.listingViewModel.Items.regions();

		    for (var i = regions.length - 1; i >= 0; i--) {
		        wdot.Listing.listingViewModel.Remove(regions[i], 'regions', $("#AllItemsList[data-uniquelistingkey='regions']").find("input[id*='viewAllItem_Name_']"));
		    }
		};
	    return {
	        generateListingFilterJSON: generateListingFilterJSON,
	        resetListingFilter: resetListingFilter
	    };
	} ();
    return {
        loadCountyAutocomplete: loadCountyAutocomplete,
        loadCountyAutocompleteForPublicSite: loadCountyAutocompleteForPublicSite,
        ListingPageViewModel: listingPageViewModel
    };
} ();