var wdot = wdot || {};
wdot.Themes = function () {
    var listingViewModel = function () {
        var deactivateTheme = function (id) {
            return true;
        },
        activateTheme = function (id) {
            var answer = confirm("Are you sure that you want to change the current theme?");
            if (answer) {
                return true;
            }
            return false;
        };
        //        activateThemeAjaxCall = function (id) {
        //            Wdot.Ajax.prototype.Post('/Admin/Themes/ActivateTheme/' + id, null, activateCallback);
        //        },
        //        deactivateThemeAjaxCall = function (id) {
        //            Wdot.Ajax.prototype.Post('/Admin/Themes/DeactivateTheme/' + id, null, deactivateCallback);
        //        },
        //        activateCallback = function (d) {
        //            if (d.Success) {
        //                $("[id^=deactivate_theme_]").not(":hidden").each(function () {
        //                    var idToDeactivate = $(this).attr("id").replace("deactivate_theme_", "");
        //                    deactivateCallback({ Success: true, Id: idToDeactivate });
        //                });
        //                $("#deactivate_theme_" + d.Id).removeClass('hidden');
        //                $("#activate_theme_" + d.Id).addClass('hidden');
        //                $("#deactivate_theme_" + d.Id).parent().prev("td").html("Yes");
        //            }
        //        },
        //        deactivateCallback = function (d) {
        //            if (d.Success) {
        //                $("#deactivate_theme_" + d.Id).addClass('hidden');
        //                $("#activate_theme_" + d.Id).removeClass('hidden');
        //                $("#deactivate_theme_" + d.Id).parent().prev("td").html("No");
        //            }
        //        };
        return {
            DeactivateTheme: deactivateTheme,
            ActivateTheme: activateTheme
        };
    } ();
    return {
        listingViewModel: listingViewModel
    };
} ();