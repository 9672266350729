var wdot = wdot || {};
wdot.AdGroups = function () {
    var loadAdsAutocomplete = function (selector, selectorFind) {
        var cache = {},
        lastXhr = '',
        itemSelected = false;
        $(selector).find(selectorFind).autocomplete({
            source: function (request, response) {
                itemSelected = false;
                response([{ Id: "AJAX", Name: ''}]);
                Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                var term = request.term;
                if (term in cache) {
                    Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                    response(cache[term]);
                    return;
                }
                lastXhr = Wdot.Ajax.prototype.Get("/admin/ads/autocompleteforads", request, function (data, status, xhr) {
                    cache[term] = data;
                    if (xhr === lastXhr) {
                        response(data);
                    }
                });
            },
            focus: function (group, ui) {
                $(selector).find(selectorFind).val(ui.item.Name);
                return false;
            },
            select: function (event, ui) {
                itemSelected = true;
                $(selector).find(selectorFind).val(ui.item.Name);
                $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
                $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                return false;
            },
            close: function (event) {
                Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
            },
            minLength: 1
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "' >" + item.Name + "</a>")
                .appendTo(ul);
        };
    },
    listingPageViewModel = function () {
    	var generateListingFilterJSON = function () {
            var FilterKeyword = $("input[name='FilterKeyword']").val();
            var Tags = $("input[name='Tags']").val();

            var queryString = '';

            queryString += '&FilterKeyword=' + FilterKeyword;
            queryString += '&FilterTags=' + Tags;


            wdot.Paging.data.FilterKeyword = FilterKeyword;
            wdot.Paging.data.FilterTags = Tags;
            return queryString;
        },
        resetListingFilter = function () {
            $("input[name='FilterKeyword']").val('');
            $("input[name='Tags']").val('');
            var regions = wdot.Listing.listingViewModel.Items.regions();

            for (var i = regions.length - 1; i >= 0; i--) {
                wdot.Listing.listingViewModel.Remove(regions[i], 'regions', $("#AllItemsList[data-uniquelistingkey='regions']").find("input[id*='viewAllItem_Name_']"));
            }
        };
        return {
            generateListingFilterJSON: generateListingFilterJSON,
            resetListingFilter: resetListingFilter
        };
    } ();
//    listingPageViewModel = function () {
//        var generateListingFilterJSON = function () {
//            var filterKeyword = $("input[name='filterKeyword']").val();
//            var id = $("input[name='Id']").val();
//            var name = $("input[name='Name']").val();
//            var regions = $("input[name*='Regions.SelectedItems']");
//            var checkedStatuses = $("input[name='SelectedVersionStatuses']:checked");

//            var queryString = '';

//            queryString += '&filterKeyword=' + filterKeyword;
//            queryString += '&Id=' + id;
//            queryString += '&Name=' + name;

//            if (Ads.length > 0) {
//                var i = 0;
//                var selectedAds = {};
//                ko.utils.arrayForEach(regions, function (t) { selectedAds[i] = $(t).val(); queryString += '&Ads.SelectedItems[' + i + ']=' + $(t).val(); i++; });
//                wdot.Paging.data['Ads.SelectedItems'] = selectedAds;
//            }
//            else {
//                wdot.Paging.data['Ads.SelectedItems'] = {};
//            }

//            if (checkedStatuses.length > 0) {
//                var i = 0;
//                var selectedVersionStatuses = {};
//                ko.utils.arrayForEach(checkedStatuses, function (t) { selectedVersionStatuses[i] = $(t).val(); queryString += '&SelectedVersionStatuses[' + i + ']=' + $(t).val(); i++; });
//                wdot.Paging.data.SelectedVersionStatuses = selectedVersionStatuses;

//            }
//            else {
//                wdot.Paging.data.SelectedVersionStatuses = {};
//            }


//            wdot.Paging.data.filterKeyword = filterKeyword;
//            wdot.Paging.data.Id = id;
//            wdot.Paging.data.Name = name;
//            return queryString;
//        },
//        resetListingFilter = function () {
//            $("input[name='filterKeyword']").val('');
//            $("input[name='Id']").val('');
//            $("input[name='Name']").val('');
//            $("input[name='SelectedVersionStatuses']:checked").attr("checked", false);
//            var regions = wdot.Listing.listingViewModel.Items.regions();

//            for (var i = regions.length - 1; i >= 0; i--) {
//                wdot.Listing.listingViewModel.Remove(regions[i], 'regions', $("#AllItemsList[data-uniquelistingkey='regions']").find("input[id*='viewAllItem_Name_']"));
//            }
//        };
//        return {
//            generateListingFilterJSON: generateListingFilterJSON,
//            resetListingFilter: resetListingFilter
//        };
//    } ();
    return {
        loadAdsAutocomplete: loadAdsAutocomplete,
        ListingPageViewModel: listingPageViewModel
    };
} ();