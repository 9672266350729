var wdot = wdot || {};
wdot.Seo = function () {
    var seoContentTagsViewModel = function () {
        var tags = ko.observableArray(),
            index = 0,
            addTag = function () {
                var value = $("#tag-value").val();
                if (value != undefined && value != '' && !isTagAlreadyAdded(value)) {
                    var tagFromTextbox = new wdot.Tag.constructor();
                    tagFromTextbox.TagValue = value;
                    tagFromTextbox.Index = index++;
                    tagFromTextbox.InputName = 'Seo.ContentTags[' + tagFromTextbox.Index + '].TagValue';
                    tags.push(tagFromTextbox);

                    $("#AllTagList input[type='checkbox']").each(function () {
                        if (isTagAlreadyAdded($(this).val())) {
                            $(this).attr('checked', 'checked');
                        }
                    });
                }
                $("#tag-value").val('');

            },
            removeTag = function (tagToRemove) {
                var indexRemoved = tagToRemove.Index;
                tags.remove(tagToRemove);
                for (var i = indexRemoved; i < tags().length; i++) {
                    var currentTag = tags()[i];
                    currentTag.Index = indexRemoved++;
                    $("[name='" + currentTag.InputName + "']").attr('name', 'Seo.ContentTags[' + currentTag.Index + '].TagValue');
                    currentTag.InputName = 'Seo.ContentTags[' + currentTag.Index + '].TagValue';
                }
                index--;
                $("#AllTagList input[type='checkbox']").each(function () {
                    if (isTagAlreadyAdded($(this).val())) {
                        $(this).attr('checked', 'checked');
                    } else {
                        $(this).removeAttr('checked');
                    }
                });
            },
            addTagsFromViewAll = function () {
                $("[name='viewAllTag']").each(function () {
                    var value = $(this).val();
                    if (!isTagAlreadyAdded(value) && $(this).attr('checked')) {
                        var tagFromViewAll = new wdot.Tag.constructor();
                        tagFromViewAll.TagValue = value;
                        tagFromViewAll.Index = index++;
                        tagFromViewAll.InputName = 'Seo.ContentTags[' + tagFromViewAll.Index + '].TagValue';
                        tags.push(tagFromViewAll);
                        $(this).attr('checked', 'checked');
                    }

                    if (isTagAlreadyAdded(value) && !$(this).attr('checked')) {
                        wdot.Seo.seoContentTagsViewModel.removeTagByValue($(this).val());
                    }
                });
                $("#tags-view-all.tags-modal").dialog('close');
            },
            loadTags = function (tagsJsonAsString) {
                var tagsJson = $.parseJSON(tagsJsonAsString);
                for (var prop in tagsJson) {
                    if (!tagsJson.hasOwnProperty(prop)) {
                        continue;
                    }
                    var value = tagsJson[prop]["TagValue"];
                    if (!isTagAlreadyAdded(value)) {
                        var tagFromJson = new wdot.Tag.constructor();
                        tagFromJson.TagValue = value;
                        tagFromJson.Index = index++;
                        tagFromJson.InputName = 'Seo.ContentTags[' + tagFromJson.Index + '].TagValue';
                        tags.push(tagFromJson);
                    }
                }
            },
            isTagAlreadyAdded = function (tagValue) {
                for (var i = 0; i < tags().length; i++) {
                    if (tags()[i].TagValue == tagValue) {
                        return true;
                    }
                }
                return false;
            },
            removeTagByValue = function (tagValue) {
                var indexRemoved = 0;
                for (var i = 0; i < tags().length; i++) {
                    if (tags()[i].TagValue == tagValue) {
                        tags.remove(tags()[i]);
                        indexRemoved = i;
                    }
                }
                for (var x = indexRemoved; x < tags().length; x++) {
                    var currentTag = tags()[x];
                    currentTag.Index = indexRemoved++;
                    $("[name='" + currentTag.InputName + "']").attr('name', 'Seo.ContentTags[' + currentTag.Index + '].TagValue');
                    currentTag.InputName = 'Seo.ContentTags[' + currentTag.Index + '].TagValue';
                }
                index--;
            };
        return {
            Tags: tags,
            AddTag: addTag,
            RemoveTag: removeTag,
            AddTagsFromViewAll: addTagsFromViewAll,
            LoadTags: loadTags,
            isTagAlreadyAdded: isTagAlreadyAdded,
            removeTagByValue: removeTagByValue
        };
    } ();
    return {
        seoContentTagsViewModel: seoContentTagsViewModel
    };
} ();