var wdot = wdot || {};
wdot.MediaRoom = wdot.MediaRoom || {};
wdot.MediaRoom.PhotosJson = wdot.MediaRoom.PhotosJson || [];
wdot.MediaRoom.PhotoGallery = function () {
    var photoPageSize = 9,
    maxVisiblePageNums = 5,
    currentPage = ko.observable(0),
    processPhotos = function (isMobile) {
        if (isMobile) {
            photoPageSize = 4;
        }

        $.each(wdot.MediaRoom.PhotosJson, function (i) {
            this.Index = i;
            this.ThumbNail = this.FileLocation + "?preset=media-room-photo";
        });
        ko.applyBindings(wdot.MediaRoom.PhotoGallery, $(".imageGallery")[0]);
        wdot.MediaRoom.PhotoGallery.CurrentPage(1);
        $(".imageGallery").on("click", ".pagingContainer .pagingbtn", function () {
            changePage($(this).attr("data-pagenumber"));
            wdot.AjaxStateHandler.UpdateHistory($(this).attr("data-pagenumber"));
            document.getElementById("gallery").scrollIntoView();
            return false;
        });
        if (!isMobile) {
            loadLightBox();
        }

        $(".imageGallery").removeClass("hidden");
    },
    changePage = function (pageNum) {
        var num = parseInt(pageNum);
        if (isNaN(num))
            num = 1;

        wdot.MediaRoom.PhotoGallery.CurrentPage(num);
        loadLightBox();
    },
    formatTitle = function (title, currentArray, currentIndex, currentOpts) {
        return '<span><a href="' + currentOpts.href + '" target="_blank">Download Image</a></span>' + (title && title.length ? '<b>' + title + '</b>' : '') + 'Image ' + (currentIndex + 1) + ' of ' + currentArray.length + '</div>';
    },
    loadLightBox = function () {
        $('a.gallery-display__lightbox').fancybox({
            beforeLoad: function () {
                this.title = this.title + " <a href='" + this.href + "' target='_blank'> Download </a>";
            },
            helpers: {
                title: {
                    type: 'inside',
                    format: formatTitle
                }
            },
            openEffect: 'none'
        });

        $('a.gallery-display__download-link').fancybox({
            beforeLoad: function () {
                this.title = this.title + " <a href='" + this.href + "' target='_blank'> Download </a>";
            },
            helpers: {
                title: {
                    type: 'inside',
                    format: formatTitle
                }
            },
            openEffect: 'none'
        });
    },
    visiblePageNums = ko.computed(function () {
        if (currentPage() > 0) {
            var startPage = 1;
            var halfWayThroughVisbiblePages = Math.ceil(maxVisiblePageNums / 2);
            if (wdot.MediaRoom.PhotoGallery.CurrentPage() > halfWayThroughVisbiblePages) {
                startPage = wdot.MediaRoom.PhotoGallery.CurrentPage() - Math.floor(maxVisiblePageNums / 2);
            }
            var endPage = (startPage + maxVisiblePageNums) - 1;

            if (endPage > maxPageNum()) {
                endPage = maxPageNum();
            }

            var pageNums = [];
            for (var i = startPage; i <= endPage; i++) {
                pageNums.push({ PageNum: i });
            }

            return pageNums;
        }
        return [];
    }),
    hasPages = function () {
        return maxPageNum() > 0;
    },
    maxPageNum = function () {
        return Math.ceil(wdot.MediaRoom.PhotosJson.length / photoPageSize);
    },
    hasRightPages = ko.computed(function () {
        return maxPageNum() > 1 && currentPage() < maxPageNum();
    }),
    hasLeftPages = ko.computed(function () {
        return currentPage() > 1;
    }),
    currentPhotos = ko.computed(function () {
        if (currentPage() > 0) {
            if (Math.ceil(wdot.MediaRoom.PhotosJson.length / photoPageSize) < wdot.MediaRoom.PhotoGallery.CurrentPage())
                wdot.MediaRoom.PhotoGallery.CurrentPage(Math.ceil(wdot.MediaRoom.PhotosJson.length / photoPageSize));

            var minIndex = (wdot.MediaRoom.PhotoGallery.CurrentPage() - 1) * photoPageSize;
            var maxIndex = (wdot.MediaRoom.PhotoGallery.CurrentPage() * photoPageSize) - 1;

            var photos = ko.utils.arrayFilter(wdot.MediaRoom.PhotosJson, function (item) {
                return item.Index >= minIndex && item.Index <= maxIndex;
            });

            return photos;
        }
        return [];
    });

    return {
        CurrentPage: function (num) {
            if (num != undefined) {
                currentPage(num);
            }

            return currentPage();
        },
        ProcessPhotos: processPhotos,
        VisiblePageNums: visiblePageNums,
        MaxPageNum: maxPageNum,
        CurrentPhotos: currentPhotos,
        HasPages: hasPages,
        ChangePage: changePage,
        HasRightPages: hasRightPages,
        HasLeftPages: hasLeftPages
    };
} ();